import { useAppSelector } from "../../config/hooks";

const useCompanySize = () => {
  const companySizeArray = useAppSelector(
    (state) => state.appConfig.companySize
  );

  const defaultCompanySize = (dafaultId) => {
    const selectedIndustry = companySizeArray.find(
      (commanyType) => commanyType.Id == dafaultId
    );
    return selectedIndustry;
  };
  return { companySizeArray, defaultCompanySize };
};

export default useCompanySize;
