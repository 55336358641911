import React from "react";

import useEmblaCarousel from "embla-carousel-react";
import { DotButton, useDotButton } from "./EmblaCarouselDotButton";

const EmblaCarousel = ({ slides, options }) => {
  const [emblaRef, emblaApi] = useEmblaCarousel(options);

  const { selectedIndex, scrollSnaps, onDotButtonClick } =
    useDotButton(emblaApi);

  return (
    <section className="m-auto max-w-3xl lg:max-w-none lg:w-full">
      <div className="overflow-hidden" ref={emblaRef}>
        <div className="flex touch-pan-y">
          {slides.map((client, index) => {
            return (
              <div
                className="flex-grow-0 flex-shrink-0 basis-full lg:basis-1/2 pl-4"
                key={index}
              >
                <div className="p-4 space-y-2">
                  <div className="h-24 w-24 border-t-8 border-l-8 border-golden rounded-tl-xl rounded-br-xl overflow-hidden mx-auto">
                    <img
                      src={client.image}
                      alt=""
                      className="object-cover w-full h-full"
                    />
                  </div>

                  <div className="text-center max-w-sm mx-auto">
                    <p className="text-lg text-expurple-800 line-clamp-3">
                      {client.content}
                    </p>
                  </div>

                  <div className="text-center">
                    <p className="text-xl text-expurple-800 font-semibold">
                      {client.name}
                    </p>
                  </div>

                  <div className="text-center">
                    <p className="text-xl text-expurple-700 font-semibold">
                      {client.designation}
                    </p>
                  </div>
                </div>
              </div>
            );
          })}
        </div>
      </div>

      <div className="flex items-center justify-center mt-4">
        <div className="flex items-center justify-center w-[50%] gap-3 max-w-sm">
          {scrollSnaps.map((_, index) => (
            <DotButton
              key={index}
              onClick={() => onDotButtonClick(index)}
              className={"appearance-none border-2 border-expurple-800 touch-action-manipulation inline-flex text-decoration-none cursor-pointer p-0 m-0 w-4 h-4 items-center justify-center rounded-full after:content-[''] after:flex after:items-center".concat(
                index === selectedIndex
                  ? " bg-expurple-800 border-2 border-expurple-800"
                  : ""
              )}
            />
          ))}
        </div>
      </div>
    </section>
  );
};

export default EmblaCarousel;
