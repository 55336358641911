import { PersistGate } from "redux-persist/es/integration/react";
import Routes from "./routes/route";
import { persistor, store } from "./store/store";
import { Provider } from "react-redux";

function App() {
  return (
    <Provider store={store}>
      <PersistGate persistor={persistor}>
        <Routes />
      </PersistGate>
    </Provider>
  );
}

export default App;
