import React from "react";
import { useAppSelector } from "../../../config/hooks";
import BellIcon from "../../../assets/Icons/BellIcon";
import StandardEnvolpIcon from "../../../assets/Icons/StandardEnvolpIcon";
import StandardPhoneIcon from "../../../assets/Icons/StandardPhoneIcon";

const RecruiterProfileCard = () => {
  const recruiterSelector = useAppSelector(
    (state) => state?.recruiter?.recruiterDetails
  );

  const { recruiterData, isLoading, error } = recruiterSelector;

  return (
    <div
      className="p-4 bg-exwhite-100 rounded-md flex items-center justify-between gap-3"
      style={{
        boxShadow: "0px 0px 5px 2px rgba(0, 0, 0, 0.2)",
      }}
    >
      <div className="h-28 w-28 bg-DefaultProfileImg bg-cover bg-center bg-no-repeat"></div>

      <div className="flex-1 space-y-1">
        <div className="flex items-center justify-between">
          <h1 className="text-xl text-expurple-600 font-semibold">
            {`${recruiterData?.firstName} ${recruiterData?.lastName}`}
          </h1>
          <div className="mr-10">{/* <BellIcon /> */}</div>
        </div>

        <div>
          <p className="text-base text-expurple-800 font-semibold">
            {recruiterData?.designation}
          </p>
        </div>

        <div className="space-y-1">
          <p className="flex items-center justify-start gap-1 text-base text-expurple-800 font-semibold">
            <StandardEnvolpIcon width="20" height="20" color="#696969" />
            {recruiterData?.email}
          </p>
          <p className="text-base text-expurple-800 flex items-center justify-start gap-2 leading-none font-semibold">
            <StandardPhoneIcon width="20" height="20" color="#696969" />
            {`${
              recruiterData?.countryCode === 0 ? "" : recruiterData?.countryCode
            } ${
              recruiterData?.phoneNo === 0
                ? "----------"
                : recruiterData?.phoneNo
            }`}
          </p>
        </div>
      </div>
    </div>
  );
};

export default RecruiterProfileCard;
