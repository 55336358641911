import { createBrowserRouter, RouterProvider } from "react-router-dom";
import Main from "../pages/main/Main";
import HomePage from "../pages/home-page/HomePage";
import CandidateLogin from "../pages/candidate/CandidateLogin";
import CompanyLogin from "../pages/company/CompanyLogin";
import RecruiterLogin from "../pages/recuiter/RecruiterLogin";
import Candidate from "../pages/candidate/Candidate";
import CandidateCreate from "../pages/candidate/CandidateCreate";
import Company from "../pages/company/Company";
import CompanyCreate from "../pages/company/CompanyCreate";
import Recruiter from "../pages/recuiter/Recruiter";
import RecruiterCreate from "../pages/recuiter/RecruiterCreate";
import CandidateProfile from "../pages/candidate-profile/CandidateProfile";

import CandidateAppliedJobs from "../pages/candidate-applied-jobs/CandidateAppliedJobs";
import JobSearch from "../pages/job-search/JobSearch";
import CompanyProfile from "../pages/company-profile/CompanyProfile";
import CompanyDashboard from "../pages/company-dashboard/CompanyDashboard";
import CreateJob from "../pages/company-dashboard/components/CreateJob";
import JobPage from "../pages/company-job-page/JobPage";
import MemberSignup from "../pages/signup-member/MemberSignup";
import MemberPage from "../pages/member-page/MemberPage";
import BundleUpload from "../pages/bundle-upload/BundleUpload";
import RecruiterDashboard from "../pages/recruiter-dashboard/RecruiterDashboard";
import ProtectedRoute from "../components/protected-route/ProtectedRoute";
import JobSeekers from "../pages/bundle-upload/JobSeekers";
import BundleUploadSuccess from "../pages/bundle-upload/BundleUploadSuccess";
import JobSeerkerView from "../pages/bundle-upload/JobSeerkerView";

const routes = createBrowserRouter([
  {
    path: "/",
    element: <Main />,
    children: [
      { path: "/", element: <HomePage /> },
      {
        path: "/candidate",
        element: <Candidate />,
        children: [
          {
            path: "/candidate",
            element: <CandidateLogin />,
          },
          {
            path: "/candidate/register",
            element: <CandidateCreate />,
          },
        ],
      },
      {
        path: "/company",
        element: <Company />,
        children: [
          {
            path: "/company",
            element: <CompanyLogin />,
          },
          {
            path: "/company/register",
            element: <CompanyCreate />,
          },
        ],
      },
      {
        path: "/recruiter",
        element: <Recruiter />,
        children: [
          {
            path: "/recruiter",
            element: <RecruiterLogin />,
          },
          {
            path: "/recruiter/register",
            element: <RecruiterCreate />,
          },
        ],
      },
      {
        path: "/candidate-profile",
        element: <ProtectedRoute element={<CandidateProfile />} />,
      },
      {
        path: "/applied-jobs",
        element: <ProtectedRoute element={<CandidateAppliedJobs />} />,
      },
      {
        path: "/job-search",
        element: <JobSearch />,
      },
      {
        path: "/company-profile",
        element: <CompanyProfile />,
      },
      {
        path: "/company-admin",
        element: <ProtectedRoute element={<CompanyDashboard />} />,
      },
      {
        path: "/compnay-profile-edit/:companyId",
        element: <ProtectedRoute element={<CompanyProfile />} />,
      },
      {
        path: "/create-job",
        element: <ProtectedRoute element={<CreateJob />} />,
      },
      {
        path: "/job/:jobId",
        element: <ProtectedRoute element={<JobPage />} />,
      },
      {
        path: "/update-job/:jobId",
        element: <ProtectedRoute element={<CreateJob />} />,
      },
      {
        path: "/signup-member/:memberId",
        element: <MemberSignup />,
      },
      {
        path: "/member",
        element: <ProtectedRoute element={<MemberPage />} />,
      },
      {
        path: "/recruiter-dashboard",
        element: <ProtectedRoute element={<RecruiterDashboard />} />,
      },
      {
        path: "/bulk-upload",
        element: <ProtectedRoute element={<BundleUpload />} />,
      },
      {
        path: "/job-seekers",
        element: <ProtectedRoute element={<JobSeekers />} />,
      },
      {
        path: "/job-seekers/:userId",
        element: <ProtectedRoute element={<JobSeerkerView />} />,
      },
      {
        path: "/success",
        element: <ProtectedRoute element={<BundleUploadSuccess />} />,
      },
    ],
  },
]);

const Routes = () => {
  return <RouterProvider router={routes} />;
};

export default Routes;
