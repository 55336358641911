import { useAppSelector } from "../../config/hooks";

const useJobTypes = () => {
  const jobTypesArray = useAppSelector((state) => state.appConfig.jobTypes);

  const defaultJobType = (dafaultId) => {
    if (isNaN(dafaultId)) {
      return { Title: dafaultId, Id: dafaultId };
    }
    const selectedJobType = jobTypesArray.find(
      (jobtype) => jobtype.Id == dafaultId
    );
    return selectedJobType;
  };
  return { jobTypesArray, defaultJobType };
};

export default useJobTypes;
