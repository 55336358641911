import { Controller, useForm } from "react-hook-form";
import ExInput from "../../components/basic-components/ExInput";
import ExDropdown from "../../components/basic-components/ExDropdown";
import { Link, useNavigate } from "react-router-dom";
import ExUplaodInput from "../../components/basic-components/ExUplaodInput";
import { useEffect, useRef, useState } from "react";
import Popup from "../../components/popup/Popup";
import OtpField from "../../components/otp/OtpField";
import * as constants from "../../constant";
import { useAppDispatch, useAppSelector } from "../../config/hooks";
import useCountryCodes from "../../utils/dropdown-options/useCountryCodes";
import {
  getRecruiterOtpApi,
  registerRecruiterApi,
} from "../../slices/loginSlice";
import { unwrapResult } from "@reduxjs/toolkit";
import PlusCircleIcon from "../../assets/Icons/PlusCircleIcon";
import uploadContent from "../../utils/upload-content/uploadContent";
import LoadingIcon from "../../assets/Icons/LoadingIcon";

const RecruiterCreate = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const inputRef = useRef(null);

  const { modifiedCountryCodes, defaultCountryCode } = useCountryCodes();

  const [togglePopup, setTogglePopup] = useState(false);
  const [isFileLoading, setIsFileLoading] = useState(false);
  const [isFormLoading, setIsFormLoading] = useState(false);

  const uiSelector = useAppSelector((state) => state.login.ui);
  const requestID = useAppSelector((state) => state.login.ui.requestId);

  const {
    control,
    handleSubmit,
    watch,
    setValue,
    formState: { errors },
  } = useForm({
    defaultValues: {
      firstName: "",
      lastName: "",
      emailId: "",
      phoneNo: "",
      countryCode: constants.IND_COUNTRYCODE,
      companyName: "",
      file: {
        name: "",
        url: "",
        contentType: "",
        size: 0,
      },
      aadharNo: "",
      panNo: "",
    },
  });

  const countryCodeValue = watch("countryCode");
  const phoneNoValue = watch("phoneNo");
  const uploadedFile = watch("file");

  useEffect(() => {
    if (uiSelector.isVerified && uiSelector.loginType === "recruiter") {
      if (uiSelector.isEmail) {
        setValue("emailId", uiSelector.email);
      } else {
        setValue("countryCode", defaultCountryCode(uiSelector.countryCode));
        setValue("phoneNo", uiSelector.phone);
      }
    }
  }, []);

  const triggerInputfileHanlder = () => {
    if (inputRef.current) inputRef.current.click();
  };

  const toglePopupHandler = async () => {
    try {
      await dispatch(
        getRecruiterOtpApi({
          phoneNo: parseInt(phoneNoValue),
          countryCode: parseInt(countryCodeValue.PhoneCode),
        })
      ).then(unwrapResult);
      setTogglePopup(!togglePopup);
    } catch (error) {
      setTogglePopup(!togglePopup);
    }
  };

  const onSubmit = async (formData) => {
    setIsFormLoading(true);

    const data = {
      requestId: requestID,
      firstName: formData.firstName,
      lastName: formData.lastName,
      phoneNo: parseInt(formData.phoneNo),
      countryCode: parseInt(formData.countryCode.PhoneCode),
      emailId: formData.emailId,
      companyName: formData.companyName,
      panNo: formData.panNo,
      aadharNo: formData.aadharNo,
      panCardImage: formData.file,
    };
    try {
      await dispatch(registerRecruiterApi(data)).then(unwrapResult);
      setIsFormLoading(false);
      navigate("/recruiter-dashboard");
    } catch (error) {
      setIsFormLoading(false);
    }
  };

  return (
    <div className="h-full flex">
      <div className="w-full h-full"></div>
      <div className="h-full w-full">
        <h1 className="text-4xl text-expurple-800 font-semibold text-center py-4">
          Recruiter Signup
        </h1>
        <form
          onSubmit={handleSubmit(onSubmit)}
          className="w-[80%] h-[80%] mx-auto space-y-3"
        >
          <div className="flex items-center justify-between gap-2">
            <div className="w-full self-start">
              <Controller
                name="firstName"
                control={control}
                rules={{
                  required: {
                    value: true,
                    message: "First Name is required",
                  },
                }}
                render={({ field: { onChange, value } }) => (
                  <ExInput
                    title="First Name"
                    placeholder="First Name"
                    name="firstName"
                    onChange={onChange}
                    value={value}
                    error={errors?.firstName?.message}
                  />
                )}
              />
            </div>
            <div className="w-full self-start">
              <Controller
                name="lastName"
                control={control}
                rules={{
                  required: {
                    value: true,
                    message: "Last Name is required",
                  },
                }}
                render={({ field: { onChange, value } }) => (
                  <ExInput
                    title="Last Name"
                    placeholder="Last Name"
                    name="lastName"
                    onChange={onChange}
                    value={value}
                    error={errors?.lastName?.message}
                  />
                )}
              />
            </div>
          </div>

          <div>
            {/* <Controller
              name="emailId"
              control={control}
              rules={{
                required: {
                  value: true,
                  message: "Email ID is required",
                },
                pattern: {
                  value:
                    /^([a-zA-Z0-9_.+-])+@(([a-zA-Z0-9-])+\.)+([a-zA-Z0-9]{2,4})+$/,
                  message: "Please enter a valid email ID",
                },
              }}
              render={({ field: { onChange, value } }) => (
                <ExInput
                  title="Email ID"
                  placeholder="eg. abc@abc.com"
                  name="emailId"
                  onChange={onChange}
                  value={value}
                  error={errors?.emailId?.message}
                />
              )}
            /> */}

            <Controller
              name="emailId"
              control={control}
              rules={{
                required: {
                  value: true,
                  message: "Email ID is required",
                },
                pattern: {
                  value:
                    /^([a-zA-Z0-9_.+-])+@(([a-zA-Z0-9-])+\.)+([a-zA-Z0-9]{2,4})+$/,
                  message: "Please enter a valid email ID",
                },
              }}
              render={({ field: { onChange, value } }) => (
                <div>
                  <label
                    htmlFor={"emailId"}
                    className={`text-lg font-normal text-expurple-800`}
                  >
                    Email ID *
                  </label>
                  <div className={`relative`}>
                    <input
                      type="text"
                      readOnly={
                        uiSelector.isVerified &&
                        uiSelector.email &&
                        uiSelector.loginType === "recruiter"
                      }
                      className="w-full h-10 px-3 bg-expurple-100 rounded-sm outline-none text-expurple-700 placeholder:text-expurple-700"
                      id="emailId"
                      placeholder="eg. abc@abc.com"
                      autoComplete="off"
                      name="emailId"
                      onChange={onChange}
                      value={value}
                    />
                    {uiSelector.isVerified &&
                      uiSelector.email &&
                      uiSelector.loginType === "recruiter" && (
                        <div className="absolute h-full top-0 flex items-center justify-center right-2">
                          <button
                            type="button"
                            disabled={true}
                            className="py-1 w-fit px-4 bg-expurple-800 text-golden text-xs rounded-sm"
                          >
                            Verified
                          </button>
                        </div>
                      )}
                  </div>
                  {errors?.emailId?.message && (
                    <p className="text-red-400">{errors?.emailId?.message}</p>
                  )}
                </div>
              )}
            />
          </div>

          <div className="w-full">
            <div className="flex items-center justify-between gap-2">
              <div className="min-w-[120px] self-start">
                {/* <Controller
                  name="countryCode"
                  control={control}
                  rules={{
                    required: {
                      value: true,
                      message: "required",
                    },
                  }}
                  render={({ field: { onChange, value } }) => (
                    <ExDropdown
                      title="Country *"
                      onChange={onChange}
                      placeholder="Select"
                      value={value || null}
                      optionValue="Id"
                      optionLabel="PhoneCodeLabel"
                      options={modifiedCountryCodes}
                      dropDownHeight="40px"
                      dropDownBorderWidth="0px"
                      dropDownBorderColor="#696969"
                      error={errors?.countryCode?.message}
                    />
                  )}
                /> */}

                <Controller
                  name="countryCode"
                  control={control}
                  rules={{
                    required: {
                      value: true,
                      message: "required",
                    },
                  }}
                  render={({ field: { onChange, value } }) => (
                    <ExDropdown
                      title="Country *"
                      onChange={onChange}
                      placeholder="Select"
                      value={value || null}
                      optionValue="Id"
                      isDisabled={
                        uiSelector.isVerified &&
                        uiSelector.countryCode &&
                        uiSelector.loginType === "recruiter"
                      }
                      optionLabel="PhoneCodeLabel"
                      options={modifiedCountryCodes}
                      dropDownHeight="40px"
                      dropDownBorderWidth="0px"
                      dropDownBorderColor="#696969"
                      error={errors?.countryCode?.message}
                    />
                  )}
                />
              </div>
              <div className="flex-1 self-start">
                {/* <Controller
                  name="phoneNo"
                  control={control}
                  rules={{
                    required: {
                      value: true,
                      message: "Mobile no is required",
                    },
                    pattern: {
                      value: /^[0-9]+$/,
                      message: "Please enter a valid mobile number",
                    },
                  }}
                  render={({ field: { onChange, value } }) => (
                    <div>
                      <label
                        htmlFor={"phoneNo"}
                        className={`text-lg font-normal text-expurple-800`}
                      >
                        Mobile No. *
                      </label>
                      <div className={`relative`}>
                        <input
                          type="number"
                          className="w-full h-10 px-3 bg-expurple-100 rounded-sm outline-none text-expurple-700 placeholder:text-expurple-700"
                          id="phoneNo"
                          placeholder="Enter here"
                          autoComplete="off"
                          name="phoneNo"
                          onChange={onChange}
                          value={value}
                        />
                        <div className="absolute h-full top-0 flex items-center justify-center right-2 spin-button-none">
                          <button
                            type="button"
                            disabled={!(phoneNoValue && countryCodeValue)}
                            onClick={toglePopupHandler}
                            className="py-1 w-fit px-4 bg-expurple-800 text-golden text-xs rounded-sm"
                          >
                            Verify
                          </button>
                        </div>
                      </div>
                      {errors?.phoneNo?.message && (
                        <p className="text-red-400">
                          {errors?.phoneNo?.message}
                        </p>
                      )}
                    </div>
                  )}
                /> */}

                <Controller
                  name="phoneNo"
                  control={control}
                  rules={{
                    required: {
                      value: true,
                      message: "Mobile no is required",
                    },
                    pattern: {
                      value: /^[0-9]+$/,
                      message: "Please enter a valid mobile number",
                    },
                  }}
                  render={({ field: { onChange, value } }) => (
                    <div>
                      <label
                        htmlFor={"phoneNo"}
                        className={`text-lg font-normal text-expurple-800`}
                      >
                        Mobile No. *
                      </label>
                      <div className={`relative`}>
                        <input
                          type="number"
                          onWheel={(e) => e.target.blur()}
                          className="w-full h-10 px-3 bg-expurple-100 rounded-sm outline-none text-expurple-700 placeholder:text-expurple-700"
                          id="phoneNo"
                          readOnly={
                            uiSelector.isVerified &&
                            uiSelector.phone &&
                            uiSelector.loginType === "recruiter"
                          }
                          placeholder="Enter here"
                          autoComplete="off"
                          name="phoneNo"
                          onChange={onChange}
                          value={value}
                        />
                        {!(uiSelector.isVerified && uiSelector.email) && (
                          <div className="absolute h-full top-0 flex items-center justify-center right-2 spin-button-none">
                            {uiSelector.isVerified &&
                            uiSelector.phone &&
                            uiSelector.loginType === "recruiter" ? (
                              <button
                                type="button"
                                disabled={true}
                                className="py-1 w-fit px-4 bg-expurple-800 text-golden text-xs rounded-sm"
                              >
                                Verified
                              </button>
                            ) : (
                              <button
                                type="button"
                                disabled={!(phoneNoValue && countryCodeValue)}
                                onClick={toglePopupHandler}
                                className="py-1 w-fit px-4 bg-expurple-800 text-golden text-xs rounded-sm"
                              >
                                Verify
                              </button>
                            )}
                          </div>
                        )}
                      </div>
                      {errors?.phoneNo?.message && (
                        <p className="text-red-400">
                          {errors?.phoneNo?.message}
                        </p>
                      )}
                    </div>
                  )}
                />
              </div>
            </div>
          </div>

          <div>
            <Controller
              name="companyName"
              control={control}
              render={({ field: { onChange, value } }) => (
                <ExInput
                  title="Company"
                  placeholder="Enter Here"
                  name="companyName"
                  onChange={onChange}
                  value={value}
                  error={errors?.companyName?.message}
                />
              )}
            />
          </div>

          <div className="flex items-center justify-between gap-2">
            <div className="w-full self-start">
              <Controller
                name="panNo"
                control={control}
                rules={{
                  required: {
                    value: true,
                    message: "PAN No. is required",
                  },
                  pattern: {
                    value: /^[A-Z]{5}[0-9]{4}[A-Z]{1}$/,
                    message: "Please enter valid PAN no.",
                  },
                }}
                render={({ field: { onChange, value } }) => (
                  <ExInput
                    title="PAN No. *"
                    placeholder="Enter here"
                    name="panNo"
                    onChange={onChange}
                    value={value}
                    error={errors?.panNo?.message}
                  />
                )}
              />
            </div>
            <div className="w-full self-start">
              <Controller
                name="aadharNo"
                control={control}
                rules={{
                  required: {
                    value: true,
                    message: "Aadhar No. is required",
                  },
                  pattern: {
                    value: /^[2-9]{1}[0-9]{3}\s[0-9]{4}\s[0-9]{4}$/,
                    message: "Please enter valid aadhar no.",
                  },
                }}
                render={({ field: { onChange, value } }) => (
                  <ExInput
                    title="Aadhar No. *"
                    placeholder="0000 0000 0000"
                    name="aadharNo"
                    onChange={onChange}
                    value={value}
                    error={errors?.aadharNo?.message}
                  />
                )}
              />
            </div>
          </div>

          {/* <div>
            <Controller
              name="uploadCV"
              control={control}
              rules={{
                required: {
                  value: true,
                  message: "Please upload a document",
                },
              }}
              render={({ field: { onChange, value } }) => (
                <ExUplaodInput
                  title={"PAN Card *"}
                  name={"uploadCV"}
                  accept="image/*"
                  onChange={onChange}
                  // value={value}
                  error={errors?.uploadCV?.message}
                />
              )}
            />
          </div> */}

          <div>
            <Controller
              name="file"
              control={control}
              rules={{
                required: {
                  value: true,
                  message: "Required",
                },
              }}
              render={({ field: { onChange, value } }) => (
                <div>
                  <label
                    htmlFor="file"
                    className={`text-lg font-normal text-expurple-800`}
                  >
                    Add Pan card
                  </label>
                  <div className="hidden">
                    <input
                      title="Add Pan card"
                      placeholder="Browse file"
                      name="file"
                      id="file"
                      type="file"
                      ref={inputRef}
                      accept="image/*"
                      onChange={(e) => {
                        setIsFileLoading(true);
                        uploadContent.uploadPandCard(
                          e.target.files,
                          (response) => {
                            setIsFileLoading(false);

                            if (!response[0].error) {
                              onChange({
                                name: response[0].orginalFileName,
                                url: response[0].url,
                                contentType: response[0].contentType,
                                size: response[0].size,
                              });
                            }
                          }
                        );
                      }}
                      // value={value}
                    />
                  </div>
                  <div className="flex items-center justify-between gap-2">
                    <div className="flex-1 h-10 bg-expurple-100 px-3 flex items-center justify-start gap-1">
                      <div
                        className="cursor-pointer"
                        onClick={triggerInputfileHanlder}
                      >
                        <PlusCircleIcon />
                      </div>
                      <p className="text-expurple-700 text-base line-clamp-1">
                        {uploadedFile.name
                          ? uploadedFile.name
                          : "Browse file. Only pdf"}
                      </p>
                    </div>
                    {isFileLoading && (
                      <div>
                        <LoadingIcon />
                      </div>
                    )}
                  </div>
                  {errors?.file?.message && (
                    <p className="text-red-400">{errors?.file?.message}</p>
                  )}
                </div>
              )}
            />
          </div>

          <div className="">
            <div className="w-fit mx-auto">
              <button
                type="submit"
                className={`py-2 px-10 ${
                  isFileLoading || isFormLoading
                    ? "bg-exgray-400/50 text-exwhite-100"
                    : "bg-expurple-800 text-golden"
                } text-center font-semibold rounded-xl tracking-wider`}
                disabled={isFileLoading || isFormLoading}
              >
                SignUp
              </button>
            </div>

            <div>
              <div className="w-fit ml-auto mr-0">
                <Link
                  to={"/recruiter"}
                  className="text-center text-lg text-expurple-800 underline tracking-wider"
                >
                  Login
                </Link>
              </div>
            </div>
          </div>
        </form>
      </div>
      <Popup onClose={() => setTogglePopup(false)} visible={togglePopup}>
        <div className="bg-expurple-100 p-10 rounded-xl">
          <OtpField
            userType={constants.RECRUITER.toLowerCase()}
            onClose={() => setTogglePopup(false)}
          />
        </div>
      </Popup>
    </div>
  );
};

export default RecruiterCreate;
