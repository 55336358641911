import { createAsyncThunk } from "@reduxjs/toolkit";
import contentService from "../services/content.service";

export const uploadFiles = createAsyncThunk(
  "post/uploadFilesApi",
  async (data, { getState, rejectWithValue }) => {
    try {
      const response = await contentService.uploadFile(data);
      return response;
    } catch (error) {
      return rejectWithValue(error.response);
    }
  }
);
