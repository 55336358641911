import { useRef, useState } from "react";
import CheckedIcon from "../../assets/Icons/CheckedIcon";

const ExCheckBox = ({
  name,
  title,
  labelStyle = "text-expurple-800 text-lg",
  ...props
}) => {
  const inpuRef = useRef(null);
  const [toggleCheckBox, setToggleCheckBox] = useState(props.value);

  const toggleInputHandler = () => {
    if (inpuRef.current) {
      inpuRef.current.click();
      setToggleCheckBox(!toggleCheckBox);
    }
  };
  return (
    <div className="flex items-center gap-2">
      <input
        type="checkbox"
        id={name}
        className="hidden"
        ref={inpuRef}
        {...props}
      />
      <div
        className={`h-5 w-5 border-2 rounded-md border-expurple-800 ${
          toggleCheckBox && "bg-expurple-800"
        } cursor-pointer flex items-center justify-center`}
        onClick={toggleInputHandler}
      >
        {toggleCheckBox && <CheckedIcon />}
      </div>
      <label htmlFor={name} className={labelStyle}>
        {title}
      </label>
    </div>
  );
};

export default ExCheckBox;
