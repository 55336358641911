import React from "react";

const StandardEnvolpIcon = ({
  width = "15",
  height = "12",
  color = "#9199A3",
}) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 15 12"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M2.3 0.799805H12.7C13.415 0.799805 14 1.3848 14 2.0998V9.8998C14 10.6148 13.415 11.1998 12.7 11.1998H2.3C1.585 11.1998 1 10.6148 1 9.8998V2.0998C1 1.3848 1.585 0.799805 2.3 0.799805Z"
        stroke={color}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M14 2.09961L7.5 6.64961L1 2.09961"
        stroke={color}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default StandardEnvolpIcon;
