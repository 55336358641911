import React from "react";

const ExperienceIcon = ({ width = "24", height = "24", color = "#223870" }) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M7 5.83618L12.28 8.63736L9.32364 10.7649L7 5.83618Z"
        stroke={color}
        strokeMiterlimit="10"
        stroklinecap="round"
        stroklinejoin="round"
      />
      <path
        d="M10.627 9.82739L11.7375 10.8994V11.2133L9.64062 19.126L12.2802 20.924"
        stroke={color}
        strokeMiterlimit="10"
        stroklinecap="round"
        stroklinejoin="round"
      />
      <path
        d="M17.9997 5.83594L12.7188 8.63712L15.6751 10.7647L17.9997 5.83594Z"
        stroke={color}
        strokeMiterlimit="10"
        stroklinecap="round"
        stroklinejoin="round"
      />
      <path
        d="M14.3719 9.82715L13.2615 10.8992V11.213L15.3592 19.1258L12.7188 20.9237"
        stroke={color}
        strokeMiterlimit="10"
        stroklinecap="round"
        stroklinejoin="round"
      />
      <path
        d="M7 5.606C7 5.606 12.2955 1.9925 17.9996 5.606"
        stroke={color}
        strokeMiterlimit="10"
        stroklinecap="round"
        stroklinejoin="round"
      />
    </svg>
  );
};

export default ExperienceIcon;
