import React, { useEffect, useState } from "react";
import { useAppDispatch, useAppSelector } from "../../../config/hooks";
import { v4 as uuid4 } from "uuid";
import JobCard from "../../job-search/components/JobCard";
import Popup from "../../../components/popup/Popup";
import JobDetail from "../../job-search/components/JobDetail";
import { getApplicantsApi } from "../../../slices/jobSlice";
import { unwrapResult } from "@reduxjs/toolkit";
import {
  getAllReferralsApi,
  getNextReferedJobsToCandidateApi,
} from "../../../slices/referSlice";
import InfiniteScroll from "react-infinite-scroll-component";
import { toast } from "sonner";

const RefferedJobsList = () => {
  const dispatch = useAppDispatch();
  const refferredJobsSelector = useAppSelector(
    (state) => state.refer.refferedJobs
  );
  const userId = useAppSelector((state) => state?.login?.session?.userId);
  const [togglePopup, setTogglePopup] = useState(false);
  const [selectedJobId, setSelectedJobId] = useState("");

  useEffect(() => {
    const fetchData = async () => {
      try {
        await dispatch(
          getAllReferralsApi({
            candidateId: userId,
            status: "pending",
          })
        ).then(unwrapResult);
      } catch (error) {}
    };
    fetchData();
  }, []);

  const togglePopupHandler = (jobId) => {
    setTogglePopup(!togglePopup);
    setSelectedJobId(jobId);
  };

  const closePopupHandler = (jobId) => {
    setTogglePopup(false);
    setSelectedJobId("");
  };

  const getMoreDataHandler = () => {
    try {
      dispatch(
        getNextReferedJobsToCandidateApi({
          candidateId: userId,
          status: "pending",
        })
      );
    } catch (error) {
      toast.error("Unable to fetch data");
    }
  };

  if (refferredJobsSelector.error) {
    return <h1>Somthing went wrong</h1>;
  }

  return (
    !refferredJobsSelector.isloading && (
      <InfiniteScroll
        className="no-scrollbar"
        dataLength={
          refferredJobsSelector?.jobs?.referrals
            ? refferredJobsSelector?.jobs?.referrals.length
              ? refferredJobsSelector?.jobs?.referrals.length
              : 0
            : 0
        }
        next={() => getMoreDataHandler()}
        hasMore={refferredJobsSelector?.jobs?.metadata?.nextResultURL}
        // hasMore={true}
        // height={"calc(100vh - 155px)"}
        loader={""}
      >
        {refferredJobsSelector?.jobs?.referrals &&
          refferredJobsSelector?.jobs?.referrals.length > 0 && (
            <div className="flex flex-wrap mx-auto">
              {refferredJobsSelector?.jobs?.referrals.length > 0 &&
                refferredJobsSelector?.jobs?.referrals.map((job) => (
                  <div className="w-[33.3333333%]" key={uuid4()}>
                    <div
                      onClick={() => togglePopupHandler(job?.job_details?.id)}
                    >
                      <JobCard job={job?.job_details} />
                    </div>
                    <Popup
                      onClose={closePopupHandler}
                      key={uuid4()}
                      visible={job?.job_details?.id === selectedJobId}
                    >
                      {job?.job_details?.id === selectedJobId && (
                        <div className="bg-expurple-200 p-10 rounded-xl w-[75%] h-[90%] overflow-y-auto no-scrollbar">
                          <JobDetail
                            jobDetails={job.job_details}
                            applied={false}
                            status={job.status}
                            referalId={job.id}
                            recruiterId={job.recruiterId}
                            referralType={job.referralType}
                            closePopupHandler={closePopupHandler}
                          />
                        </div>
                      )}
                    </Popup>
                  </div>
                ))}
            </div>
          )}
      </InfiniteScroll>
    )
  );
};

export default RefferedJobsList;
