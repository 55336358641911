import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { Controller, useForm } from "react-hook-form";
import ExDropdown from "../basic-components/ExDropdown";
import OtpField from "../otp/OtpField";
import { useAppDispatch } from "../../config/hooks";
import {
  getOtpApi,
  getOtpDomainVerification,
  getRecruiterOtpApi,
  removeLoginCredentials,
  toggleOtpHandler,
  verifyCompanyDomain,
} from "../../slices/loginSlice";
import { unwrapResult } from "@reduxjs/toolkit";
import { setComapnyDomain } from "../../slices/companySlice";
import * as constants from "../../constant";
import useCountryCodes from "../../utils/dropdown-options/useCountryCodes";

const Login = ({ title, redirectTo, userType }) => {
  const dispatch = useAppDispatch();
  const [toggleSwitch, setToggleSwitch] = useState(
    // userType === constants.COMPANY.toLowerCase()
    true
  );
  const [toggleOtp, setToggleOtp] = useState(false);

  const { modifiedCountryCodes } = useCountryCodes();

  const {
    control,
    handleSubmit,
    setValue,
    watch,
    formState: { errors },
  } = useForm({
    defaultValues: {
      emailId: "",
      countryCode: constants.IND_COUNTRYCODE,
      phoneNo: "",
    },
  });

  const toggleSwitchHandler = () => {
    setToggleSwitch(!toggleSwitch);
    dispatch(removeLoginCredentials());
    setToggleOtp(false);
    setValue("emailId", "");
    setValue("phoneNo", "");
  };

  const getOtp = async (req) => {
    if (userType === "user") {
      try {
        const response = await dispatch(getOtpApi(req)).then(unwrapResult);
        const requestId = response?.data?.data?.requestId;
        if (requestId) {
          setToggleOtp(true);
        }
      } catch (error) {}
    } else if (userType === "company") {
      try {
        const response = await dispatch(verifyCompanyDomain(req)).then(
          unwrapResult
        );
      } catch (error) {
        try {
          const response = await dispatch(getOtpDomainVerification(req)).then(
            unwrapResult
          );
          if (response) {
            setToggleOtp(true);
            dispatch(
              setComapnyDomain({
                companyDomain: req.domainName,
                companyEmail: req.emailId,
              })
            );
          }
        } catch (error) {}
      }
    } else if (userType === "recruiter") {
      try {
        const response = await dispatch(getRecruiterOtpApi(req)).then(
          unwrapResult
        );
        const requestId = response?.data?.data?.requestId;
        if (requestId) {
          setToggleOtp(true);
        }
      } catch (error) {}
    }
  };

  const onSubmit = async (formData) => {
    if (formData.emailId) {
      if (userType === "user") {
        getOtp({ emailId: formData.emailId });
      } else if (userType === "company") {
        const domain = formData.emailId.split("@");
        getOtp({
          domain: domain.length > 1 ? domain[1] : "",
          emailId: formData.emailId,
        });
      } else if (userType === "recruiter") {
        getOtp({ emailId: formData.emailId });
      }
    } else {
      if (userType === "user" || userType === "recruiter")
        getOtp({
          countryCode: parseInt(formData.countryCode.PhoneCode),
          phoneNo: parseInt(formData.phoneNo),
        });
    }
  };

  return (
    <div className="space-y-6 w-[70%]">
      <h1 className="text-3xl text-expurple-800 font-bold tracking-wider text-center">
        {title}
      </h1>
      {userType !== constants.COMPANY.toLowerCase() && (
        <div className="flex items-center justify-center gap-4">
          <h1 className="text-lg text-expurple-800">Mobile No.</h1>
          <div
            className={`h-6 w-14 p-1 bg-expurple-700 rounded-full flex items-center transition-all cursor-pointer ${
              toggleSwitch ? "justify-end" : "justify-start"
            }`}
            onClick={toggleSwitchHandler}
          >
            <div className="h-5 w-5 rounded-full bg-expurple-800 shadow-md"></div>
          </div>
          <h1 className="text-lg text-expurple-800">Email ID</h1>
        </div>
      )}
      <form onSubmit={handleSubmit(onSubmit)}>
        {toggleSwitch ? (
          <div className="w-[80%] mx-auto">
            <Controller
              name="emailId"
              control={control}
              rules={{
                required: {
                  value: true,
                  message: "Email ID is required",
                },
                pattern: {
                  value:
                    /^([a-zA-Z0-9_.+-])+@(([a-zA-Z0-9-])+\.)+([a-zA-Z0-9]{2,4})+$/,
                  message: "Please enter a valid email ID",
                },
              }}
              render={({ field: { onChange, value } }) => (
                <div>
                  <label
                    htmlFor={"emailId"}
                    className={`text-lg font-normal text-expurple-800`}
                  >
                    Email ID *
                  </label>
                  <div className={`relative`}>
                    <input
                      type="text"
                      className="w-full h-10 px-3 bg-expurple-100 rounded-sm outline-none text-expurple-700 placeholder:text-expurple-700"
                      id="emailId"
                      placeholder="eg. abc@abc.com"
                      autoComplete="off"
                      name="emailId"
                      onChange={onChange}
                      value={value}
                    />
                    <div className="absolute h-full top-0 flex items-center justify-center right-2">
                      <button
                        type="submit"
                        className="py-1 w-fit px-4 bg-expurple-800 text-golden text-xs rounded-sm"
                      >
                        Login Via OTP
                      </button>
                    </div>
                  </div>
                  {errors?.emailId?.message && (
                    <p className="text-red-400">{errors?.emailId?.message}</p>
                  )}
                </div>
              )}
            />
          </div>
        ) : (
          <div className="w-full">
            <div className="flex items-center justify-between gap-2">
              <div className="min-w-[120px] self-start">
                <Controller
                  name="countryCode"
                  control={control}
                  rules={{
                    required: {
                      value: true,
                      message: "required",
                    },
                  }}
                  render={({ field: { onChange, value } }) => (
                    <ExDropdown
                      title="Country *"
                      onChange={onChange}
                      placeholder="Select"
                      value={value || null}
                      optionValue="Id"
                      optionLabel="PhoneCodeLabel"
                      options={modifiedCountryCodes}
                      dropDownHeight="40px"
                      dropDownBorderWidth="0px"
                      dropDownBorderColor="#696969"
                      error={errors?.countryCode?.message}
                    />
                  )}
                />
              </div>
              <div className="flex-1 self-start">
                <Controller
                  name="phoneNo"
                  control={control}
                  rules={{
                    required: {
                      value: true,
                      message: "Mobile no is required",
                    },
                    pattern: {
                      value: /^[0-9]+$/,
                      message: "Please enter a valid mobile number",
                    },
                  }}
                  render={({ field: { onChange, value } }) => (
                    <div>
                      <div>
                        <label
                          htmlFor={"phoneNo"}
                          className={`text-lg font-normal text-expurple-800`}
                        >
                          Mobile No. *
                        </label>
                        <div className={`relative`}>
                          <input
                            className="w-full h-10 px-3 bg-expurple-100 rounded-sm outline-none text-expurple-700 placeholder:text-expurple-700 spin-button-none"
                            id="phoneNo"
                            type="number"
                            onWheel={(e) => e.target.blur()}
                            placeholder="Enter here"
                            autoComplete="off"
                            name="phoneNo"
                            onChange={onChange}
                            value={value}
                          />
                          <div className="absolute h-full top-0 flex items-center justify-center right-2">
                            <button
                              type="submit"
                              className="py-1 w-fit px-4 bg-expurple-800 text-golden text-xs rounded-sm"
                            >
                              Login Via OTP
                            </button>
                          </div>
                        </div>
                        {errors?.phoneNo?.message && (
                          <p className="text-red-400">
                            {errors?.phoneNo?.message}
                          </p>
                        )}
                      </div>
                    </div>
                  )}
                />
              </div>
            </div>
          </div>
        )}
      </form>

      {toggleOtp && (
        <div>
          <OtpField userType={userType} />
        </div>
      )}

      <div className="text-center">
        <p className="text-lg text-expurple-800">
          Don't have an account ?{" "}
          <Link
            to={redirectTo}
            type="button"
            className="underline font-medium text-center"
          >
            SignUp
          </Link>
        </p>
      </div>
    </div>
  );
};

export default Login;
