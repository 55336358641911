import React from "react";

const StandardPhoneIcon = ({
  width = "14",
  height = "14",
  color = "#9199A3",
}) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 14 14"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M4.42839 7.04271C4.97054 8.15109 5.86899 9.04546 6.97983 9.58255C7.0611 9.62107 7.15101 9.63775 7.24069 9.63097C7.33037 9.62418 7.41674 9.59416 7.4913 9.54386L9.12693 8.45318C9.19927 8.40494 9.2825 8.37551 9.36908 8.36756C9.45566 8.3596 9.54286 8.37338 9.62278 8.40763L12.6827 9.71904C12.7867 9.76319 12.8735 9.83994 12.93 9.9377C12.9866 10.0355 13.0098 10.149 12.9962 10.2611C12.8995 11.0179 12.5303 11.7135 11.9576 12.2177C11.3849 12.7218 10.6481 13 9.88517 13C7.52868 13 5.2687 12.0639 3.60241 10.3976C1.93611 8.7313 1 6.47132 1 4.11483C1.00004 3.35186 1.27818 2.61507 1.78234 2.04241C2.2865 1.46975 2.98211 1.10049 3.73892 1.00377C3.85104 0.990205 3.96453 1.01344 4.0623 1.06998C4.16006 1.12652 4.2368 1.21331 4.28096 1.31725L5.59352 4.37989C5.62747 4.4591 5.6413 4.54549 5.63379 4.63134C5.62627 4.7172 5.59764 4.79987 5.55044 4.87198L4.46355 6.53271C4.41408 6.60742 4.38484 6.69368 4.37868 6.78307C4.37251 6.87246 4.38964 6.96192 4.42839 7.04271V7.04271Z"
        stroke={color}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default StandardPhoneIcon;
