import React, { useEffect, useState } from "react";
import { useAppDispatch, useAppSelector } from "../../../config/hooks";
import { v4 as uuid4 } from "uuid";
import JobCard from "../../job-search/components/JobCard";
import Popup from "../../../components/popup/Popup";
import JobDetail from "../../job-search/components/JobDetail";
import {
  getAppliedJobApi,
  getNextAppliedJobsApi,
} from "../../../slices/jobSlice";
import { unwrapResult } from "@reduxjs/toolkit";
import { toast } from "sonner";
import InfiniteScroll from "react-infinite-scroll-component";

const AppliedJobList = () => {
  const dispatch = useAppDispatch();
  const appliedJobs = useAppSelector((state) => state.jobs.appliedJobs);
  const [togglePopup, setTogglePopup] = useState(false);
  const [selectedJobId, setSelectedJobId] = useState("");
  useEffect(() => {
    const fetchData = async () => {
      try {
        await dispatch(getAppliedJobApi()).then(unwrapResult);
      } catch (error) {
        toast.error("Something went wrong");
      }
    };

    fetchData();
  }, []);

  const togglePopupHandler = (jobId) => {
    setTogglePopup(!togglePopup);
    setSelectedJobId(jobId);
  };

  const closePopupHandler = (jobId) => {
    setTogglePopup(false);
    setSelectedJobId("");
  };

  const getMoreDataHandler = () => {
    try {
      dispatch(getNextAppliedJobsApi());
    } catch (error) {
      toast.error("Unable to fetch data");
    }
  };

  return (
    <div>
      {appliedJobs?.jobs && appliedJobs?.jobs?.length > 0 && (
        <InfiniteScroll
          className="no-scrollbar"
          dataLength={appliedJobs?.jobs?.length ? appliedJobs?.jobs?.length : 0}
          next={() => getMoreDataHandler()}
          hasMore={appliedJobs?.metadata?.nextResultURL}
          // height={"calc(100vh - 155px)"}
          loader={""}
        >
          <div className="flex flex-wrap mx-auto">
            {appliedJobs.jobs &&
              appliedJobs?.jobs?.length > 0 &&
              appliedJobs?.jobs.map((appliedJob) => (
                <div className="w-[33.3333333%]" key={uuid4()}>
                  <div onClick={() => togglePopupHandler(appliedJob.job.id)}>
                    <JobCard job={appliedJob.job} jobApplication={appliedJob} />
                  </div>
                  <Popup
                    onClose={closePopupHandler}
                    key={uuid4()}
                    visible={appliedJob.job.id === selectedJobId}
                  >
                    {appliedJob.job.id === selectedJobId && (
                      <div className="bg-expurple-200 p-10 rounded-xl w-[75%] h-[90%] overflow-y-auto no-scrollbar">
                        <JobDetail
                          jobDetails={appliedJob.job}
                          applied={true}
                          applicationId={appliedJob.id}
                          closePopupHandler={closePopupHandler}
                        />
                      </div>
                    )}
                  </Popup>
                </div>
              ))}
          </div>
        </InfiniteScroll>
      )}
    </div>
  );
};

export default AppliedJobList;
