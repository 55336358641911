import React from "react";
import { Link } from "react-router-dom";
import SuccessIcon from "../../assets/Icons/SuccessIcon";

const BundleUploadSuccess = () => {
  return (
    <div className="w-[95%] mx-auto py-20 flex items-center justify-center">
      <div className="space-y-4">
        <div className="w-fit mx-auto">
          <SuccessIcon />
        </div>
        <div>
          <h1 className="text-2xl text-expurple-600 font-semibold text-center">
            🎉 Congratulations, All candidate profiles have been successfully
            created.
          </h1>
          <p className="text-base text-exgray-200 text-center max-w-lg mx-auto">
            Donec hendrerit, ante mattis pellentesque eleifend, tortor urna
            malesuada ante, eget aliquam nulla augue hendrerit ligula. Nunc
            mauris arcu, mattis sed sem vitae.
          </p>
        </div>
        <div className="flex items-center justify-center gap-4">
          <Link
            to={"/job-seekers"}
            className="text-lg text-center font-semibold text-exwhite-100 px-10 py-2 rounded-md bg-expurple-800 flex items-center justify-between gap-4"
          >
            Continue
          </Link>
        </div>
      </div>
    </div>
  );
};

export default BundleUploadSuccess;
