import React, { useEffect } from "react";
import { useAppDispatch, useAppSelector } from "../../../config/hooks";
import {
  getAllReferralsApi,
  getNextReferedCandidatesApi,
} from "../../../slices/referSlice";
import { unwrapResult } from "@reduxjs/toolkit";
import CandidateReferCard from "../../../components/candidate-refer-card/CandidateReferCard";
import { v4 as uuid4 } from "uuid";
import * as constants from "../../../constant";
import InfiniteScroll from "react-infinite-scroll-component";
import { toast } from "sonner";

const JobReferrals = ({ jobId }) => {
  const dispatch = useAppDispatch();
  const loginSelector = useAppSelector((state) => state?.login?.session);
  const memberSelector = useAppSelector((state) => state.member.memberDetails);
  const referSelector = useAppSelector((state) => state.refer.jobReferalList);

  useEffect(() => {
    const fetchData = async () => {
      try {
        // designation
        if (loginSelector.who === constants.COMPANY_MEMBER.toLowerCase()) {
          if (memberSelector.designation === constants.ADMIN) {
            await dispatch(
              getAllReferralsApi({
                jobId: jobId,
                status: "pending",
                refferedCandidate: true,
              })
            ).then(unwrapResult);
          } else if (
            memberSelector.designation === constants.CANDIDATE_MANEGMENT
          ) {
            await dispatch(
              getAllReferralsApi({
                jobId: jobId,
                status: "pending",
                recruiterId: loginSelector?.userId,
                refferedCandidate: true,
              })
            ).then(unwrapResult);
          }
        } else if (loginSelector.who === constants.COMPANY.toLowerCase()) {
          await dispatch(
            getAllReferralsApi({
              jobId: jobId,
              status: "pending",
              refferedCandidate: true,
            })
          ).then(unwrapResult);
        } else if (loginSelector.who === constants.RECRUITER.toLowerCase()) {
          await dispatch(
            getAllReferralsApi({
              jobId: jobId,
              status: "pending",
              recruiterId: loginSelector?.userId,
              refferedCandidate: true,
            })
          ).then(unwrapResult);
        }
      } catch (error) {}
    };
    fetchData();
  }, []);

  const getMoreDataHandler = () => {
    try {
      dispatch(
        getNextReferedCandidatesApi({
          jobId: jobId,
          status: "pending",
          refferedCandidate: true,
        })
      );
    } catch (error) {
      toast.error("Unable to fetch data");
    }
  };

  if (referSelector.error) {
    return <h1>Something went wrong</h1>;
  }

  return (
    !referSelector.isLoading && (
      <InfiniteScroll
        className="no-scrollbar"
        dataLength={
          referSelector?.candidates?.referrals.length
            ? referSelector?.candidates?.referrals.length
            : 0
        }
        next={() => getMoreDataHandler()}
        hasMore={referSelector?.candidates?.metadata?.nextResultURL}
        // hasMore={true}
        // height={"calc(100vh - 155px)"}
        loader={""}
      >
        <div className="space-y-2">
          {referSelector?.candidates?.referrals &&
            referSelector?.candidates?.referrals.length > 0 &&
            referSelector.candidates.referrals.map((candidate) => (
              <CandidateReferCard candidate={candidate} key={uuid4()} />
            ))}
        </div>
      </InfiniteScroll>
    )
  );
};

export default JobReferrals;
