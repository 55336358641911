import { useAppSelector } from "../../config/hooks";

const useCountryCodes = () => {
  const countryCodes = useAppSelector((state) => state.appConfig.countries);
  const modifiedCountryCodes = countryCodes.map((country) => ({
    ...country,
    PhoneCodeLabel: `${country.Iso3} +${country.PhoneCode}`,
  }));

  const defaultCountryCode = (countryId) => {
    const defaultCountryCode = modifiedCountryCodes.find(
      (code) => code.PhoneCode == countryId
    );
    return defaultCountryCode;
  };
  return { modifiedCountryCodes, defaultCountryCode };
};

export default useCountryCodes;
