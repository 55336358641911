import apiClient from "../config/axiosConfig";
import apiCompanyClient from "../config/local-axios-config/companyApiConfig";

class CompanyMemberService {
  createMember(createMemberReq) {
    return apiClient.post(`/v1/exo/companies/users`, createMemberReq);
  }

  getMembers(req) {
    return apiClient.get(`/v1/exo/companies/${req.companyId}/users`, {
      params: { authorId: req.authorId },
    });
  }

  getMemberById(req) {
    return apiClient.get(`/v1/exo/companies/users/${req.memberId}`);
  }

  removeMember(req) {
    return apiClient.delete(`/v1/exo/companies/users/${req.memberId}`);
  }

  updateMember(req) {
    return apiClient.put(`/v1/exo/companies/users/${req.memberId}`, req);
  }
}

const companyMemberService = new CompanyMemberService();
export default companyMemberService;
