import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import candidateProfileService from "../services/candidateProfile.service";
import { logout } from "./loginSlice";

const initialState = {
  userDetails: null,
  userExperience: [],
  userLanguages: [],
  userAwards: [],
  userPublications: [],
  userEducation: [],
  userAdditionalInfo: null,
  isLoading: true,
  error: false,
};

export const getCandidateDetailApi = createAsyncThunk(
  "candidate/getCandidateDetailsApi",
  async (data, { getState, rejectWithValue }) => {
    try {
      const response = await candidateProfileService.getCandidateDetailApi(
        data
      );
      return response;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const addExperienceApi = createAsyncThunk(
  "user/addExperienceApi",
  async (data, { getState, rejectWithValue }) => {
    try {
      const response = await candidateProfileService.addExperience(data);
      return response;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const addEducationApi = createAsyncThunk(
  "user/addEducationApi",
  async (data, { getState, rejectWithValue }) => {
    try {
      const response = await candidateProfileService.addEducation(data);
      return response;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const userEducationDelete = createAsyncThunk(
  "user/userEducationDelete",
  async (data, { getState, rejectWithValue }) => {
    try {
      const response = await candidateProfileService.deleteUserInfo(data);
      return response;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const userExperienceDelete = createAsyncThunk(
  "user/userExperienceDelete",
  async (data, { getState, rejectWithValue }) => {
    try {
      const response = await candidateProfileService.deleteUserInfo(data);
      return response;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const updateUserProfileApi = createAsyncThunk(
  "user/updateUserProfileApi",
  async (data, { getState, rejectWithValue }) => {
    try {
      const response = await candidateProfileService.updateUserDetail(data);
      return response;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const createAdditionalDetailsApi = createAsyncThunk(
  "user/createAdditionalDetailsApi",
  async (data, { getState, rejectWithValue }) => {
    try {
      const response = await candidateProfileService.createAdditionalDetails(
        data
      );
      return response;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const updateAdditionalDetailsApi = createAsyncThunk(
  "user/updateAdditionalDetailsApi",
  async (data, { getState, rejectWithValue }) => {
    try {
      const response = await candidateProfileService.updateAdditionalDetails(
        data
      );
      return response;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const updateExperienceApi = createAsyncThunk(
  "user/updateExperienceApi",
  async (data, { getState, rejectWithValue }) => {
    try {
      const response = await candidateProfileService.updateExperience(data);
      return response;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const updateEducationApi = createAsyncThunk(
  "user/updateEducationApi",
  async (data, { getState, rejectWithValue }) => {
    try {
      const response = await candidateProfileService.updateEducation(data);
      return response;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const postLanguagesApi = createAsyncThunk(
  "user/postLanguagesApi",
  async (data, { getState, rejectWithValue }) => {
    try {
      const response = await candidateProfileService.postLanguages(data);
      return response;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const addLanguagesApi = createAsyncThunk(
  "user/addLanguagesApi",
  async (data, { getState, rejectWithValue }) => {
    try {
      const response = await candidateProfileService.addLanguages(data);
      return response;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

// bulk upload apis to add Experience, education, skills, languages, industry, for only bulk upload user

export const getBulkCandidateDetailApi = createAsyncThunk(
  "candidate/getBulkCandidateDetailApi",
  async (data, { getState, rejectWithValue }) => {
    try {
      const response = await candidateProfileService.getCandidateDetailApi(
        data
      );
      return response;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const addBulkUserExperienceApi = createAsyncThunk(
  "user/addBulkUserExperienceApi",
  async (data, { getState, rejectWithValue }) => {
    try {
      const response = await candidateProfileService.addExperience(data);
      return response;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const addBulkUserEducationApi = createAsyncThunk(
  "user/addBulkUserEducationApi",
  async (data, { getState, rejectWithValue }) => {
    try {
      const response = await candidateProfileService.addEducation(data);
      return response;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const createBulkUploadAdditionalDetailsApi = createAsyncThunk(
  "user/createBulkUploadAdditionalDetailsApi",
  async (data, { getState, rejectWithValue }) => {
    try {
      const response = await candidateProfileService.createAdditionalDetails(
        data
      );
      return response;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const updateBulkUserProfileApi = createAsyncThunk(
  "user/updateBulkUserProfileApi",
  async (data, { getState, rejectWithValue }) => {
    try {
      const response = await candidateProfileService.updateUserDetail(data);
      return response;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const candidateProfileSlice = createSlice({
  name: "candidateProfile",
  initialState,
  reducers: {
    removeCandidateDetails: (state, action) => {
      state.userDetails = null;
      state.userExperience = [];
      state.userLanguages = [];
      state.userAwards = [];
      state.userPublications = [];
      state.userEducation = [];
      state.userAdditionalInfo = null;
      state.isLoading = true;
      state.error = false;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getCandidateDetailApi.pending, (state, action) => {
      state.isLoading = true;
    });

    builder.addCase(getCandidateDetailApi.fulfilled, (state, action) => {
      const response = action.payload.data.data;
      state.isLoading = false;
      state.error = false;
      state.userDetails = response.userDetails;
      state.userLanguages = response.userLanguage;
      state.userExperience = response.userExperience;
      state.userEducation = response.userEducation;
      state.userAwards = response.userAwards;
      state.userAdditionalInfo = response.userAdditionalInfo;
    });

    builder.addCase(getCandidateDetailApi.rejected, (state, action) => {
      state.isLoading = false;
      state.error = true;
    });

    builder.addCase(addEducationApi.fulfilled, (state, action) => {
      const response = action.payload.data.data;
      state.userEducation.push(response);
    });

    builder.addCase(userEducationDelete.fulfilled, (state, action) => {
      const response = action.payload.data.data;
      const filteredArray = state.userEducation.filter(
        (eduDetail) => eduDetail.id !== response.id
      );
      state.userEducation = filteredArray;
    });

    builder.addCase(addExperienceApi.fulfilled, (state, action) => {
      const response = action.payload.data.data;
      state.userExperience.push(response);
    });

    builder.addCase(userExperienceDelete.fulfilled, (state, action) => {
      const response = action.payload.data.data;
      const filteredArray = state.userExperience.filter(
        (expDetail) => expDetail.id !== response.id
      );
      state.userExperience = filteredArray;
    });

    builder.addCase(updateUserProfileApi.fulfilled, (state, action) => {
      const response = action.payload.data.data;
      state.userDetails = response;
    });

    builder.addCase(createAdditionalDetailsApi.fulfilled, (state, action) => {
      const response = action.payload.data.data;
      state.userAdditionalInfo = response;
    });

    builder.addCase(updateAdditionalDetailsApi.fulfilled, (state, action) => {
      const response = action.payload.data.data;
      state.userAdditionalInfo = response;
    });

    builder.addCase(updateExperienceApi.fulfilled, (state, action) => {
      const experiences = state.userExperience;
      const expDetail = action.payload.data.data;
      const newArray = experiences.map((experienceDetail) => {
        if (experienceDetail.id === expDetail.id) {
          return expDetail;
        } else {
          return experienceDetail;
        }
      });
      state.userExperience = newArray;
    });

    builder.addCase(updateEducationApi.fulfilled, (state, action) => {
      const educations = state.userEducation;
      const educationDetail = action.payload.data.data;
      const newArray = educations.map((edDetail) => {
        if (edDetail.id === educationDetail.id) {
          return educationDetail;
        } else {
          return edDetail;
        }
      });
      state.userEducation = newArray;
    });

    builder.addCase(postLanguagesApi.fulfilled, (state, action) => {
      state.userLanguages = action.meta.arg.languages;
    });

    builder.addCase(addLanguagesApi.fulfilled, (state, action) => {
      state.userLanguages = action.payload.data.data;
    });

    builder.addCase(logout, (state) => {
      // Reset this slice's state to initial state
      return initialState;
    });
  },
});

export const { removeCandidateDetails } = candidateProfileSlice.actions;
export default candidateProfileSlice.reducer;
