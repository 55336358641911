import { useAppSelector } from "../../config/hooks";

const useLanguages = () => {
  const laguagesArray = useAppSelector((state) => state.appConfig.languages);

  const defaultLanguage = (id) => {
    const filteredLocation = laguagesArray.filter(
      (location) => location.Id == id
    );
    return filteredLocation;
  };

  const defaultLanguages = (ids) => {
    const filteredLocation = laguagesArray.filter((lang) =>
      ids.some((obj2) => obj2.language == lang.Id)
    );
    return filteredLocation;
  };
  return { laguagesArray, defaultLanguage, defaultLanguages };
};

export default useLanguages;
