import React, { useEffect, useMemo, useRef, useState } from "react";
import RightArrowIcon from "../../assets/Icons/RightArrowIcon";
import VerticleThreeDotsIcon from "../../assets/Icons/VerticleThreeDotsIcon";
import LocationIcon from "../../assets/Icons/LocationIcon";
import * as constants from "../../constant";
import { useAppDispatch, useAppSelector } from "../../config/hooks";
import { updateApplicantStatusApi } from "../../slices/jobSlice";
import useLocations from "../../utils/dropdown-options/useLocations";
import axios from "axios";
import useIndustries from "../../utils/dropdown-options/useIndustries";
import {
  updateApplicantStatusByMemberApi,
  updateReferalStatusApi,
} from "../../slices/referSlice";
import { unwrapResult } from "@reduxjs/toolkit";
import { Link, useNavigate } from "react-router-dom";

const CandidateCard = ({
  candidate,
  candidateDetails,
  setAppliedCadidateViaRefer,
  appliedCadidateViaRefer,
}) => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const menuRef = useRef(null);
  const menuParentRef = useRef(null);

  const loginSelector = useAppSelector((state) => state?.login?.session);
  const memberSelector = useAppSelector((state) => state.member.memberDetails);

  const { defaultIndustry } = useIndustries();

  const preferdIndustry = useMemo(() => {
    if (candidate?.preferredIndustry) {
      return candidate?.preferredIndustry[0]
        ? defaultIndustry(candidate.preferredIndustry[0])
        : null;
    } else if (
      candidate?.applications &&
      candidate?.applications[0]?.preferredIndustry[0]
    ) {
      return defaultIndustry(candidate?.applications[0]?.preferredIndustry[0]);
    }
    return null;
  }, [candidate?.applications, candidate.preferredIndustry]);

  const token = useAppSelector((state) => state?.login?.session?.token);

  const [toggle, setToggle] = useState(false);

  const { defaultLocation } = useLocations();

  useEffect(() => {
    const toggleHandler = (e) => {
      if (
        menuRef.current &&
        menuParentRef &&
        !menuRef.current.contains(e.target) &&
        !menuParentRef.current.contains(e.target)
      ) {
        // close popup
        setToggle(false);
      }
    };
    document.addEventListener("mousedown", toggleHandler);

    return () => {
      document.removeEventListener("mousedown", toggleHandler);
    };
  });

  const toggleStateModal = () => {
    setToggle(!toggle);
  };

  const handleDownload = async () => {
    if (candidate?.attachment?.url) {
      axios
        .get(`${constants.baseUrl}/${candidate?.attachment?.url}?t=${token}`, {
          headers: {
            "Content-Type": "application/octet-stream",
          },
          responseType: "blob",
        })
        .then((response) => {
          const a = document.createElement("a");
          const url = window.URL.createObjectURL(response.data);
          a.href = url;
          a.download = candidate?.attachment?.name;
          a.click();
        })
        .catch((err) => {});
    } else if (
      (loginSelector?.who === constants.COMPANY_MEMBER.toLowerCase() &&
        memberSelector.designation === constants.CANDIDATE_MANEGMENT) ||
      loginSelector?.who === constants.RECRUITER.toLowerCase()
    ) {
      axios
        .get(
          `${constants.baseUrl}/${candidate?.applications[0]?.attachment?.url}?t=${token}`,
          {
            headers: {
              "Content-Type": "application/octet-stream",
            },
            responseType: "blob",
          }
        )
        .then((response) => {
          const a = document.createElement("a");
          const url = window.URL.createObjectURL(response.data);
          a.href = url;
          a.download = candidate?.applications[0]?.attachment?.name;
          a.click();
        })
        .catch((err) => {});
    }
  };

  const updateStateHandler = async (applicantStatus) => {
    if (
      loginSelector?.who === constants.COMPANY.toLowerCase() ||
      (loginSelector?.who === constants.COMPANY_MEMBER.toLowerCase() &&
        memberSelector.designation === constants.ADMIN)
    ) {
      dispatch(
        updateApplicantStatusApi({
          jobId: candidate.jobId,
          applicationId: candidate.id,
          status: applicantStatus,
          subStatus: "APPLICATION_VIEWED", //APPLICATION_CONTACT_VIEWED
        })
      );
    } else if (
      (loginSelector?.who === constants.COMPANY_MEMBER.toLowerCase() &&
        memberSelector.designation === constants.CANDIDATE_MANEGMENT) ||
      loginSelector?.who === constants.RECRUITER.toLowerCase()
    ) {
      const resp = await dispatch(
        updateApplicantStatusByMemberApi({
          jobId: candidate.jobId,
          applicationId: candidate.applications[0].id,
          status: applicantStatus,
          subStatus: "APPLICATION_VIEWED", //APPLICATION_CONTACT_VIEWED
        })
      ).then(unwrapResult);
      const newArray = appliedCadidateViaRefer.referrals.map((ref) => {
        return {
          ...ref,
          applications: ref.applications.map((apps) => {
            if (apps.id === resp.data.data.id) {
              return { ...apps, status: resp.data.data.status };
            }
          }),
        };
      });

      setAppliedCadidateViaRefer({
        ...appliedCadidateViaRefer,
        referrals: newArray,
      });
      dispatch(
        updateReferalStatusApi({
          referalId: candidate.id,
          status: applicantStatus,
          subStatus: "APPLICATION_VIEWED",
        })
      ).then(unwrapResult);
    }
    toggleStateModal();
  };

  const candidateLocation = useMemo(() => {
    return candidateDetails?.location
      ? isNaN(candidateDetails?.location)
        ? candidateDetails?.location
        : defaultLocation(candidateDetails?.location)
      : "";
  }, [candidateDetails?.location]);

  const navigateHandler = () => {
    if (candidateDetails.userId)
      navigate(`/job-seekers/${candidateDetails.userId}`);
  };

  //   <Link
  //   to={
  //     candidate.author.userId
  //       ? `/job-seekers/${candidate.author.userId}`
  //       : ""
  //   }
  // >

  return (
    // <div className="flex items-center justify-between p-4 bg-transparent gap-2 border-b border-b-exgray-100">
    //   <Link
    //     className="flex items-center justify-start gap-5"
    //     to={
    //       candidateDetails.userId
    //         ? `/job-seekers/${candidateDetails.userId}`
    //         : ""
    //     }
    //   >
    //     {candidateDetails.picUrl ? (
    //       <div className="h-28 w-28  rounded-lg flex items-center justify-center overflow-hidden">
    //         <img
    //           src={`${constants.baseUrl}/${candidateDetails?.picUrl}?t=${token}`}
    //           alt="profile"
    //           className="w-full h-full object-cover"
    //         />
    //       </div>
    //     ) : (
    //       <div className="h-28 w-28 rounded-lg bg-DefaultProfileImg bg-center bg-cover bg-no-repeat"></div>
    //     )}

    //     <div className="w-60 space-y-1">
    //       <h1 className="text-lg text-exgray-800 font-semibold leading-none">{`${candidateDetails?.firstName} ${candidateDetails?.lastName}`}</h1>
    //       <p className="text-base text-exgray-400">
    //         {candidateDetails?.designation}
    //       </p>
    //       <div className="flex items-center justify-start gap-2">
    //         <LocationIcon width="20" height="20" color="#5071C4" />
    //         <p className="text-expurple-700 text-base font-semibold">
    //           {candidateLocation?.Name}
    //         </p>
    //       </div>

    //       <p className="text-base text-exgray-500 font-semibold line-clamp-1">
    //         {preferdIndustry ? preferdIndustry?.Name : "N/A"}
    //       </p>
    //     </div>
    //   </Link>

    //   <p className="text-exgray-800 text-base font-semibold">
    //     {candidateDetails?.totalExperience
    //       ? `${candidateDetails?.totalExperience} Yrs Exp`
    //       : "N/A"}
    //   </p>

    //   <p className="bg-blue-100 text-[#223870] text-center py-1 px-4 rounded-full">
    //     {candidate?.referralType === constants.COMPANY.toLowerCase()
    //       ? "Company"
    //       : candidate?.referralType === constants.COMPANY_MEMBER.toLowerCase()
    //       ? "Member"
    //       : candidate?.referralType === constants.USER.toLowerCase()
    //       ? "User"
    //       : candidate?.referralType === constants.RECRUITER.toLowerCase()
    //       ? "External"
    //       : ""}
    //   </p>

    //   {/* <p className="text-2xl text-green-600 font-semibold">79 %</p> */}

    //   {loginSelector?.who === constants.COMPANY_MEMBER.toLowerCase() &&
    //     memberSelector.designation === constants.ADMIN && (
    //       <p className="text-base text-exgray-800">
    //         {candidate.status === constants.APPLICATION_SEND
    //           ? "In progress"
    //           : candidate.status === constants.HOLD
    //           ? "Hold"
    //           : candidate.status === constants.REJECTED
    //           ? "Rejected"
    //           : candidate.status === constants.SHORTLISTED
    //           ? "Shortlisted"
    //           : "In progress"}
    //       </p>
    //     )}

    //   {((loginSelector?.who === constants.COMPANY_MEMBER.toLowerCase() &&
    //     memberSelector.designation === constants.CANDIDATE_MANEGMENT) ||
    //     loginSelector?.who === constants.RECRUITER.toLowerCase()) && (
    //     <p className="text-base text-exgray-800">
    //       {candidate?.applications &&
    //       candidate?.applications[0]?.status === constants.APPLICATION_SEND
    //         ? "In progress"
    //         : candidate?.applications &&
    //           candidate?.applications[0]?.status === constants.HOLD
    //         ? "Hold"
    //         : candidate?.applications &&
    //           candidate?.applications[0]?.status === constants.REJECTED
    //         ? "Rejected"
    //         : candidate?.applications &&
    //           candidate?.applications[0]?.status === constants.SHORTLISTED
    //         ? "Shortlisted"
    //         : candidate?.applications &&
    //           candidate?.applications[0]?.status === constants.APPLIED
    //         ? "In progress"
    //         : "In progress"}
    //     </p>
    //   )}

    //   {candidate?.percentageMatch && (
    //     <p
    //       className={`text-xl font-semibold ${
    //         candidate?.percentageMatch > 70
    //           ? "text-[#0BA02C]"
    //           : candidate?.percentageMatch < 70 &&
    //             candidate?.percentageMatch > 50
    //           ? "text-[#CAA464]"
    //           : "text-[#E05151]"
    //       }`}
    //     >{`${candidate?.percentageMatch} %`}</p>
    //   )}

    //   {loginSelector?.who === constants.COMPANY.toLowerCase() && (
    //     <p className="text-base text-exgray-800">
    //       {candidate.status === constants.APPLICATION_SEND
    //         ? "In progress"
    //         : candidate.status === constants.HOLD
    //         ? "Hold"
    //         : candidate.status === constants.REJECTED
    //         ? "Rejected"
    //         : candidate.status === constants.SHORTLISTED
    //         ? "Shortlisted"
    //         : "In progress"}
    //     </p>
    //   )}

    //   <button
    //     className="text-base text-[#0BA02C] bg-green-100 pl-5 pr-3 py-2 text-center rounded-md flex items-center justify-center gap-2 active:scale-90"
    //     onClick={handleDownload}
    //   >
    //     Download
    //     <div className="rotate-90">
    //       <RightArrowIcon color="#0BA02C" />
    //     </div>
    //   </button>

    //   <div className="relative">
    //     <div
    //       className="cursor-pointer"
    //       onClick={toggleStateModal}
    //       ref={menuParentRef}
    //     >
    //       <VerticleThreeDotsIcon />
    //     </div>
    //     {toggle && (
    //       <div
    //         className="absolute bg-expurple-100 top-7 right-2 w-32 rounded-lg overflow-hidden"
    //         style={{
    //           boxShadow: "0px 0px 20px 1px rgba(0, 0, 0, 0.7)",
    //         }}
    //         ref={menuRef}
    //       >
    //         <button
    //           className="py-2 w-full text-center text-base text-expurple-600 font-semibold hover:bg-expurple-700/30"
    //           onClick={() => updateStateHandler(constants.SHORTLISTED)}
    //         >
    //           Shortlisted
    //         </button>
    //         <button
    //           className="py-2 w-full text-center text-base text-expurple-600 font-semibold hover:bg-expurple-700/30 whitespace-nowrap"
    //           onClick={() => updateStateHandler(constants.HOLD)}
    //         >
    //           Hold
    //         </button>
    //         <button
    //           className="py-2 w-full text-center text-base text-expurple-600 font-semibold hover:bg-expurple-700/30"
    //           onClick={() => updateStateHandler(constants.REJECTED)}
    //         >
    //           Rejected
    //         </button>
    //       </div>
    //     )}
    //   </div>
    // </div>
    <div className="flex items-center p-4 bg-transparent gap-2 border-b border-b-exgray-100">
      <div className="w-fit cursor-pointer" onClick={navigateHandler}>
        {candidateDetails.picUrl ? (
          <div className="h-28 w-28  rounded-lg flex items-center justify-center overflow-hidden">
            <img
              src={`${constants.baseUrl}/${candidateDetails?.picUrl}?t=${token}`}
              alt="profile"
              className="w-full h-full object-cover"
            />
          </div>
        ) : (
          <div className="h-28 w-28 rounded-lg bg-DefaultProfileImg bg-center bg-cover bg-no-repeat"></div>
        )}
      </div>

      <div className="flex-1 space-y-1 mx-4">
        <h1
          className="text-lg text-exgray-800 font-semibold leading-none cursor-pointer line-clamp-1"
          onClick={navigateHandler}
        >{`${candidateDetails?.firstName} ${candidateDetails?.lastName}`}</h1>
        <p className="text-base text-exgray-400 line-clamp-1">
          {candidateDetails?.designation}
        </p>
        <div className="flex items-center justify-start gap-2">
          <LocationIcon width="20" height="20" color="#5071C4" />
          <p className="text-expurple-700 text-base font-semibold line-clamp-1">
            {candidateLocation?.Name}
          </p>
        </div>

        <p className="text-base text-exgray-500 font-semibold line-clamp-1">
          {preferdIndustry ? preferdIndustry?.Name : "N/A"}
        </p>
      </div>

      <div className="flex-1">
        <p className="text-exgray-800 text-base font-semibold line-clamp-1">
          {candidateDetails?.totalExperience
            ? `${candidateDetails?.totalExperience} Yrs Exp`
            : "N/A"}
        </p>
      </div>

      <div className="flex-1">
        <p className="w-fit bg-blue-100 text-[#223870] text-center py-1 px-4 rounded-full mx-auto line-clamp-1">
          {candidate?.referralType === constants.COMPANY.toLowerCase()
            ? "Company"
            : candidate?.referralType === constants.COMPANY_MEMBER.toLowerCase()
            ? "Member"
            : candidate?.referralType === constants.USER.toLowerCase()
            ? "User"
            : candidate?.referralType === constants.RECRUITER.toLowerCase()
            ? "External"
            : ""}
        </p>
      </div>

      <div className="flex-1">
        {candidate?.percentageMatch &&
          (loginSelector?.who === constants.COMPANY.toLowerCase() ||
            (loginSelector?.who === constants.COMPANY_MEMBER.toLowerCase() &&
              memberSelector.designation === constants.ADMIN)) && (
            <p
              className={`text-xl font-semibold text-center ${
                candidate?.percentageMatch > 70
                  ? "text-[#0BA02C]"
                  : candidate?.percentageMatch < 70 &&
                    candidate?.percentageMatch > 50
                  ? "text-[#CAA464]"
                  : "text-[#E05151]"
              }`}
            >{`${candidate?.percentageMatch} %`}</p>
          )}

        {(loginSelector?.who === constants.RECRUITER.toLowerCase() ||
          (loginSelector?.who === constants.COMPANY_MEMBER.toLowerCase() &&
            memberSelector.designation === constants.CANDIDATE_MANEGMENT)) &&
          candidate?.applications &&
          candidate?.applications[0]?.percentageMatch && (
            <p
              className={`text-xl font-semibold text-center ${
                candidate?.applications[0]?.percentageMatch > 70
                  ? "text-[#0BA02C]"
                  : candidate?.applications[0]?.percentageMatch < 70 &&
                    candidate?.applications[0]?.percentageMatch > 50
                  ? "text-[#CAA464]"
                  : "text-[#E05151]"
              }`}
            >{`${candidate?.applications[0]?.percentageMatch} %`}</p>
          )}
      </div>

      <div className="flex-1 text-center">
        {((loginSelector?.who === constants.COMPANY_MEMBER.toLowerCase() &&
          memberSelector.designation === constants.CANDIDATE_MANEGMENT) ||
          loginSelector?.who === constants.RECRUITER.toLowerCase()) && (
          <p className="text-base text-exgray-800">
            {candidate?.applications &&
            candidate?.applications[0]?.status === constants.APPLICATION_SEND
              ? "In progress"
              : candidate?.applications &&
                candidate?.applications[0]?.status === constants.HOLD
              ? "Hold"
              : candidate?.applications &&
                candidate?.applications[0]?.status === constants.REJECTED
              ? "Rejected"
              : candidate?.applications &&
                candidate?.applications[0]?.status === constants.SHORTLISTED
              ? "Shortlisted"
              : candidate?.applications &&
                candidate?.applications[0]?.status === constants.APPLIED
              ? "In progress"
              : "In progress"}
          </p>
        )}

        {loginSelector?.who === constants.COMPANY.toLowerCase() && (
          <p className="text-base text-exgray-800">
            {candidate.status === constants.APPLICATION_SEND
              ? "In progress"
              : candidate.status === constants.HOLD
              ? "Hold"
              : candidate.status === constants.REJECTED
              ? "Rejected"
              : candidate.status === constants.SHORTLISTED
              ? "Shortlisted"
              : "In progress"}
          </p>
        )}

        {loginSelector?.who === constants.COMPANY_MEMBER.toLowerCase() &&
          memberSelector.designation === constants.ADMIN && (
            <p className="text-base text-exgray-800">
              {candidate.status === constants.APPLICATION_SEND
                ? "In progress"
                : candidate.status === constants.HOLD
                ? "Hold"
                : candidate.status === constants.REJECTED
                ? "Rejected"
                : candidate.status === constants.SHORTLISTED
                ? "Shortlisted"
                : "In progress"}
            </p>
          )}
      </div>

      <div className="flex-1">
        <button
          className="text-base text-[#0BA02C] bg-green-100 pl-5 pr-3 py-2 text-center rounded-md flex items-center justify-center gap-2 active:scale-90 mx-auto"
          onClick={handleDownload}
        >
          Download
          <div className="rotate-90">
            <RightArrowIcon color="#0BA02C" />
          </div>
        </button>
      </div>

      <div className="relative w-fit">
        <div
          className="cursor-pointer"
          onClick={toggleStateModal}
          ref={menuParentRef}
        >
          <VerticleThreeDotsIcon />
        </div>
        {toggle && (
          <div
            className="absolute bg-expurple-100 top-7 right-2 w-32 rounded-lg overflow-hidden"
            style={{
              boxShadow: "0px 0px 20px 1px rgba(0, 0, 0, 0.7)",
            }}
            ref={menuRef}
          >
            <button
              className="py-2 w-full text-center text-base text-expurple-600 font-semibold hover:bg-expurple-700/30"
              onClick={() => updateStateHandler(constants.SHORTLISTED)}
            >
              Shortlisted
            </button>
            <button
              className="py-2 w-full text-center text-base text-expurple-600 font-semibold hover:bg-expurple-700/30 whitespace-nowrap"
              onClick={() => updateStateHandler(constants.HOLD)}
            >
              Hold
            </button>
            <button
              className="py-2 w-full text-center text-base text-expurple-600 font-semibold hover:bg-expurple-700/30"
              onClick={() => updateStateHandler(constants.REJECTED)}
            >
              Rejected
            </button>
          </div>
        )}
      </div>
    </div>
  );
};

export default CandidateCard;
