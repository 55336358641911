import React from "react";

const SalaryRangeIcon = ({
  width = "28",
  height = "22",
  color = "#2D4687",
}) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 28 22"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M4.69763 17.0603H21.2101C22.6779 17.0603 23.8725 15.8657 23.8725 14.3979L23.8736 5.66245C23.8736 4.19467 22.6789 3 21.2111 3H4.6976C3.22983 3 2.03516 4.19462 2.03516 5.66245V14.3971C2.03516 15.8659 3.22875 17.0606 4.6976 17.0606L4.69763 17.0603ZM3.4092 5.66245C3.4092 4.95227 3.98745 4.37402 4.69763 4.37402H21.2101C21.9203 4.37402 22.4985 4.95227 22.4985 5.66245V14.3971C22.4985 15.1072 21.9203 15.6855 21.2101 15.6855L4.69763 15.6865C3.98745 15.6865 3.4092 15.1083 3.4092 14.3981V5.66245Z"
        fill={color}
      />
      <path
        d="M24.5908 9.43236V16.7157C24.5908 17.2166 24.1816 17.6248 23.6786 17.6248H18.3877C18.0084 17.6248 17.7002 17.932 17.7002 18.3123C17.7002 18.6916 18.0074 18.9998 18.3877 18.9998H23.6786C24.9391 18.9998 25.9648 17.9753 25.9648 16.7157V9.43239C25.9648 9.05307 25.6576 8.74487 25.2772 8.74487C24.8979 8.74487 24.5908 9.05305 24.5908 9.43236Z"
        fill={color}
      />
      <path
        d="M12.9542 14.084C15.1899 14.084 17.0081 12.2647 17.0081 10.0302C17.0081 7.79446 15.1888 5.97632 12.9542 5.97632C10.7185 5.97632 8.90039 7.79454 8.90039 10.0302C8.90039 12.2648 10.7186 14.084 12.9542 14.084ZM12.9542 7.34923C14.4323 7.34923 15.6341 8.55108 15.6341 10.0291C15.6341 11.5071 14.4322 12.709 12.9542 12.709C11.4761 12.709 10.2744 11.5071 10.2744 10.0291C10.2744 8.55203 11.4762 7.34923 12.9542 7.34923Z"
        fill={color}
      />
    </svg>
  );
};

export default SalaryRangeIcon;
