import React, { useEffect, useRef, useState } from "react";

import Logo from "../../assets/images/LogowithTagline.png";

import { Link, useLocation, useNavigate } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "../../config/hooks";
import { tokenRepo } from "../../cache/db";
import { logout } from "../../slices/loginSlice";

import { persistor } from "../../store/store";

const Navbar = () => {
  const { pathname } = useLocation();
  const menuRef = useRef(null);
  const menuParentRef = useRef(null);
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const [toggleJoinAs, settoggleJoinAs] = useState(false);
  const loginSelector = useAppSelector((state) => state.login);

  const { session } = loginSelector || {};

  useEffect(() => {
    const toggleHandler = (e) => {
      if (
        menuRef.current &&
        menuParentRef &&
        !menuRef.current.contains(e.target) &&
        !menuParentRef.current.contains(e.target)
      ) {
        // close popup
        settoggleJoinAs(false);
      }
    };
    if (!session?.userId) {
      document.addEventListener("mousedown", toggleHandler);
    }

    return () => {
      if (!session?.userId) {
        document.removeEventListener("mousedown", toggleHandler);
      }
    };
  });

  const removeToken = async () => {
    try {
      await tokenRepo.appToken.delete(1);
      persistor.purge();
      dispatch(logout());

      // if (session?.userId) {
      //   if (session?.who === "user") {
      //     navigate("/");
      //     dispatch(logoutCandidateHandler());
      //     dispatch(removeCandidateDetails());
      //     // dispatch(logout());
      //   } else if (session?.who === "company") {
      //     navigate("/");
      //     dispatch(logoutComapany());
      //     dispatch(logoutCandidateHandler());
      //   } else if (session?.who === "company_member") {
      //     navigate("/");
      //     dispatch(logoutMember());
      //     dispatch(logoutCandidateHandler());
      //   } else if (session?.who === "recruiter") {
      //     navigate("/");
      //     dispatch(removeRecruiterDetails());
      //     dispatch(logoutCandidateHandler());
      //   }
      // }
    } catch (error) {
      console.error(`Error removing token with id 1:`, error);
    }
  };

  // const navigateHandler = () => {
  //   navigate("/");
  // };

  const toggleJoinAsHandler = () => {
    if (session?.userId) {
      removeToken();
    } else {
      settoggleJoinAs(!toggleJoinAs);
    }
  };

  return (
    <div className="h-16 w-full bg-[#E0DFF4] flex items-center justify-center shadow-lg shadow-[#828282] z-10 relative">
      <div className="w-[95%] flex items-center justify-between mx-auto">
        <div className="w-fit">
          <img src={Logo} alt="Exotelent Logo" className="max-w-52" />
        </div>
        <div className="flex items-center justify-between gap-10 relative">
          <ul className="w-fit flex mr-0 ml-auto space-x-10 text-lg text-[#223870] font-medium">
            {!session?.userId && (
              <Link to={"/#about"} className={`cursor-pointer px-2`}>
                About Us
              </Link>
            )}

            {!session?.userId && (
              <Link to={"/#services"} className={`cursor-pointer px-2`}>
                Services
              </Link>
            )}
            {!session?.userId && (
              <Link to={"/#industry"} className={`cursor-pointer px-2`}>
                Industry Verticals
              </Link>
            )}
            {!session?.userId && (
              <Link to={"/#client"} className={`cursor-pointer px-2`}>
                Clients
              </Link>
            )}
            {session?.userId && session?.who === "user" && (
              <Link
                className={`cursor-pointer px-2 ${
                  pathname === "/job-search" &&
                  "font-bold border-b-2 border-b-expurple-800"
                }`}
                to="/job-search"
              >
                Jobs
              </Link>
            )}
            {session?.userId && session?.who === "recruiter" && (
              <Link
                className={`cursor-pointer px-2 ${
                  pathname === "/job-search" &&
                  "font-bold border-b-2 border-b-expurple-800"
                }`}
                to="/job-search"
              >
                Jobs
              </Link>
            )}
            {!session?.userId && (
              <Link
                className={`cursor-pointer px-2 ${
                  pathname === "/job-search" &&
                  "font-bold border-b-2 border-b-expurple-800"
                }`}
                to="/job-search"
              >
                Jobs
              </Link>
            )}
            {session?.userId ? (
              session?.who === "user" ||
              session?.who === "company_member" ||
              session?.who === "recruiter" ? (
                <Link
                  className={`cursor-pointer px-2 ${
                    (pathname === "/applied-jobs" ||
                      pathname === "/member" ||
                      pathname === "/recruiter-dashboard") &&
                    "font-bold border-b-2 border-b-expurple-800"
                  }`}
                  to={
                    session?.who === "user"
                      ? "/applied-jobs"
                      : session?.who === "company_member"
                      ? "/member"
                      : session?.who === "recruiter"
                      ? "/recruiter-dashboard"
                      : ""
                  }
                >
                  Profile
                </Link>
              ) : (
                <Link
                  className={`cursor-pointer px-2 ${
                    pathname === "/company-admin" &&
                    "font-bold border-b-2 border-b-expurple-800"
                  }`}
                  to="/company-admin"
                >
                  Company
                </Link>
              )
            ) : null}
          </ul>

          <div className="relative w-fit space-y-1">
            <button
              type="button"
              className={`px-8 py-2 rounded-lg ${
                session?.userId
                  ? "bg-transparent border-2 border-expurple-800 text-expurple-800"
                  : "bg-expurple-800 text-golden"
              }  text-center text-base font-medium`}
              onClick={toggleJoinAsHandler}
              ref={menuParentRef}
            >
              {session?.userId ? "Logout" : "Join As"}
            </button>
            {toggleJoinAs && !session?.userId && (
              <div
                className="absolute w-fit bg-[#E0DFF4] rounded-lg overflow-hidden"
                ref={menuRef}
              >
                <ul className="text-center text-lg text-expurple-700 font-medium flex flex-col">
                  <Link
                    to={"company"}
                    className="w-full px-5 py-1 hover:bg-expurple-800 hover:text-golden font-medium cursor-pointer"
                    onClick={toggleJoinAsHandler}
                  >
                    Company
                  </Link>

                  <Link
                    to={"candidate"}
                    className="w-full px-5 py-1 hover:bg-expurple-800 hover:text-golden font-medium cursor-pointer"
                    onClick={toggleJoinAsHandler}
                  >
                    Candidate
                  </Link>
                  <Link
                    to={"recruiter"}
                    className="w-full px-5 py-1 hover:bg-expurple-800 hover:text-golden font-medium cursor-pointer"
                    onClick={toggleJoinAsHandler}
                  >
                    Recuiter
                  </Link>
                </ul>
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};
export default Navbar;
