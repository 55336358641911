import React, { useEffect, useMemo, useState } from "react";
import { useAppDispatch, useAppSelector } from "../../config/hooks";
import { getMemberByIdApi } from "../../slices/companyMemberSlice";
import { unwrapResult } from "@reduxjs/toolkit";
import { v4 as uuid4 } from "uuid";
import MemberProfileCard from "./components/MemberProfileCard";
import MemberJobsList from "./components/MemberJobsList";
import * as constants from "../../constant";
import CompanyMembersDashboard from "../company-dashboard/components/CompanyMembersDashboard";

let navigationButtons; // [{ title: "Job List" }, { title: "Members" }];

const MemberPage = () => {
  const dispatch = useAppDispatch();
  const userId = useAppSelector((state) => state?.login?.session?.userId);
  const memberSelector = useAppSelector((state) => state?.member);
  const loginSelector = useAppSelector((state) => state?.login?.session);

  const [toggle, setToggle] = useState(0);

  const { memberDetails } = memberSelector;

  useEffect(() => {
    const fetchData = async () => {
      try {
        await dispatch(getMemberByIdApi({ memberId: userId })).then(
          unwrapResult
        );
      } catch (error) {}
    };

    fetchData();
  }, []);

  const toggleHandler = (index) => {
    setToggle(index);
  };

  navigationButtons = useMemo(() => {
    if (
      loginSelector?.who === constants.COMPANY_MEMBER.toLowerCase() &&
      memberDetails?.designation === constants.ADMIN
    ) {
      return [{ title: "Job List" }, { title: "Members" }];
    } else return [{ title: "Job List" }];
  }, [loginSelector?.who, memberDetails?.designation]);

  return (
    memberDetails && (
      <div className="w-[95%] mx-auto py-10 min-h-screenheight space-y-6">
        <MemberProfileCard />

        <div className="pb-2 border-b-2 border-b-exgray-100">
          {navigationButtons.map((navogationBtn, index) => (
            <button
              key={uuid4()}
              className={`py-2 px-8 text-base ${
                toggle === index
                  ? "bg-expurple-800 text-exwhite-100 rounded-md"
                  : "text-expurple-800"
              }`}
              onClick={() => toggleHandler(index)}
            >
              {navogationBtn.title}
            </button>
          ))}
        </div>

        {toggle === 0 && (
          <div>
            <MemberJobsList />
          </div>
        )}

        {toggle === 1 && (
          <div>
            <CompanyMembersDashboard />
          </div>
        )}
      </div>
    )
  );
};

export default MemberPage;
