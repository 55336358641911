import apiJobsClient from "../config/local-axios-config/jobsApiConfig";
import apiUserClient from "../config/local-axios-config/userApiConfig";
import apiClient from "../config/axiosConfig";

class ReferService {
  // getCandidates(filterData) {
  //   return apiClient.get(`v1/exo/users/paginated?size=20`, {
  //     params: filterData,
  //   });
  // }

  getCandidates(filterData) {
    return apiClient.get(`v1/exo/users/search?size=20`, {
      params: filterData,
    });
  }

  getCandidatesByUrl(req) {
    return apiClient.get(req.nextUrl, { params: req });
  }

  searchCandidate(filterData) {
    return apiClient.get(`/v1/exo/users/refer/search`, {
      params: filterData,
    });
  }

  referCandidate(req) {
    return apiClient.post(`/v1/exo/referrals`, req);
  }

  getAllReferrals(filterData) {
    return apiClient.get(`/v1/exo/referrals`, {
      params: filterData,
    });
  }

  getNextByUrl(filterData) {
    return apiClient.get(filterData.nextUrl, {
      params: filterData,
    });
  }

  updateReferalStatus(req) {
    return apiClient.put(`/v1/exo/referrals/${req.referalId}/status`, req);
  }

  getExternalRecruiterRefferedJob(filterData) {
    return apiClient.get(`/v1/exo/referrals/job`, {
      params: filterData,
    });
  }
}

const referService = new ReferService();
export default referService;
