import React from "react";

const JobTypeIcon = ({ width = "24", height = "24", color = "#223870" }) => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M14.8731 5.63075H18.0948C19.4865 5.63075 20.6112 6.75549 20.6303 8.14712V13.8827C20.3277 13.6508 19.995 13.4564 19.639 13.3062V11.1019C17.847 11.6167 15.7691 11.9407 13.5768 12.0742V12.5317C13.5768 13.0845 13.1193 13.5421 12.5665 13.5421H10.5076C9.95477 13.5421 9.49725 13.0845 9.49725 12.5317V12.0742C7.32402 11.9407 5.26517 11.6167 3.47321 11.1019V16.84C3.47321 17.6979 4.17856 18.3842 5.01735 18.3842H13.4493C13.52 18.7307 13.6302 19.0627 13.7751 19.3755H5.01735C3.62572 19.3755 2.50098 18.2507 2.50098 16.8591V8.14712C2.50098 6.75549 3.62572 5.63075 5.01735 5.63075H8.23906V4.27725C8.23906 3.5719 8.81096 3 9.51631 3H13.5959C14.3012 3 14.8731 3.5719 14.8731 4.27725V5.63075ZM9.51631 3.97223C9.34474 3.97223 9.2113 4.10568 9.2113 4.27725V5.63075H13.9009V4.27725C13.9009 4.10568 13.7675 3.97223 13.5959 3.97223H9.51631ZM18.1139 6.60298H5.01735C4.15949 6.60298 3.47321 7.28927 3.49227 8.14712V10.0916C5.26517 10.6254 7.34308 10.9685 9.51631 11.1019V10.6826C9.51631 10.1297 9.97383 9.67219 10.5267 9.67219H12.6236C13.1765 9.67219 13.634 10.1297 13.634 10.6826V11.1019C15.8072 10.9685 17.8851 10.6254 19.658 10.0916V8.14712C19.658 7.28927 18.9527 6.60298 18.1139 6.60298ZM12.6427 12.5317V10.6826L10.5267 10.6254L10.4885 12.5317L12.6046 12.5698C12.6236 12.5698 12.6427 12.5508 12.6427 12.5317Z"
        fill={color}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M17.8806 13.8572C15.8833 13.8572 14.2617 15.4787 14.2617 17.476C14.2617 19.4733 15.8833 21.0949 17.8806 21.0949C19.8779 21.0949 21.4994 19.4733 21.4994 17.476C21.4994 15.4787 19.8779 13.8572 17.8806 13.8572ZM18.1218 14.3487V14.5809C18.1218 14.7142 18.0139 14.8222 17.8806 14.8222C17.7473 14.8222 17.6393 14.7142 17.6393 14.5809V14.3487C16.1007 14.466 14.8706 15.6958 14.7533 17.2348H14.9855C15.1188 17.2348 15.2267 17.3427 15.2267 17.476C15.2267 17.6093 15.1188 17.7173 14.9855 17.7173H14.7533C14.8706 19.2559 16.1004 20.486 17.6393 20.6033V20.3711C17.6393 20.2378 17.7473 20.1299 17.8806 20.1299C18.0139 20.1299 18.1218 20.2378 18.1218 20.3711V20.6033C19.6605 20.486 20.8905 19.2562 21.0079 17.7173H20.7757C20.6424 17.7173 20.5344 17.6093 20.5344 17.476C20.5344 17.3427 20.6424 17.2348 20.7757 17.2348H21.0079C20.8906 15.6961 19.6608 14.4661 18.1218 14.3487ZM17.6393 15.546V17.476C17.6393 17.5608 17.6836 17.6395 17.7566 17.6829L18.9629 18.4067C19.0769 18.4751 19.2253 18.4384 19.2937 18.3241C19.3622 18.2101 19.3254 18.0617 19.2111 17.9932L18.1222 17.3397V15.5463C18.1222 15.413 18.0142 15.3051 17.8809 15.3051C17.7476 15.3051 17.6397 15.413 17.6397 15.5463L17.6393 15.546Z"
        fill={color}
        stroke={color}
        strokeWidth="0.3"
      />
    </svg>
  );
};

export default JobTypeIcon;
