import React from "react";
import { Link } from "react-router-dom";

const CandidateReferCard = ({ candidate }) => {
  return (
    <div className="flex items-center justify-start p-4 bg-exwhite-100 gap-4 border-b border-b-exgray-100">
      <Link
        to={
          candidate?.userDetails?.userId
            ? `/job-seekers/${candidate?.userDetails?.userId}`
            : ""
        }
        className="h-28 w-28 bg-DefaultProfileImg bg-center bg-cover bg-no-repeat cursor-pointer"
      ></Link>

      <div className="w-60 space-y-2">
        <Link
          to={
            candidate?.userDetails?.userId
              ? `/job-seekers/${candidate?.userDetails?.userId}`
              : ""
          }
          className="text-lg text-exgray-800 font-semibold leading-none cursor-pointer"
        >{`${candidate?.userDetails?.firstName} ${candidate?.userDetails?.lastName}`}</Link>
        <p className="text-base text-exgray-400">
          {candidate?.userDetails?.designation}
        </p>
        {/* <div className="flex items-center justify-start gap-2">
          <LocationIcon width="20" height="20" color="#5071C4" />
          <p className="text-expurple-700 text-base font-semibold">
            {candidate?.userDetails?.location}
          </p>
        </div> */}

        {/* <p className="text-base text-exgray-500 font-semibold">
          {candidate?.userDetails?.industry}
        </p> */}
      </div>

      <div className="w-fit ml-auto mr-4 flex items-center justify-center gap-2">
        {/* <DoubleChecksIcon /> */}
        <p className="text-exgray-200 text-base font-semibold">
          Action Pending...
        </p>
      </div>
    </div>
  );
};

export default CandidateReferCard;
