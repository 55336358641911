import React, { useMemo } from "react";
import WorkPlaceIcon from "../../../assets/Icons/WorkPlaceIcon";
import IndustryIcon from "../../../assets/Icons/IndustryIcon";
import SalaryRangeIcon from "../../../assets/Icons/SalaryRangeIcon";
import GlobeIcon from "../../../assets/Icons/GlobeIcon";
import EnvelopeIcon from "../../../assets/Icons/EnvelopeIcon";
import useCompanyTypes from "../../../utils/dropdown-options/useCompanyTypes";
import useIndustries from "../../../utils/dropdown-options/useIndustries";
import useCompanySize from "../../../utils/dropdown-options/useCompanySize";
import { useAppSelector } from "../../../config/hooks";

const CompanyDashboardDetails = () => {
  const { defaultCompanyType } = useCompanyTypes();
  const { defaultIndustry } = useIndustries();
  const { defaultCompanySize } = useCompanySize();

  const companySelector = useAppSelector((state) => state.company);
  const { companyDetails } = companySelector;
  const { emailId, pageName, industry, size, registrationType, description } =
    companyDetails || {};

  const defaultOrgType = useMemo(
    () => defaultCompanyType(registrationType),
    [registrationType]
  );

  const defaultIndustryType = useMemo(
    () => defaultIndustry(industry),
    [industry]
  );

  const defaultSize = useMemo(() => defaultCompanySize(size), [size]);
  return (
    <div className="flex items-center justify-center gap-4">
      <div className="flex-1 self-start">
        <h1 className="text-xl text-expurple-600 font-semibold">About Us</h1>
        <p className="text-base text-exgray-300 text-start whitespace-pre-wrap">
          {description}
        </p>
      </div>

      <div className="flex-1 h-screen self-start space-y-2">
        <div className="border-2 border-expurple-100 rounded-lg grid grid-cols-2 auto-rows-auto gap-y-6 p-5">
          <div className="text-start text-base space-y-1">
            <div>
              <WorkPlaceIcon />
            </div>
            <h1 className="text-exgray-200 text-sm">ORGANIZATION TYPE</h1>
            <p className="text-expurple-800 font-semibold">
              {defaultOrgType?.Title}
            </p>
          </div>

          <div className="text-start text-base space-y-1">
            <div>
              <IndustryIcon />
            </div>
            <h1 className="text-exgray-200 text-sm">INDUSTRY</h1>
            <p className="text-expurple-800 font-semibold">
              {defaultIndustryType?.Name}
            </p>
          </div>

          <div className="text-start text-base space-y-1">
            <div>
              <SalaryRangeIcon />
            </div>
            <h1 className="text-exgray-200 text-sm">TEAM SIZE</h1>
            <p className="text-expurple-800 font-semibold text-start">
              {defaultSize?.Title}
            </p>
          </div>
        </div>

        <div className="border-2 border-expurple-100 rounded-lg p-5 space-y-4">
          <h1 className="text-xl text-expurple-600 font-semibold">
            Connect Us
          </h1>

          <div className="flex items-center justify-start gap-3 pb-2 border-b-2 border-b-expurple-100">
            <div>
              <GlobeIcon />
            </div>
            <div className="flex-1">
              <h1 className="text-exgray-200 text-sm">WEBSITE</h1>
              <p className="text-expurple-800 font-semibold text-start">
                {pageName}
              </p>
            </div>
          </div>

          <div className="flex items-center justify-start gap-3 pb-2 border-b-2 border-b-expurple-100">
            <div>
              <EnvelopeIcon />
            </div>
            <div className="flex-1">
              <h1 className="text-exgray-200 text-sm">EMAIL ADRESS</h1>
              <p className="text-expurple-800 font-semibold text-start">
                {emailId}
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CompanyDashboardDetails;
