import { useAppSelector } from "../../config/hooks";

const useLocations = () => {
  const locationsArray = useAppSelector((state) => state.appConfig.cities);

  const defaultLocation = (id) => {
    if (isNaN(id)) {
      return { Id: id, Name: id, State: id };
    }
    const filteredLocation = locationsArray.find(
      (location) => location.Id == id
    );
    return filteredLocation;
  };

  // const defaultLocations = (dafaultIds) => {
  //   const selectedIndustry = locationsArray.filter((location) =>
  //     dafaultIds.some((obj2) => obj2 == location.Id)
  //   );
  //   return selectedIndustry;
  // };

  const defaultLocations = (dafaultIds) => {
    const selectedIndustry = dafaultIds.map((id) => {
      if (isNaN(id)) {
        return { Id: id, Name: id, State: id };
      }

      const filteredLocation = locationsArray.find(
        (location) => location.Id == id
      );
      return filteredLocation;
    });

    return selectedIndustry;
  };

  return { locationsArray, defaultLocation, defaultLocations };
};

export default useLocations;
