import apiClient from "../config/axiosConfig";
import apiCompanyAuthClient from "../config/local-axios-config/companyAuthApiConfig";
import apiRecruiterAuthClient from "../config/local-axios-config/recruiterAuthApiConfig";
import apiUserClient from "../config/local-axios-config/userApiConfig";
import apiUserAuthClient from "../config/local-axios-config/userAuthAPIConfig";

class AuthService {
  // Candidate auth -->
  getOTP(authReq) {
    return apiClient.post(`/v1/exo/auth/otp`, authReq);
  }

  verifyOTP(registerReq) {
    return apiClient.post(`/v1/exo/auth/otp/verify`, registerReq);
  }

  newSignUp(newregisterReq) {
    return apiClient.post(`/v1/exo/auth/register`, newregisterReq);
  }

  // create refer auth

  createRefer(req) {
    return apiClient.post(`/v1/exo/auth/refer`, req);
  }

  //Comapny Auth

  verifyDomain(domainVerifyReq) {
    return apiClient.post(`/v1/exo/companies/authorize`, domainVerifyReq);
  }

  getOtpForDomainVerify(getOtp) {
    return apiClient.post(`/v1/exo/auth/company/otp`, getOtp);
  }

  verifyDomainOtp(otpVerifyReq) {
    return apiClient.post(`/v1/exo/auth/company/otp/verify`, otpVerifyReq);
  }

  createCompany(newCompanyReq) {
    return apiClient.post(`/v1/exo/auth/company/register`, newCompanyReq);
  }

  registerCompanyMember(newCompanyReq) {
    return apiClient.post(
      `/v1/exo/auth/company/member/register`,
      newCompanyReq
    );
  }

  //Recruiter Auth
  getRecruiterOtp(authReq) {
    return apiClient.post(`/v1/exo/auth/recruiter/otp`, authReq);
  }

  verifyRecruiterOTP(verifyReq) {
    return apiClient.post(`/v1/exo/auth/recruiter/otp/verify`, verifyReq);
  }

  registerRecruiter(newregisterReq) {
    return apiClient.post(`/v1/exo/auth/recruiter/register`, newregisterReq);
  }

  //Candidate Bulk Upload

  newSignUps(newregisterReq) {
    return apiClient.post(`/v1/exo/auth/register/bulk`, newregisterReq);
  }
}

const authService = new AuthService();
export default authService;
