import DatePicker from "react-datepicker";

import "react-datepicker/dist/react-datepicker.css";
import "../../../src/App.css";

const CustomDatePicker = ({ name, title, error, ...props }) => {
  return (
    <>
      <label className="text-lg font-normal text-expurple-800" htmlFor={name}>
        {title}
      </label>
      <div className="w-full">
        <DatePicker
          className={`text-expurple-700 text-base  h-10 rounded-sm pl-2 outline-none w-full ${
            props.disabled
              ? "bg-exgray-100/80 placeholder:text-exgray-400/80"
              : "bg-expurple-100 placeholder:text-expurple-700"
          }`}
          {...props}
          id={name}
        />
      </div>
      <p className="text-red-900">{error}</p>
    </>
  );
};

export default CustomDatePicker;
