import React from "react";

import { v4 as uuidv4 } from "uuid";
import UploadedUserEduCard from "./UploadedUserEduCard";

const UploadedEducationSection = ({ userDetails }) => {
  return (
    <div className="space-y-2">
      <div className="flex items-center justify-between">
        <h1 className="text-lg text-black text-semibold text-start">
          Education
        </h1>
      </div>

      <div className="space-y-2">
        {userDetails.userEducation?.map((edDetail) => (
          <UploadedUserEduCard educationDetail={edDetail} key={uuidv4()} />
        ))}
      </div>
    </div>
  );
};

export default UploadedEducationSection;
