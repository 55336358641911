import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import authService from "../services/auth.service";
import { tokenRepo } from "../cache/db";
import * as constants from "../constant";

const initialState = {
  session: null,
  accountExists: false,
  ui: {
    requestId: "",
    isEmail: false,
    email: "",
    countryCode: "",
    phone: "",
    loginType: "",
    isVerified: false,
    toggleOtp: false,
  },
};

//Canadiate auth -->

export const getOtpApi = createAsyncThunk(
  "login/getOtpApi",
  async (data, { getState, rejectWithValue }) => {
    try {
      const response = await authService.getOTP(data);
      return response;
    } catch (error) {
      return rejectWithValue(error.response?.data);
    }
  }
);

export const verifyOtpApi = createAsyncThunk(
  "login/verifyOtpApi",
  async (data, { getState, rejectWithValue }) => {
    try {
      const response = await authService.verifyOTP(data);
      return response;
    } catch (error) {
      return rejectWithValue(error.response?.data);
    }
  }
);

export const registerApi = createAsyncThunk(
  "login/register",
  async (data, { getState, rejectWithValue }) => {
    try {
      const response = await authService.newSignUp(data);
      return response;
    } catch (error) {
      return rejectWithValue(error.response?.data);
    }
  }
);

//Company auth -->

export const verifyCompanyDomain = createAsyncThunk(
  "login/verifyDomain",
  async (data, { getState, rejectWithValue }) => {
    try {
      const response = await authService.verifyDomain(data);
      return response;
    } catch (error) {
      return rejectWithValue(error.response?.data);
    }
  }
);

export const getOtpDomainVerification = createAsyncThunk(
  "login/getOtpDomainVerification",
  async (data, { getState, rejectWithValue }) => {
    try {
      return await authService.getOtpForDomainVerify(data);
    } catch (error) {
      return rejectWithValue(error.response?.data);
    }
  }
);

export const verifyCompanyOtpApi = createAsyncThunk(
  "login/verifyCompanyOtpApi",
  async (data, { getState, rejectWithValue }) => {
    try {
      return await authService.verifyDomainOtp(data);
    } catch (error) {
      return rejectWithValue(error.response?.data);
    }
  }
);

export const createCompanyApi = createAsyncThunk(
  "login/createCompanyApi",
  async (data, { getState, rejectWithValue }) => {
    try {
      const response = await authService.createCompany(data);
      return response;
    } catch (error) {
      return rejectWithValue(error.response?.data);
    }
  }
);

export const registerCompanyMemberApi = createAsyncThunk(
  "login/registerCompanyMemberApi",
  async (data, { getState, rejectWithValue }) => {
    try {
      const response = await authService.registerCompanyMember(data);
      return response;
    } catch (error) {
      return rejectWithValue(error.response?.data);
    }
  }
);

export const createReferApi = createAsyncThunk(
  "login/createReferApi",
  async (data, { getState, rejectWithValue }) => {
    try {
      const response = await authService.createRefer(data);
      return response;
    } catch (error) {
      return rejectWithValue(error.response?.data);
    }
  }
);

//Recruiter auth
export const getRecruiterOtpApi = createAsyncThunk(
  "login/getRecruiterOtpApi",
  async (data, { getState, rejectWithValue }) => {
    try {
      return await authService.getRecruiterOtp(data);
    } catch (error) {
      return rejectWithValue(error.response?.data);
    }
  }
);

export const verifyRecruiterOTPApi = createAsyncThunk(
  "login/verifyRecruiterOTPApi",
  async (data, { getState, rejectWithValue }) => {
    try {
      return await authService.verifyRecruiterOTP(data);
    } catch (error) {
      return rejectWithValue(error.response?.data);
    }
  }
);

export const registerRecruiterApi = createAsyncThunk(
  "login/registerRecruiterApi",
  async (data, { getState, rejectWithValue }) => {
    try {
      const response = await authService.registerRecruiter(data);
      return response;
    } catch (error) {
      return rejectWithValue(error.response?.data);
    }
  }
);

// Candidate Bulk Upload
export const bulkUploadRegisterApi = createAsyncThunk(
  "login/bulkUploadRegisterApi",
  async (data, { getState, rejectWithValue }) => {
    try {
      const response = await authService.newSignUps(data);
      return response;
    } catch (error) {
      return rejectWithValue(error.response?.data);
    }
  }
);

export const loginSlice = createSlice({
  name: "login",
  initialState,
  reducers: {
    toggleOtpHandler: (state, action) => {
      state.ui.toggleOtp = !state.ui.toggleOtp;
    },
    logoutCandidateHandler: (state, action) => {
      state.session = null;
      state.accountExists = false;
      state.ui = {
        requestId: "",
        toggleOtp: false,
      };
    },
    removeLoginCredentials: (state, action) => {
      state.ui = {
        requestId: "",
        isEmail: false,
        email: "",
        countryCode: "",
        phone: "",
        loginType: "",
        isVerified: false,
        toggleOtp: false,
      };
    },
    logout: () => initialState, // Reset state to initial state on logout
  },
  extraReducers: (builder) => {
    //Candidate auth -->
    builder.addCase(getOtpApi.fulfilled, (state, action) => {
      const payload = action.meta.arg;
      const requestId = action.payload.data.data.requestId;
      state.ui.loginType = "user";

      state.ui.requestId = requestId;
      if (payload.emailId) {
        state.ui.isEmail = true;
        state.ui.email = payload.emailId;
        state.ui.phone = "";
        state.ui.countryCode = "";
      } else {
        state.ui.isEmail = false;
        state.ui.countryCode = payload.countryCode.toString();
        state.ui.phone = payload.phoneNo.toString();
        state.ui.email = "";
      }
    });

    builder.addCase(verifyOtpApi.fulfilled, (state, action) => {
      const response = action.payload.data.data;
      state.accountExists = response.accountExists;
      state.ui.requestId = response.requestId;
      state.ui.isVerified = true;
      state.ui.loginType = "user";

      if (response.accountExists && response.session.who === "user") {
        state.session = response.session;
        tokenRepo.appToken.add({ token: response.session.token, id: 1 });
      }
    });

    builder.addCase(registerApi.fulfilled, (state, action) => {
      const response = action.payload.data.data;
      state.session = response.session;
      tokenRepo.appToken.add({ token: response.session.token, id: 1 });
    });

    // Company auth -->
    builder.addCase(verifyCompanyDomain.fulfilled, (state, action) => {
      const response = action.payload.data.data;
    });

    builder.addCase(getOtpDomainVerification.fulfilled, (state, action) => {
      const requestId = action.payload.data.data.requestId;
      const payload = action.meta.arg;
      state.ui.requestId = requestId;
      state.ui.email = payload.emailId;
      state.ui.isEmail = true;
      state.ui.loginType = "company";
    });

    builder.addCase(verifyCompanyOtpApi.fulfilled, (state, action) => {
      const response = action.payload.data.data;
      state.accountExists = response.accountExists;
      state.ui.requestId = response.requestId;

      if (
        response.accountExists &&
        (response.session.who === constants.COMPANY.toLowerCase() ||
          response.session.who === constants.COMPANY_MEMBER.toLowerCase())
      ) {
        state.session = response.session;
        tokenRepo.appToken.add({ token: response.session.token, id: 1 });
      }
    });

    builder.addCase(createCompanyApi.fulfilled, (state, action) => {
      const response = action.payload.data.data;
      state.session = response.session;
      tokenRepo.appToken.add({ token: response.session.token, id: 1 });
    });

    builder.addCase(registerCompanyMemberApi.fulfilled, (state, action) => {
      const response = action.payload.data.data;
      state.session = response.session;
      tokenRepo.appToken.add({ token: response.session.token, id: 1 });
    });

    //Recruiter auth
    builder.addCase(getRecruiterOtpApi.fulfilled, (state, action) => {
      const requestId = action.payload.data.data.requestId;
      const payload = action.meta.arg;

      state.ui.requestId = requestId;
      state.ui.loginType = "recruiter";
      if (payload.emailId) {
        state.ui.isEmail = true;
        state.ui.email = payload.emailId;
        state.ui.phone = "";
        state.ui.countryCode = "";
      } else {
        state.ui.isEmail = false;
        state.ui.countryCode = payload.countryCode.toString();
        state.ui.phone = payload.phoneNo.toString();
        state.ui.email = "";
      }
    });

    builder.addCase(verifyRecruiterOTPApi.fulfilled, (state, action) => {
      const response = action.payload.data.data;
      state.accountExists = response.accountExists;
      state.ui.requestId = response.requestId;
      state.ui.isVerified = true;
      state.ui.loginType = "recruiter";

      if (
        response.accountExists &&
        response.session.who === constants.RECRUITER.toLowerCase()
      ) {
        state.session = response.session;
        tokenRepo.appToken.add({ token: response.session.token, id: 1 });
      }
    });

    builder.addCase(registerRecruiterApi.fulfilled, (state, action) => {
      const response = action.payload.data.data;
      state.session = response.session;
      tokenRepo.appToken.add({ token: response.session.token, id: 1 });
    });
  },
});

export const {
  toggleOtpHandler,
  logoutCandidateHandler,
  logout,
  removeLoginCredentials,
} = loginSlice.actions;
export default loginSlice.reducer;
