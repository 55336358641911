import React, { useEffect, useState } from "react";
import PlusCircleIcon from "../../../assets/Icons/PlusCircleIcon";
import FilterIcon from "../../../assets/Icons/FilterIcon";
import Popup from "../../../components/popup/Popup";
import CreateMember from "./CreateMember";
import { v4 as uuid4 } from "uuid";
import MemberCard from "../../../components/company-member-card/MemberCard";
import { useAppDispatch, useAppSelector } from "../../../config/hooks";
import { getMembersApi } from "../../../slices/companyMemberSlice";
import * as constants from "../../../constant";

const CompanyMembersDashboard = () => {
  const dispatch = useAppDispatch();
  const userId = useAppSelector((state) => state?.login?.session?.userId);
  const loginSelector = useAppSelector((state) => state?.login?.session);
  const memberSelector = useAppSelector((state) => state.member);
  const [togglePopup, setTogglePopup] = useState(false);

  const { members } = memberSelector || {};

  useEffect(() => {
    dispatch(
      getMembersApi({
        companyId:
          loginSelector.who === constants.COMPANY.toLowerCase()
            ? userId
            : memberSelector?.memberDetails?.companyId,
        authorId:
          loginSelector.who === constants.COMPANY.toLowerCase() ? "" : userId,
      })
    );
  }, []);

  const togglePopupHandler = () => {
    setTogglePopup(!togglePopup);
  };

  return (
    <div className="">
      <div className="flex items-center justify-end gap-4">
        <button
          className="flex items-center justify-center gap-2 px-4 py-2 bg-expurple-800 text-base text-exwhite-100 font-semibold rounded-md"
          onClick={togglePopupHandler}
        >
          <PlusCircleIcon color="white" /> Create Member
        </button>

        {/* <button>
          <FilterIcon />
        </button> */}
      </div>

      <div className="flex flex-wrap mx-auto">
        {members &&
          members.length > 0 &&
          members.map((member) => (
            <div className="w-[33.3333333%]" key={uuid4()}>
              <MemberCard member={member} />
            </div>
          ))}
      </div>

      <Popup onClose={togglePopupHandler} visible={togglePopup}>
        <div className="w-[70%] bg-expurple-200 p-10 rounded-xl">
          <CreateMember onClose={togglePopupHandler} />
        </div>
      </Popup>
    </div>
  );
};

export default CompanyMembersDashboard;
