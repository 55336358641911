import { createAsyncThunk } from "@reduxjs/toolkit";
import aiService from "../services/ai.service";

export const getCvDetailsApi = createAsyncThunk(
  "user/getCvDetailsApi",
  async (data, { getState, rejectWithValue }) => {
    try {
      const response = await aiService.getCvDetails(data);
      return response;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const getJdDetailsApi = createAsyncThunk(
  "user/getJdDetailsApi",
  async (data, { getState, rejectWithValue }) => {
    try {
      const response = await aiService.getJdDetails(data);
      return response;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);
