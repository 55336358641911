import apiRecruiterClient from "../config/local-axios-config/recruiterApiConfig";
import apiClient from "../config/axiosConfig";

class RecruiterService {
  getRecruiterDetailApi(req) {
    return apiClient.get(`/v1/exo/recruiter/${req.recruiterId}`);
  }
}

const recruiterService = new RecruiterService();
export default recruiterService;
