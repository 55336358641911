import { useAppSelector } from "../../config/hooks";

const useCompanyTypes = () => {
  const companyTypesArray = useAppSelector(
    (state) => state.appConfig.companyTypes
  );

  const defaultCompanyType = (dafaultId) => {
    const selectedIndustry = companyTypesArray.find(
      (commanyType) => commanyType.Id == dafaultId
    );
    return selectedIndustry;
  };
  return { companyTypesArray, defaultCompanyType };
};

export default useCompanyTypes;
