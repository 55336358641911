import React from "react";

const PlusCircleIcon = ({ width = "32", height = "32", color = "#0A65CC" }) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 32 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M16.0005 28.0001C22.6279 28.0001 28.0005 22.6275 28.0005 16.0001C28.0005 9.37271 22.6279 4.00012 16.0005 4.00012C9.37307 4.00012 4.00049 9.37271 4.00049 16.0001C4.00049 22.6275 9.37307 28.0001 16.0005 28.0001Z"
        stroke={color}
        strokeWidth="2"
        strokeMiterlimit="10"
      />
      <path
        d="M10.9995 16.0001H20.9995"
        stroke={color}
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M16.0005 10.9999V20.9999"
        stroke={color}
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default PlusCircleIcon;
