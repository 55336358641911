import React from "react";

const VerticleThreeDotsIcon = ({
  width = "24",
  height = "24",
  color = "#767F8C",
}) => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle
        cx="12"
        cy="18.5"
        r="1.5"
        transform="rotate(-90 12 18.5)"
        fill={color}
      />
      <circle
        cx="12"
        cy="12"
        r="1.5"
        transform="rotate(-90 12 12)"
        fill={color}
      />
      <circle
        cx="12"
        cy="5.5"
        r="1.5"
        transform="rotate(-90 12 5.5)"
        fill={color}
      />
    </svg>
  );
};

export default VerticleThreeDotsIcon;
