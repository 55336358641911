import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import jobService from "../services/job.service";
import { logout } from "./loginSlice";

const initialState = {
  openedJobBeforelogin: {
    jobId: "",
  },
  advanceJobSearch: null,
  jobSearchFields: {
    keyword: "",
    location: "",
  },
  jobCards: null,
  assignedJobsMemberNextUrl: "",
  isLoading: true,
  appliedJobs: null,
  savedJobs: null,
  jobApplicants: {
    applicants: null,
    isLoading: true,
    error: false,
  },
  error: false,
  jobById: {
    isLoading: true,
    error: false,
    jobDetail: null,
  },
};

export const createJobApi = createAsyncThunk(
  "job/createJobApi",
  async (data, { getState, rejectWithValue }) => {
    try {
      const response = await jobService.createJob(data);
      return response;
    } catch (error) {
      return rejectWithValue(error.response?.data);
    }
  }
);

export const updateJobApi = createAsyncThunk(
  "job/updateJobApi",
  async (data, { getState, rejectWithValue }) => {
    try {
      const response = await jobService.updateJob(data);
      return response;
    } catch (error) {
      return rejectWithValue(error.response?.data);
    }
  }
);

export const getRecommendedJobApi = createAsyncThunk(
  "job/getRecommendedJobApi",
  async (_data, { getState, rejectWithValue, dispatch }) => {
    try {
      const response = await jobService.getRecommendedJobs(_data);
      return response;
    } catch (error) {
      return rejectWithValue(error.response?.data);
    }
  }
);

export const getJobDetailApi = createAsyncThunk(
  "jobs/getJobDetailApi",
  async (_data, { getState, rejectWithValue }) => {
    try {
      const response = await jobService.getJobDetail(_data);
      return response;
    } catch (error) {
      return rejectWithValue(error.response?.data);
    }
  }
);

export const getJobByIdPopupApi = createAsyncThunk(
  "jobs/getJobByIdPopupApi",
  async (_data, { getState, rejectWithValue }) => {
    try {
      const response = await jobService.getJobDetail(_data);
      return response;
    } catch (error) {
      return rejectWithValue(error.response?.data);
    }
  }
);

export const applyJobApi = createAsyncThunk(
  "jobs/applyToJobApi",
  async (_data, { getState, rejectWithValue }) => {
    try {
      const response = await jobService.applyToJob(_data);
      return response;
    } catch (error) {
      return rejectWithValue(error.response?.data);
    }
  }
);
export const withdrawJobApi = createAsyncThunk(
  "jobs/withdrawJobApi",
  async (_data, { getState, rejectWithValue }) => {
    try {
      const response = await jobService.withdrawToJob(_data);
      return response;
    } catch (error) {
      return rejectWithValue(error.response?.data);
    }
  }
);

export const getAppliedJobApi = createAsyncThunk(
  "job/getAppliedJobApi",
  async (_data, { getState, rejectWithValue }) => {
    try {
      const response = await jobService.getAppliedJob();
      return response;
    } catch (error) {
      return rejectWithValue(error.response?.data);
    }
  }
);

export const updateJobStatusApi = createAsyncThunk(
  "job/updateJobStatusApi",
  async (data, { getState, rejectWithValue }) => {
    try {
      const response = await jobService.updateJobStatus(data);
      return response;
    } catch (error) {
      return rejectWithValue(error.response?.data);
    }
  }
);

export const updateApplicantStatusApi = createAsyncThunk(
  "job/updateApplicantStatusApi",
  async (data, { getState, rejectWithValue }) => {
    try {
      const response = await jobService.updateApplication(data);
      return response;
    } catch (error) {
      return rejectWithValue(error.response?.data);
    }
  }
);

export const getNextJobApi = createAsyncThunk(
  "job/getNextFeedApis",
  async (a, { getState, rejectWithValue, dispatch }) => {
    try {
      const state = getState();
      const response = await jobService.getJobByUrl(
        state.jobs.jobCards.metadata.nextResultURL
      );
      return response;
    } catch (error) {
      return rejectWithValue(error.response?.data);
    }
  }
);

export const getSavedJobApi = createAsyncThunk(
  "jobs/getSavedJobApi",
  async (_data, { getState, rejectWithValue }) => {
    try {
      const response = await jobService.getSavedJob();
      return response;
    } catch (error) {
      return rejectWithValue(error.response?.data);
    }
  }
);

export const getNextSavedJobApi = createAsyncThunk(
  "jobs/getNextSavedJobApi",
  async (_data, { getState, rejectWithValue }) => {
    try {
      const state = getState();
      const response = await jobService.getJobByUrl(
        state.jobs.savedJobs.metadata.nextResultURL
      );
      return response;
    } catch (error) {
      return rejectWithValue(error.response?.data);
    }
  }
);

export const saveJobApi = createAsyncThunk(
  "jobs/saveJobApi",
  async (_data, { getState, rejectWithValue }) => {
    try {
      const response = await jobService.saveJob(_data);
      return response;
    } catch (error) {
      return rejectWithValue(error.response?.data);
    }
  }
);

export const unSaveJobApi = createAsyncThunk(
  "jobs/unSaveJobApi",
  async (_data, { getState, rejectWithValue }) => {
    try {
      const response = await jobService.unSaveJob(_data);
      return response;
    } catch (error) {
      return rejectWithValue(error.response?.data);
    }
  }
);

export const getApplicantsApi = createAsyncThunk(
  "jobs/getApplicantsApi",
  async (_data, { getState, rejectWithValue }) => {
    try {
      const response = await jobService.getApplicants(_data);
      return response;
    } catch (error) {
      return rejectWithValue(error.response?.data);
    }
  }
);

export const getNextApplicantsApi = createAsyncThunk(
  "jobs/getNextApplicantsApi",
  async (_data, { getState, rejectWithValue }) => {
    try {
      const state = getState();
      const response = await jobService.getJobByUrl(
        state?.jobs?.jobApplicants?.applicants?.metadata?.nextResultURL
      );
      return response;
    } catch (error) {
      return rejectWithValue(error.response?.data);
    }
  }
);

export const getNextApplicantsCMApi = createAsyncThunk(
  "jobs/getNextApplicantsCMApi",
  async (_data, { getState, rejectWithValue }) => {
    try {
      const state = getState();
      const response = await jobService.getJobApplicantsForCMByUrl(_data);
      return response;
    } catch (error) {
      return rejectWithValue(error.response?.data);
    }
  }
);

export const asignJobApi = createAsyncThunk(
  "jobs/asignJobApi",
  async (_data, { getState, rejectWithValue }) => {
    try {
      const response = await jobService.asignJob(_data);
      return response;
    } catch (error) {
      return rejectWithValue(error.response?.data);
    }
  }
);

export const getAssignJobsToMemberApi = createAsyncThunk(
  "jobs/getAssignJobsToMemberApi",
  async (_data, { getState, rejectWithValue }) => {
    try {
      const response = await jobService.getAssignJobsToMember(_data);
      return response;
    } catch (error) {
      return rejectWithValue(error.response?.data);
    }
  }
);

export const getNextAssignJobsToMemberApi = createAsyncThunk(
  "jobs/getNextAssignJobsToMemberApi",
  async (_data, { getState, rejectWithValue }) => {
    try {
      const state = getState();
      const response = await jobService.getNextAssignedJobToMemberByUrl({
        ..._data,
        nextUrl: state.jobs.assignedJobsMemberNextUrl,
      });
      return response;
    } catch (error) {
      return rejectWithValue(error.response?.data);
    }
  }
);

export const getNextJobsToMemberAdminApi = createAsyncThunk(
  "jobs/getNextJobsToMemberAdminApi",
  async (_data, { getState, rejectWithValue }) => {
    try {
      const state = getState();
      const response = await jobService.getJobByUrl(
        state.jobs.jobCards?.metadata?.nextResultURL
      );
      return response;
    } catch (error) {
      return rejectWithValue(error.response?.data);
    }
  }
);

export const getNextAppliedJobsApi = createAsyncThunk(
  "jobs/getNextAppliedJobsApi",
  async (_data, { getState, rejectWithValue }) => {
    try {
      const state = getState();
      const response = await jobService.getJobByUrl(
        state?.jobs?.appliedJobs?.metadata?.nextResultURL
      );
      return response;
    } catch (error) {
      return rejectWithValue(error.response?.data);
    }
  }
);

export const getAssignMembersToJobApi = createAsyncThunk(
  "jobs/getAssignMembersToJobApi",
  async (_data, { getState, rejectWithValue }) => {
    try {
      const response = await jobService.getAssignMembersToJob(_data);
      return response;
    } catch (error) {
      return rejectWithValue(error.response?.data);
    }
  }
);

export const jobSlice = createSlice({
  name: "jobs",
  initialState,
  reducers: {
    jobOpenedbeforeLoginHandler: (state, action) => {
      state.openedJobBeforelogin.jobId = action.payload.jobId;
    },
    jobSearchFieldsReducer: (state, action) => {
      state.jobSearchFields.keyword = action.payload.keyword;
      state.jobSearchFields.location = action.payload.location;
    },
    advanceJobSearchReducer: (state, action) => {
      state.advanceJobSearch = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getRecommendedJobApi.pending, (state, action) => {
      state.isLoading = true;
    });

    builder.addCase(getRecommendedJobApi.fulfilled, (state, action) => {
      const response = action.payload.data.data;
      state.jobCards = response;
      state.isLoading = false;
    });

    builder.addCase(getRecommendedJobApi.rejected, (state, action) => {
      state.isLoading = false;
      state.error = true;
    });

    builder.addCase(getAssignJobsToMemberApi.fulfilled, (state, action) => {
      const response = action.payload.data.data;
      state.jobCards = response;
      state.assignedJobsMemberNextUrl = action.payload.data.nextPageUrl;
    });

    builder.addCase(getNextJobsToMemberAdminApi.fulfilled, (state, action) => {
      const response = action.payload.data.data;
      if (response?.jobs && response.jobs.length > 0) {
        state.jobCards.jobs = state.jobCards.jobs.concat(response.jobs);
      }
      state.jobCards.metadata = response.metadata;
    });

    builder.addCase(getNextAssignJobsToMemberApi.fulfilled, (state, action) => {
      const response = action.payload.data.data;
      if (response?.jobs && response?.jobs.length > 0) {
        state.jobCards.jobs = state.jobCards.jobs.concat(response.jobs);
      }
      state.assignedJobsMemberNextUrl = action.payload.data.nextPageUrl;
    });

    builder.addCase(getNextApplicantsApi.fulfilled, (state, action) => {
      const response = action.payload.data.data;
      if (response?.applications && response.applications.length > 0) {
        state.jobApplicants.applicants.applications =
          state?.jobApplicants?.applicants?.applications.concat(
            response.applications
          );
      }
      state.jobApplicants.applicants.metadata = response.metadata;
    });

    builder.addCase(getJobDetailApi.pending, (state, action) => {
      state.jobById.isLoading = true;
    });

    builder.addCase(getJobDetailApi.fulfilled, (state, action) => {
      const response = action.payload.data.data;
      state.jobById.isLoading = false;
      state.jobById.jobDetail = response[0];
    });

    builder.addCase(getJobDetailApi.rejected, (state, action) => {
      state.jobById.isLoading = false;
      state.jobById.error = true;
    });

    builder.addCase(applyJobApi.fulfilled, (state, action) => {
      const response = action.payload.data.data;
    });

    builder.addCase(withdrawJobApi.fulfilled, (state, action) => {
      const jobId = action.meta.arg.jobId;
      const filterData = state.appliedJobs.jobs.filter(
        (appliedJob) => appliedJob?.job.id !== jobId
      );
      state.appliedJobs.jobs = filterData;
    });

    builder.addCase(getAppliedJobApi.fulfilled, (state, action) => {
      const response = action.payload.data.data;
      state.appliedJobs = response;
    });

    builder.addCase(getNextAppliedJobsApi.fulfilled, (state, action) => {
      const response = action.payload.data.data;
      if (response?.jobs && response?.jobs.length > 0) {
        state.appliedJobs.jobs = state.appliedJobs.jobs.concat(response.jobs);
      }
      state.appliedJobs.metadata = response.metadata;
    });

    builder.addCase(createJobApi.fulfilled, (state, action) => {
      const response = action.payload.data.data;
    });

    builder.addCase(updateJobStatusApi.fulfilled, (state, action) => {
      const response = action.payload.data.data;
    });

    builder.addCase(getNextJobApi.fulfilled, (state, action) => {
      const response = action.payload.data.data;
      if (response?.jobs && response?.jobs?.length > 0) {
        state.jobCards.jobs = state.jobCards.jobs.concat(response.jobs);
      }
      state.jobCards.metadata = response.metadata;
    });

    builder.addCase(getSavedJobApi.fulfilled, (state, action) => {
      const response = action.payload.data.data;
      state.savedJobs = response;
    });

    builder.addCase(getNextSavedJobApi.fulfilled, (state, action) => {
      const response = action.payload.data.data;
      if (response?.jobs && response?.jobs?.length > 0) {
        state.savedJobs.jobs = state.savedJobs.jobs.concat(response.jobs);
      }
      state.savedJobs.metadata = response.metadata;
    });

    builder.addCase(unSaveJobApi.fulfilled, (state, action) => {
      const jobId = action.meta.arg.jobId;
      const response = action.payload.data.data;
      if (response) {
        state.savedJobs.jobs = state.savedJobs.jobs.filter(
          (job) => job.id !== jobId
        );
      }
      // state.savedJobs = response;
    });

    builder.addCase(getApplicantsApi.pending, (state, action) => {
      state.jobApplicants.isLoading = true;
      state.jobApplicants.error = false;
    });

    builder.addCase(getApplicantsApi.fulfilled, (state, action) => {
      const response = action.payload.data.data;
      state.jobApplicants.applicants = response;
      state.jobApplicants.isLoading = false;
      state.jobApplicants.error = false;
    });

    builder.addCase(getApplicantsApi.rejected, (state, action) => {
      state.jobApplicants.isLoading = false;
      state.jobApplicants.error = true;
    });

    builder.addCase(updateApplicantStatusApi.fulfilled, (state, action) => {
      const response = action.payload.data.data;
      const updatedArray = state.jobApplicants.applicants.applications.map(
        (applicant) => {
          if (applicant.id === response.id) {
            return {
              ...applicant,
              status: response.status,
            };
          }
          return applicant;
        }
      );
      state.jobApplicants.applicants.applications = updatedArray;
    });

    builder.addCase(logout, (state) => {
      // Reset this slice's state to initial state
      return initialState;
    });
  },
});

export const {
  jobOpenedbeforeLoginHandler,
  jobSearchFieldsReducer,
  advanceJobSearchReducer,
} = jobSlice.actions;

export default jobSlice.reducer;
