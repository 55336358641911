import React, { useEffect, useRef, useState } from "react";
import VerticleThreeDotsIcon from "../../assets/Icons/VerticleThreeDotsIcon";
import StandardEnvolpIcon from "../../assets/Icons/StandardEnvolpIcon";
import StandardPhoneIcon from "../../assets/Icons/StandardPhoneIcon";
import { useAppDispatch } from "../../config/hooks";
import { removeMemberApi } from "../../slices/companyMemberSlice";
import { unwrapResult } from "@reduxjs/toolkit";
import { toast } from "sonner";

const MemberCard = ({ member }) => {
  const menuRef = useRef(null);
  const menuParentRef = useRef(null);

  const dispatch = useAppDispatch();

  const [toggleModal, setToggleModal] = useState(false);

  useEffect(() => {
    const toggleHandler = (e) => {
      if (
        menuRef.current &&
        menuParentRef &&
        !menuRef.current.contains(e.target) &&
        !menuParentRef.current.contains(e.target)
      ) {
        // close popup
        setToggleModal(false);
      }
    };
    document.addEventListener("mousedown", toggleHandler);

    return () => {
      document.removeEventListener("mousedown", toggleHandler);
    };
  });

  const toggleModalhandler = () => {
    setToggleModal(!toggleModal);
  };

  const removeMemberHandler = async () => {
    try {
      await dispatch(removeMemberApi({ memberId: member.id })).then(
        unwrapResult
      );
      toggleModalhandler();
      toast.success("Member removed");
    } catch (error) {
      toast.error("Something went wrong");
    }
  };

  return (
    <div className="p-2">
      <div className="bg-gradient-to-b from-[#FAFBFF] to-expurple-100 h-full rounded-lg p-5 space-y-2 border border-exgray-100/70">
        <div className="flex items-center justify-start gap-3">
          <div className="h-20 w-20 rounded-md bg-DefaultProfileImg bg-cover bg-center bg-no-repeat border border-exgray-100"></div>
          <div className="flex-1 space-y-1">
            <h1 className="text-lg text-exgray-800 font-semibold line-clamp-1">
              {member.firstName && member.firstName
                ? `${member.firstName && member.firstName} ${
                    member.lastName && member.lastName
                  }`
                : "UnKnown"}
            </h1>
            <p className="text-base text-exgray-800 font-semibold line-clamp-1">
              {member.designation}
            </p>
          </div>
          <div className="self-start relative">
            <div
              className="cursor-pointer"
              onClick={toggleModalhandler}
              ref={menuParentRef}
            >
              <VerticleThreeDotsIcon />
            </div>

            {toggleModal && (
              <div
                className="absolute right-2 top-7 bg-gradient-to-b from-[#FAFBFF] to-expurple-100  rounded-md w-fit overflow-hidden"
                style={{
                  boxShadow: "0px 0px 10px 1px rgba(0, 0, 0, 0.4)",
                }}
                ref={menuRef}
              >
                <button
                  className="w-full px-8 py-2 text-center text-exgray-800 hover:bg-expurple-700/30 text-base font-semibold"
                  onClick={removeMemberHandler}
                >
                  Remove
                </button>
              </div>
            )}
          </div>
        </div>

        <div>
          <p className="text-base text-exgray-300 flex items-center justify-start gap-1">
            <StandardEnvolpIcon />
            {member.emailId ? member.emailId : "Unknown"}
          </p>
          <div className="flex items-center justify-between gap-1">
            <p className="text-base text-exgray-300 flex items-center justify-start gap-1">
              <StandardPhoneIcon />
              {member.mobileNo ? member.mobileNo : "Unknown"}
            </p>
            {!member.isEmailVerified && (
              <p className="text-base text-exgray-300">Action pending...</p>
            )}
          </div>
        </div>

        {member?.authorName && (
          <div className="w-fit ml-auto mr-0 pt-4">
            <div className="px-4 border border-expurple-800 text-base text-expurple-800 font-semibold text-center w-fit rounded-full">
              {member?.authorName}
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default MemberCard;
