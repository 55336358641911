import React, { useEffect, useState } from "react";
import CandidateCard from "../../../components/candidate-card/CandidateCard";
import { v4 as uuid4 } from "uuid";
import { useAppDispatch, useAppSelector } from "../../../config/hooks";
import {
  getApplicantsApi,
  getNextApplicantsApi,
  getNextApplicantsCMApi,
} from "../../../slices/jobSlice";
import { unwrapResult } from "@reduxjs/toolkit";
import { getAllReferralsApi } from "../../../slices/referSlice";
import * as constants from "../../../constant";
import InfiniteScroll from "react-infinite-scroll-component";
import { toast } from "sonner";

const JobApplicants = ({ jobId }) => {
  const dispatch = useAppDispatch();
  const jobSelector = useAppSelector((state) => state.jobs.jobApplicants);
  const loginSelector = useAppSelector((state) => state?.login?.session);
  const memberSelector = useAppSelector((state) => state.member.memberDetails);

  const [appliedCadidateViaRefer, setAppliedCadidateViaRefer] = useState(null);

  useEffect(() => {
    const fetchData = async () => {
      if (
        loginSelector?.who === constants.COMPANY.toLowerCase() ||
        (loginSelector?.who === constants.COMPANY_MEMBER.toLowerCase() &&
          memberSelector?.designation === constants.ADMIN)
      ) {
        try {
          await dispatch(getApplicantsApi({ jobId })).then(unwrapResult);
        } catch (error) {}
      } else {
        try {
          const resposeRefer = await dispatch(
            getAllReferralsApi({
              jobId: jobId,
              // status: "applied",
              recruiterId: loginSelector?.userId,
            })
          ).then(unwrapResult);
          setAppliedCadidateViaRefer(resposeRefer.data.data);
        } catch (error) {}
      }
    };

    fetchData();
  }, []);

  const getMoreDataHandler = () => {
    try {
      dispatch(getNextApplicantsApi());
    } catch (error) {
      toast.error("Unable to fetch data");
    }
  };

  const getMoreHandler = async () => {
    try {
      const response = await dispatch(
        getNextApplicantsCMApi({
          nextUrl: appliedCadidateViaRefer.metadata.nextResultURL,
          jobId: jobId,
          recruiterId: loginSelector?.userId,
        })
      ).then(unwrapResult);

      setAppliedCadidateViaRefer({
        referrals: [
          ...appliedCadidateViaRefer.referrals,
          ...response.data.data.referrals,
        ],
        metadata: response.data.data.metadata,
      });
    } catch (error) {
      toast.error("Unable to fetch data");
    }
  };

  if (jobSelector.error) {
    return <h1>Somthing went wrong</h1>;
  }

  if (
    loginSelector?.who === constants.COMPANY.toLowerCase() ||
    (loginSelector?.who === constants.COMPANY_MEMBER.toLowerCase() &&
      memberSelector?.designation === constants.ADMIN &&
      !jobSelector.isLoading)
  ) {
    return (
      !jobSelector.isLoading && (
        <InfiniteScroll
          className="no-scrollbar"
          dataLength={
            jobSelector?.applicants?.applications?.length
              ? jobSelector?.applicants?.applications?.length
              : 0
          }
          next={() => getMoreDataHandler()}
          hasMore={jobSelector?.applicants?.metadata?.nextResultURL}
          // hasMore={true}
          // height={"calc(100vh - 155px)"}
          style={{ overflow: "visible" }}
          loader={""}
        >
          <div className="space-y-2">
            {jobSelector?.applicants?.applications?.map((candidate) => (
              <CandidateCard
                candidate={candidate}
                candidateDetails={candidate.author}
                key={uuid4()}
              />
            ))}
          </div>
        </InfiniteScroll>
      )
    );
  } else {
    return (
      appliedCadidateViaRefer && (
        <InfiniteScroll
          className="no-scrollbar"
          dataLength={
            appliedCadidateViaRefer?.referrals?.length
              ? appliedCadidateViaRefer?.referrals?.length
              : 0
          }
          next={() => getMoreHandler()}
          hasMore={appliedCadidateViaRefer?.metadata?.nextResultURL}
          // hasMore={true}
          // height={"calc(100vh - 155px)"}
          style={{ overflow: "visible" }}
          loader={""}
        >
          <div className="space-y-2">
            {appliedCadidateViaRefer?.referrals?.map((candidate) => {
              if (candidate.status !== "pending") {
                return (
                  <CandidateCard
                    candidate={candidate}
                    setAppliedCadidateViaRefer={setAppliedCadidateViaRefer}
                    appliedCadidateViaRefer={appliedCadidateViaRefer}
                    candidateDetails={candidate.userDetails}
                    key={uuid4()}
                  />
                );
              }
            })}
          </div>
        </InfiniteScroll>
      )
    );
  }
};

export default JobApplicants;
