import React from "react";

const IndustryIcon = ({ width = "25", height = "26", color = "#223870" }) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 25 26"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M19.3033 14.2622C19.2184 11.2001 17.1868 8.61205 14.2771 7.85785C14.2591 7.42015 13.9021 7.06885 13.4629 7.06885H11.4619C11.011 7.06885 10.6441 7.43785 10.6441 7.89145V7.89505C7.73741 8.64145 5.70701 11.2169 5.62151 14.2622C5.22401 14.441 4.94531 14.8388 4.94531 15.302V16.2554C4.94531 16.8848 5.45741 17.3969 6.08681 17.3969H18.838C19.4674 17.3969 19.9795 16.8848 19.9795 16.2554V15.302C19.9795 14.8388 19.7011 14.4407 19.3033 14.2622ZM18.6991 14.1605H14.2807V8.48005C16.8109 9.20665 18.5758 11.4731 18.6991 14.1605ZM11.4619 7.66885H13.4629C13.5829 7.66885 13.6807 7.76875 13.6807 7.89145V14.1605H11.2441V7.89145C11.2441 7.76875 11.3419 7.66885 11.4619 7.66885ZM10.6441 8.51635V14.1605H6.22571C6.34991 11.4881 8.11541 9.23455 10.6441 8.51635ZM19.3795 16.2554C19.3795 16.5539 19.1365 16.7969 18.838 16.7969H6.08681C5.78831 16.7969 5.54531 16.5539 5.54531 16.2554V15.302C5.54531 15.0035 5.78831 14.7605 6.08681 14.7605H18.838C19.1365 14.7605 19.3795 15.0035 19.3795 15.302V16.2554Z"
        fill={color}
        stroke="#223870"
        strokeWidth="0.5"
      />
      <path
        d="M12.5 1C9.8966 1 7.4861 1.8361 5.5175 3.25H2.8721C2.7065 3.25 2.5721 3.3841 2.5721 3.55C2.5721 3.7159 2.7065 3.85 2.8721 3.85H4.7486C2.1521 6.0529 0.5 9.3364 0.5 13C0.5 19.6168 5.8832 25 12.5 25C15.1034 25 17.5139 24.1639 19.4825 22.75H22.1279C22.2935 22.75 22.4279 22.6159 22.4279 22.45C22.4279 22.2841 22.2935 22.15 22.1279 22.15H20.2514C22.8479 19.9471 24.5 16.6636 24.5 13C24.5 6.3832 19.1168 1 12.5 1ZM19.7375 21.8011V19.7596C19.7375 19.5937 19.6031 19.4596 19.4375 19.4596C19.2719 19.4596 19.1375 19.5937 19.1375 19.7596V22.2595C17.2664 23.6047 14.975 24.4 12.5 24.4C6.2141 24.4 1.1 19.2859 1.1 13C1.1 9.4597 2.7224 6.2914 5.2625 4.1989V6.2404C5.2625 6.4063 5.3969 6.5404 5.5625 6.5404C5.7281 6.5404 5.8625 6.4063 5.8625 6.2404V3.7405C7.7336 2.3953 10.025 1.6 12.5 1.6C18.7859 1.6 23.9 6.7141 23.9 13C23.9 16.5403 22.2776 19.7086 19.7375 21.8011Z"
        fill={color}
        stroke="#223870"
        strokeWidth="0.6"
      />
    </svg>
  );
};

export default IndustryIcon;
