import React from "react";

const GlobeIcon = ({ width = "32", height = "32" }) => {
  return (
    <svg
      width="32"
      height="32"
      viewBox="0 0 32 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M16 28C22.6274 28 28 22.6274 28 16C28 9.37258 22.6274 4 16 4C9.37258 4 4 9.37258 4 16C4 22.6274 9.37258 28 16 28Z"
        fill="#E7F0FA"
        stroke="#0A65CC"
        strokeWidth="2"
        strokeMiterlimit="10"
      />
      <path
        d="M4 16H28"
        stroke="#0A65CC"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M16 27.6779C18.7614 27.6779 21 22.4495 21 16C21 9.5505 18.7614 4.32214 16 4.32214C13.2386 4.32214 11 9.5505 11 16C11 22.4495 13.2386 27.6779 16 27.6779Z"
        stroke="#0A65CC"
        strokeWidth="2"
        strokeMiterlimit="10"
      />
    </svg>
  );
};

export default GlobeIcon;
