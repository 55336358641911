import React, { useMemo, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import ExInput from "../../../components/basic-components/ExInput";
import ExDropdown from "../../../components/basic-components/ExDropdown";
import useCountryCodes from "../../../utils/dropdown-options/useCountryCodes";
import useLocations from "../../../utils/dropdown-options/useLocations";
import { useAppDispatch, useAppSelector } from "../../../config/hooks";
import { createReferApi } from "../../../slices/loginSlice";
import { unwrapResult } from "@reduxjs/toolkit";
import {
  referCandidateApi,
  searchCandidatesApi,
} from "../../../slices/referSlice";
import * as constants from "../../../constant";
import ErrorIcon from "../../../assets/Icons/ErrorIcon";
import { toast } from "sonner";
import LocationIcon from "../../../assets/Icons/LocationIcon";
import useIndustries from "../../../utils/dropdown-options/useIndustries";
import IndustryIcon from "../../../assets/Icons/IndustryIcon";
import { useNavigate } from "react-router-dom";

const CreateReferForm = ({ jobId, onClose = () => {} }) => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const userId = useAppSelector((state) => state?.login?.session?.userId);
  const userType = useAppSelector((state) => state?.login?.session?.who);
  const token = useAppSelector((state) => state?.login?.session?.token);
  const memberSelector = useAppSelector(
    (state) => state?.member?.memberDetails
  );

  const [userDetails, setUserDetails] = useState(null);
  const [conflictError, setConflictError] = useState("");

  const { modifiedCountryCodes } = useCountryCodes();

  const { locationsArray, defaultLocation } = useLocations();
  const { defaultIndustry } = useIndustries();

  const candidateLocation = useMemo(() => {
    return userDetails?.location
      ? isNaN(userDetails?.location)
        ? userDetails?.location
        : defaultLocation(userDetails?.location)
      : "";
  }, [defaultLocation, userDetails?.location]);

  const preferdIndustry = useMemo(() => {
    if (userDetails?.industry) {
      return userDetails?.industry[0]
        ? defaultIndustry(userDetails?.industry[0])
        : null;
    }
    return null;
  }, [defaultIndustry, userDetails?.industry]);

  const form = useForm({
    defaultValues: {
      firstName: "",
      lastName: "",
      emailId: "",
      location: "",
      phoneNo: "",
      isFresher: false,
      companyName: "",
      designantion: "",
      experienceYears: null,
      experienceMonths: null,
      ctcInLacs: null,
      ctcInThousand: null,
      countryCode: {
        Id: 99,
        CountryName: "India",
        Iso3: "IND",
        PhoneCode: "91",
        PhoneCodeLabel: "IND +91",
      },
      uploadCV: "",
      tAndc: false,
      isMobileVerified: false,
      isEmailVerified: false,
    },
  });

  const { handleSubmit, control, formState, watch, reset, setValue } = form;
  const { errors } = formState;

  const onSubmit = async (formData) => {
    if (
      userDetails &&
      (formData.isMobileVerified || formData.isEmailVerified)
    ) {
      try {
        await dispatch(
          referCandidateApi({
            recruiterID: userId,
            candidateID: userDetails.id,
            jobID: jobId,
            status: "pending",
            referralType: userType,
          })
        ).then(unwrapResult);
        toast.success("Reffered successfully");
        onClose();
      } catch (error) {
        if (error.status === 409) {
          // setConflictError("Candidate alredy reffered");
          toast.error(error?.message);
        } else {
          toast.error("Something went wrong");
        }
      }
    } else {
      try {
        const response = await dispatch(
          createReferApi({
            firstName: formData.firstName,
            lastName: formData.lastName,
            emailId: formData.emailId,
            countryCode: parseInt(formData.countryCode.PhoneCode),
            phoneNo: parseInt(formData.phoneNo),
            yearsOfExperience: formData.experienceYears,
            designation: formData.designantion,
            authorType:
              userType === constants.COMPANY.toLowerCase() ||
              userType === constants.COMPANY_MEMBER.toLowerCase()
                ? "COMPANY"
                : "RECRUITER",
            authorId:
              userType === constants.COMPANY.toLowerCase()
                ? userId
                : userType === constants.COMPANY_MEMBER.toLowerCase()
                ? memberSelector.companyId
                : userType === constants.RECRUITER.toLowerCase()
                ? userId
                : "",
            location: formData.location.Id.toString(),
            socialInfo: {
              linkedin: {
                profileUrl: "https://linkedin.com/in/johndoe",
              },
            },
            organization: formData.companyName,
          })
        ).then(unwrapResult);

        try {
          await dispatch(
            referCandidateApi({
              recruiterID: userId,
              candidateID: response.data.data.userId,
              jobID: jobId,
              status: "pending",
              referralType: userType,
            })
          ).then(unwrapResult);
          toast.success("Reffered successfully");
        } catch (error) {
          toast.error("Somthing went wrong");
        }
        onClose();
      } catch (error) {
        toast.error("Somthing went wrong");
      }
    }
  };

  const { emailId, phoneNo } = watch();

  const searchCandidatehandler = async (isEmail) => {
    try {
      const response = await dispatch(
        searchCandidatesApi({
          mobile: !isEmail ? parseInt(phoneNo) : null,
          email: isEmail ? emailId : "",
          authorId:
            userType === constants.COMPANY.toLowerCase()
              ? userId
              : userType === constants.COMPANY_MEMBER.toLowerCase()
              ? memberSelector.companyId
              : userId,
        })
      ).then(unwrapResult);
      setUserDetails(response.data.data);
      const data = response.data.data;

      setValue("firstName", data.firstName);
      setValue("lastName", data.lastName);
      setValue("experienceYears", data.totalExperience);
      setValue("designantion", data.designation);
      setValue("companyName", data.organization);
      setValue("emailId", data.email);
      setValue("phoneNo", data.mobile.toString());
      setValue("location", defaultLocation(data.location));
      setValue("isEmailVerified", data.isEmailVerified);
      setValue("isMobileVerified", data.isMobileVerified);
    } catch (error) {
      if (error.error.code === 404) toast.error("User not found");
      else toast.error("Something went wrong");
    }
  };

  const clearFieldsHandler = () => {
    reset();
    setUserDetails(null);
    setConflictError("");
  };

  const navigateHandler = () => {
    if (userDetails.id) navigate(`/job-seekers/${userDetails.id}`);
  };

  return (
    <div className="space-y-4">
      <div>
        <h1 className="text-xl text-expurple-800 font-semibold text-start">
          Candidate Details{" "}
          <span className="text-sm text-exgray-200 tracking-wide">
            Search / Create Candidate
          </span>
        </h1>
      </div>

      <form onSubmit={handleSubmit(onSubmit)} className="space-y-4">
        <div className="grid grid-cols-2 auto-rows-auto gap-2">
          <div className="flex items-center justify-between gap-2">
            <div className="min-w-[120px] self-start">
              <Controller
                name="countryCode"
                control={control}
                rules={{
                  required: {
                    value: true,
                    message: "required",
                  },
                }}
                render={({ field: { onChange, value } }) => (
                  <ExDropdown
                    title="Country"
                    onChange={onChange}
                    placeholder="Select"
                    value={value || null}
                    optionValue="PhoneCode"
                    optionLabel="PhoneCodeLabel"
                    options={modifiedCountryCodes}
                    dropDownHeight="40px"
                    dropDownBorderWidth="0px"
                    dropDownBorderColor="#696969"
                    error={errors?.countryCode?.message}
                  />
                )}
              />
            </div>
            <div className="flex-1 self-start">
              <Controller
                name="phoneNo"
                control={control}
                rules={{
                  required: {
                    value: true,
                    message: "Mobile no is required",
                  },
                  pattern: {
                    value: /^[0-9]+$/,
                    message: "Please enter a valid mobile number",
                  },
                }}
                render={({ field: { onChange, value } }) => (
                  <div>
                    <label
                      htmlFor={"phoneNo"}
                      className={`text-lg font-normal text-expurple-800`}
                    >
                      Mobile No. *
                    </label>
                    <div className={`relative`}>
                      <input
                        type="number"
                        className="w-full h-10 px-3 bg-expurple-100 rounded-sm outline-none text-expurple-700 placeholder:text-expurple-700"
                        id="phoneNo"
                        onWheel={(e) => e.target.blur()}
                        readOnly={userDetails}
                        placeholder="Enter here"
                        autoComplete="off"
                        name="phoneNo"
                        onChange={onChange}
                        value={value}
                      />
                      <div className="absolute h-full top-0 flex items-center justify-center right-2 spin-button-none">
                        <button
                          type="button"
                          disabled={!phoneNo}
                          onClick={() => searchCandidatehandler(false)}
                          className="py-1 w-fit px-4 bg-expurple-800 text-exwhite-100 text-xs rounded-sm"
                        >
                          Search
                        </button>
                      </div>
                    </div>
                    {errors?.phoneNo?.message && (
                      <p className="text-red-400">{errors?.phoneNo?.message}</p>
                    )}
                  </div>
                )}
              />
            </div>
          </div>

          <div>
            <Controller
              name="emailId"
              control={control}
              rules={{
                required: {
                  value: true,
                  message: "Email ID is required",
                },
                pattern: {
                  value:
                    /^([a-zA-Z0-9_.+-])+@(([a-zA-Z0-9-])+\.)+([a-zA-Z0-9]{2,4})+$/,
                  message: "Please enter a valid email ID",
                },
              }}
              render={({ field: { onChange, value } }) => (
                <div>
                  <label
                    htmlFor={"emailId"}
                    className={`text-lg font-normal text-expurple-800`}
                  >
                    Email ID *
                  </label>
                  <div className={`relative`}>
                    <input
                      type="text"
                      className="w-full h-10 px-3 bg-expurple-100 rounded-sm outline-none text-expurple-700 placeholder:text-expurple-700"
                      id="emailId"
                      readOnly={userDetails}
                      placeholder="eg. abc@abc.com"
                      autoComplete="off"
                      name="emailId"
                      onChange={onChange}
                      value={value}
                    />
                    <div className="absolute h-full top-0 flex items-center justify-center right-2">
                      <button
                        type="button"
                        className="py-1 w-fit px-4 bg-expurple-800 text-exwhite-100 text-xs rounded-sm"
                        disabled={!emailId}
                        onClick={() => searchCandidatehandler(true)}
                      >
                        Search
                      </button>
                    </div>
                  </div>
                  {errors?.emailId?.message && (
                    <p className="text-red-400">{errors?.emailId?.message}</p>
                  )}
                </div>
              )}
            />
          </div>

          <div>
            <Controller
              name="firstName"
              control={control}
              rules={{
                required: {
                  value: true,
                  message: "First Name is required",
                },
              }}
              render={({ field: { onChange, value } }) => (
                <ExInput
                  title="First Name"
                  placeholder="First Name"
                  name="firstName"
                  onChange={onChange}
                  value={value}
                  error={errors?.firstName?.message}
                />
              )}
            />
          </div>

          <div>
            <Controller
              name="lastName"
              control={control}
              rules={{
                required: {
                  value: true,
                  message: "Last Name is required",
                },
              }}
              render={({ field: { onChange, value } }) => (
                <ExInput
                  title="Last Name"
                  placeholder="Last Name"
                  name="lastName"
                  onChange={onChange}
                  value={value}
                  error={errors?.lastName?.message}
                />
              )}
            />
          </div>

          <div>
            <Controller
              name="designantion"
              control={control}
              rules={{
                required: {
                  value: true,
                  message: "Designantion is required",
                },
              }}
              render={({ field: { onChange, value } }) => (
                <ExInput
                  title="Designation"
                  placeholder="Enter Here"
                  name="designantion"
                  onChange={onChange}
                  value={value}
                  error={errors?.designantion?.message}
                />
              )}
            />
          </div>

          <div>
            <Controller
              name="experienceYears"
              control={control}
              rules={{
                required: {
                  value: true,
                  message: "Experience is required",
                },
              }}
              render={({ field: { onChange, value } }) => (
                <ExInput
                  title="Years of Experience"
                  placeholder="Ex. 5"
                  type="number"
                  onWheel={(e) => e.target.blur()}
                  name="experienceYears"
                  onChange={onChange}
                  value={value || ""}
                  error={errors?.experienceYears?.message}
                />
              )}
            />
          </div>

          <div>
            <Controller
              name="companyName"
              control={control}
              rules={{
                required: {
                  value: true,
                  message: "Company name is required",
                },
              }}
              render={({ field: { onChange, value } }) => (
                <ExInput
                  title="Company Name"
                  placeholder="Enter Here"
                  name="companyName"
                  onChange={onChange}
                  value={value}
                  error={errors?.companyName?.message}
                />
              )}
            />
          </div>

          <div>
            <Controller
              name="location"
              control={control}
              rules={{
                required: {
                  value: true,
                  message: "Preffered location is required",
                },
              }}
              render={({ field: { onChange, value } }) => (
                <ExDropdown
                  title="Location"
                  onChange={onChange}
                  placeholder="Select"
                  value={value || null}
                  optionValue="Id"
                  optionLabel="Name"
                  options={locationsArray}
                  dropDownHeight="40px"
                  dropDownBorderWidth="0px"
                  dropDownBorderColor="#696969"
                  error={errors?.location?.message}
                />
              )}
            />
          </div>
        </div>

        <div className="flex items-center">
          {conflictError && (
            <p className="text-base text-red-600 font-semibold flex items-center gap-2 justify-start">
              <ErrorIcon width="20" height="20" /> {conflictError}
            </p>
          )}

          <div className="w-fit ml-auto mr-0 space-x-2">
            <button
              type="button"
              className="px-10 py-2 text-expurple-800 text-base font-semibold text-center bg-transparent rounded-md"
              onClick={clearFieldsHandler}
            >
              Clear
            </button>

            <button className="px-10 py-2 text-exwhite-100 text-base font-semibold text-center bg-expurple-800 rounded-md">
              Refer
            </button>
          </div>
        </div>
      </form>

      {userDetails && (
        <div className="p-2 w-[50%]">
          <div
            className={`bg-gradient-to-b from-[#FAFBFF] to-expurple-100 h-full rounded-lg p-7 space-y-2 cursor-pointer border border-exgray-100/70`}
          >
            <div className="flex items-center justify-between gap-2">
              {userDetails.profilePicture ? (
                <div className="h-16 w-16 rounded-md flex items-center justify-center overflow-hidden">
                  <img
                    src={`${constants.baseUrl}/${userDetails?.profilePicture}?t=${token}`}
                    alt="profile"
                    className="w-full h-full object-cover"
                  />
                </div>
              ) : (
                <div className="h-16 w-16 bg-DefaultProfileImg rounded-md bg-cover bg-center bg-no-repeat"></div>
              )}
              <div className="self-stretch ml-3 flex-1 space-y-1">
                <h1 className="text-lg text-black font-semibold line-clamp-1">
                  {`${userDetails?.firstName} ${userDetails?.lastName}`}
                </h1>
                <div className="flex items-center justify-start gap-2">
                  <p className=" flex-1 text-base text-black font-semibold line-clamp-1">
                    {userDetails?.organization}
                  </p>
                </div>
              </div>
            </div>

            <div className="flex items-center gap-2 text-base text-exgray-300">
              <LocationIcon width="20" height="20" color="#6C7275" />
              <h1 className="line-clamp-1">{candidateLocation?.Name}</h1>
            </div>
            <div className="flex items-center gap-2">
              <IndustryIcon width="20" height="20" color="#6C7275" />
              <h1 className="flex items-center gap-2 text-base text-exgray-300">
                {preferdIndustry ? preferdIndustry?.Name : "N/A"}
              </h1>
            </div>

            <div>
              <div className="ml-auto mr-0 w-fit">
                <button
                  className="text-base px-8 py-2 bg-expurple-800 font-semibold text-center rounded-md text-exwhite-100"
                  onClick={navigateHandler}
                >
                  View
                </button>
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default CreateReferForm;
