import apiClient from "../config/axiosConfig";

class AiService {
  getCvDetails(req) {
    return apiClient.post(`/v1/resume_details`, req, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    });
  }

  getJdDetails(req) {
    return apiClient.post(
      `https://api.prod.linkcxo.com/v1/jd_autofilling`,
      req,
      {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      }
    );
  }
}

const aiService = new AiService();
export default aiService;
