import React, { useState } from "react";
import Popup from "../../../components/popup/Popup";
import EducationForm from "../forms/EducationForm";
import { useAppSelector } from "../../../config/hooks";
import EducationCard from "./EducationCard";
import { v4 as uuidv4 } from "uuid";

const EducationSection = () => {
  const candidateEdDetails = useAppSelector(
    (state) => state.candidateProfile.userEducation
  );
  const [togglePopup, setTogglePopup] = useState(false);

  const togglePopupHandler = () => {
    setTogglePopup(!togglePopup);
  };
  const closePopupHandler = () => {
    setTogglePopup(false);
  };

  return (
    <div className="space-y-2">
      <div className="flex items-center justify-between">
        <h1 className="text-lg text-black text-semibold text-start">
          Education
        </h1>
        <button
          type="button"
          className="text-sm text-expurple-800 font-medium"
          onClick={togglePopupHandler}
        >
          <span className="font-bold text-lg">+</span> Add new degree
        </button>
      </div>

      <div className="space-y-2">
        {candidateEdDetails?.map((edDetail) => (
          <EducationCard educationDetail={edDetail} key={uuidv4()} />
        ))}
      </div>

      <Popup onClose={closePopupHandler} visible={togglePopup}>
        <EducationForm onClose={closePopupHandler} />
      </Popup>
    </div>
  );
};

export default EducationSection;
