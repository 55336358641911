import React from "react";
import { v4 as uuidV4 } from "uuid";

const UploadedUserSkillsSection = ({ userSkills }) => {
  return (
    <div className="">
      <div className="space-y-2">
        <div className="flex items-center justify-between">
          <h1 className="text-lg text-black text-semibold text-start">
            Skills
          </h1>
        </div>

        <div className="flex flex-wrap gap-3">
          {userSkills &&
            userSkills.length > 0 &&
            userSkills?.map((skill) => (
              <div
                className="px-8 py-2 border-2 border-expurple-800 rounded-lg text-center text-base text-expurple-800 font-semibold"
                key={uuidV4()}
              >
                {skill}
              </div>
            ))}
        </div>
      </div>
    </div>
  );
};

export default UploadedUserSkillsSection;
