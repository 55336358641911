import React from "react";
import { v4 as uuid4 } from "uuid";

const ExRadioBtnGroup = ({
  title,
  error,
  options,
  selectedOption,
  ...props
}) => {
  return (
    <div>
      <label className={`text-lg font-normal text-expurple-800`}>{title}</label>
      <div className="h-10 flex items-center justify-start gap-10">
        {options.map((option) => (
          <div className="flex items-center justify-start gap-3" key={uuid4()}>
            <input
              type="radio"
              className="h-5 w-5 cursor-pointer"
              value={option}
              checked={selectedOption === option}
              {...props}
            />
            <h1>{option}</h1>
          </div>
        ))}
      </div>
      {error && <p className="text-red-400">{error}</p>}
    </div>
  );
};

export default ExRadioBtnGroup;
