import React from "react";

const StandardEditIcon = ({ width = "23", height = "23", color = "black" }) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 23 23"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M7.81192 10.976L7.02292 14.7381C6.98721 14.9087 6.99331 15.086 7.04067 15.2535C7.08803 15.421 7.17512 15.5735 7.29392 15.6968C7.41436 15.817 7.56086 15.9052 7.72101 15.954C7.88116 16.0027 8.05024 16.0105 8.21392 15.9768L11.8199 15.1534C12.0072 15.1106 12.1786 15.0127 12.3139 14.8713L21.4279 5.38393C21.7065 5.09394 21.9276 4.74965 22.0784 4.37073C22.2292 3.9918 22.3068 3.58567 22.3068 3.17552C22.3068 2.76537 22.2292 2.35924 22.0784 1.98032C21.9276 1.6014 21.7065 1.25711 21.4279 0.967111C20.8567 0.398885 20.097 0.0817871 19.3069 0.0817871C18.5168 0.0817871 17.7571 0.398885 17.1859 0.967111L8.08592 10.4639C7.94934 10.6037 7.85423 10.7815 7.81192 10.976ZM18.5999 2.44008C18.7902 2.25035 19.0435 2.14444 19.3069 2.14444C19.5704 2.14444 19.8237 2.25035 20.0139 2.44008C20.1989 2.63644 20.3025 2.90049 20.3025 3.17552C20.3025 3.45055 20.1989 3.71461 20.0139 3.91097L19.3069 4.64693L17.8929 3.175L18.5999 2.44008ZM9.69992 11.7193L16.4739 4.65005L17.8739 6.11469L11.0969 13.186L9.30192 13.5961L9.69992 11.7193Z"
        fill={color}
      />
      <path
        d="M3.5 22.9013H18.5C19.4297 22.8985 20.3204 22.5119 20.9765 21.8263C21.6327 21.1407 22.0008 20.2121 22 19.2444V11.3986C22 11.1225 21.8946 10.8577 21.7071 10.6625C21.5196 10.4673 21.2652 10.3576 21 10.3576C20.7348 10.3576 20.4804 10.4673 20.2929 10.6625C20.1054 10.8577 20 11.1225 20 11.3986V19.2444C20.0013 19.6601 19.8441 20.0595 19.5629 20.3548C19.2817 20.6501 18.8994 20.8171 18.5 20.8193H3.5C3.10057 20.8171 2.7183 20.6501 2.43708 20.3548C2.15587 20.0595 1.99867 19.6601 2 19.2444V3.65692C1.99867 3.24112 2.15587 2.84177 2.43708 2.54649C2.7183 2.25121 3.10057 2.08414 3.5 2.08193H11C11.2652 2.08193 11.5196 1.97226 11.7071 1.77704C11.8946 1.58182 12 1.31705 12 1.04097C12 0.764885 11.8946 0.500111 11.7071 0.304892C11.5196 0.109673 11.2652 0 11 0H3.5C2.57031 0.00275687 1.67964 0.389354 1.02346 1.07495C0.367281 1.76055 -0.000797091 2.68913 1.29611e-06 3.65692V19.2444C-0.000797091 20.2121 0.367281 21.1407 1.02346 21.8263C1.67964 22.5119 2.57031 22.8985 3.5 22.9013Z"
        fill={color}
      />
    </svg>
  );
};

export default StandardEditIcon;
