import React from "react";

const ErrorIcon = ({ width = "32", height = "32", color = "#E05151" }) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 32 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M16 32C24.836 32 32 24.836 32 16C32 7.164 24.836 0 16 0C7.164 0 0 7.164 0 16C0 24.836 7.164 32 16 32ZM18 18C18 18.5304 17.7893 19.0391 17.4142 19.4142C17.0391 19.7893 16.5304 20 16 20C15.4696 20 14.9609 19.7893 14.5858 19.4142C14.2107 19.0391 14 18.5304 14 18V8C14 7.46957 14.2107 6.96086 14.5858 6.58579C14.9609 6.21071 15.4696 6 16 6C16.5304 6 17.0391 6.21071 17.4142 6.58579C17.7893 6.96086 18 7.46957 18 8V18ZM16 21.968C16.2627 21.9681 16.5228 22.0199 16.7655 22.1205C17.0082 22.2211 17.2287 22.3685 17.4144 22.5543C17.6001 22.7401 17.7474 22.9607 17.8479 23.2034C17.9484 23.4461 18.0001 23.7063 18 23.969C17.9999 24.2317 17.9481 24.4918 17.8475 24.7345C17.7469 24.9772 17.5995 25.1977 17.4137 25.3834C17.2279 25.5691 17.0073 25.7164 16.7646 25.8169C16.5219 25.9174 16.2617 25.9691 15.999 25.969C15.4684 25.9689 14.9597 25.758 14.5846 25.3827C14.2095 25.0075 13.9989 24.4986 13.999 23.968C13.9991 23.4374 14.21 22.9287 14.5853 22.5536C14.9605 22.1785 15.4694 21.9679 16 21.968Z"
        fill={color}
      />
    </svg>
  );
};

export default ErrorIcon;
