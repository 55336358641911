import React from "react";

const WorkPlaceIcon = ({ width = "25", height = "24" }) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 25 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M12.4286 6.44444V2H1V22H23.8571V6.44444H12.4286ZM5.57143 19.7778H3.28571V17.5556H5.57143V19.7778ZM5.57143 15.3333H3.28571V13.1111H5.57143V15.3333ZM5.57143 10.8889H3.28571V8.66667H5.57143V10.8889ZM5.57143 6.44444H3.28571V4.22222H5.57143V6.44444ZM10.1429 19.7778H7.85714V17.5556H10.1429V19.7778ZM10.1429 15.3333H7.85714V13.1111H10.1429V15.3333ZM10.1429 10.8889H7.85714V8.66667H10.1429V10.8889ZM10.1429 6.44444H7.85714V4.22222H10.1429V6.44444ZM21.5714 19.7778H12.4286V17.5556H14.7143V15.3333H12.4286V13.1111H14.7143V10.8889H12.4286V8.66667H21.5714V19.7778ZM19.2857 10.8889H17V13.1111H19.2857V10.8889ZM19.2857 15.3333H17V17.5556H19.2857V15.3333Z"
        fill="#223870"
        stroke="white"
        strokeWidth="1.5"
      />
    </svg>
  );
};

export default WorkPlaceIcon;
