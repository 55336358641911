import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import nocoService from "../services/noco.service";

const initialState = {
  designations: [],
  industries: [],
  categories: [],
  functions: [],
  countries: [],
  cities: [],
  languages: [],
  currencies: [],
  jobTypes: [],
  workplaceTypes: [],
  minSalaries: [],
  maxSalaries: [],
  companySize: [],
  companyTypes: [],
  maxExperience: [],
  minExperience: [],
};

export const getDesignationsApi = createAsyncThunk(
  "appSlice/getDesignationsApi",
  async (data, { getState, rejectWithValue }) => {
    try {
      const response = await nocoService.getAllDesignations();
      return response;
    } catch (error) {
      return rejectWithValue(error.response?.data);
    }
  }
);

export const getLanguagesApi = createAsyncThunk(
  "appSlice/getLanguagesApi",
  async (data, { getState, rejectWithValue }) => {
    try {
      const response = await nocoService.getLanguageList();
      return response;
    } catch (error) {
      return rejectWithValue(error.response?.data);
    }
  }
);

export const getCountriesApi = createAsyncThunk(
  "appSlice/getCountriesApi",
  async (data, { getState, rejectWithValue }) => {
    try {
      const response = await nocoService.getAllCountries();
      return response;
    } catch (error) {
      return rejectWithValue(error.response?.data);
    }
  }
);

export const getCitiesApi = createAsyncThunk(
  "appSlice/getCitiesApi",
  async (data, { getState, rejectWithValue }) => {
    try {
      const response = await nocoService.getAllCities();
      return response;
    } catch (error) {
      return rejectWithValue(error.response?.data);
    }
  }
);

export const getIndustriesApi = createAsyncThunk(
  "appSlice/getIndustriesApi",
  async (data, { getState, rejectWithValue }) => {
    try {
      const response = await nocoService.getAllIndustries();
      return response;
    } catch (error) {
      return rejectWithValue(error.response?.data);
    }
  }
);

export const getCategoriesApi = createAsyncThunk(
  "appSlice/getCategoriesApi",
  async (data, { getState, rejectWithValue }) => {
    try {
      const response = await nocoService.getAllCategories();
      return response;
    } catch (error) {
      return rejectWithValue(error.response?.data);
    }
  }
);

export const getFunctionsApi = createAsyncThunk(
  "appSlice/getFunctionsApi",
  async (data, { getState, rejectWithValue }) => {
    try {
      const response = await nocoService.getAllFunctions();
      return response;
    } catch (error) {
      return rejectWithValue(error.response?.data);
    }
  }
);

export const getJobTypeApi = createAsyncThunk(
  "appSlice/getJobTypeApi",
  async (data, { getState, rejectWithValue }) => {
    try {
      const response = await nocoService.getAllJobType();
      return response;
    } catch (error) {
      return rejectWithValue(error.response?.data);
    }
  }
);

export const getWorkplaceTypeApi = createAsyncThunk(
  "appSlice/getWorkplaceTypeApi",
  async (data, { getState, rejectWithValue }) => {
    try {
      const response = await nocoService.getAllWorkplaceType();
      return response;
    } catch (error) {
      return rejectWithValue(error.response?.data);
    }
  }
);

export const getSalaryRangeMinApi = createAsyncThunk(
  "appSlice/getSalaryRangeMinApi",
  async (data, { getState, rejectWithValue }) => {
    try {
      const response = await nocoService.getSalaryRange();
      return response;
    } catch (error) {
      return rejectWithValue(error.response?.data);
    }
  }
);
export const getSalaryMaxRangeApi = createAsyncThunk(
  "appSlice/getSalaryMaxRangeApi",
  async (data, { getState, rejectWithValue }) => {
    try {
      const response = await nocoService.getMaxSalaryRange();
      return response;
    } catch (error) {
      return rejectWithValue(error.response?.data);
    }
  }
);

export const getCompanySizeRangeApi = createAsyncThunk(
  "appSlice/getCompanySizeRangeApi",
  async (data, { getState, rejectWithValue }) => {
    try {
      const response = await nocoService.getCompanySizeRange();
      return response;
    } catch (error) {
      return rejectWithValue(error.response?.data);
    }
  }
);

export const getCompanyTypesApi = createAsyncThunk(
  "appSlice/getCompanyTypesApi",
  async (data, { getState, rejectWithValue }) => {
    try {
      const response = await nocoService.getCompanyTypes();
      return response;
    } catch (error) {
      return rejectWithValue(error.response?.data);
    }
  }
);

export const getExperienceMaxApi = createAsyncThunk(
  "appSlice/getExperienceMaxApi",
  async (data, { getState, rejectWithValue }) => {
    try {
      const response = await nocoService.getExperienceMaxRange();
      return response;
    } catch (error) {
      return rejectWithValue(error.response?.data);
    }
  }
);

export const getExperienceMinApi = createAsyncThunk(
  "appSlice/getExperienceMinApi",
  async (data, { getState, rejectWithValue }) => {
    try {
      const response = await nocoService.getExperienceMinRange();
      return response;
    } catch (error) {
      return rejectWithValue(error.response?.data);
    }
  }
);

export const appSlice = createSlice({
  name: "appConfig",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(getDesignationsApi.fulfilled, (state, action) => {
      state.designations = action.payload.data.list;
    });

    builder.addCase(getLanguagesApi.fulfilled, (state, action) => {
      state.languages = action.payload.data.list;
    });

    builder.addCase(getCountriesApi.fulfilled, (state, action) => {
      state.countries = action.payload.data.list;
    });

    builder.addCase(getCitiesApi.fulfilled, (state, action) => {
      state.cities = action.payload.data.list;
    });

    builder.addCase(getIndustriesApi.fulfilled, (state, action) => {
      state.industries = action.payload.data.list;
    });

    builder.addCase(getCategoriesApi.fulfilled, (state, action) => {
      state.categories = action.payload.data.list;
    });

    builder.addCase(getFunctionsApi.fulfilled, (state, action) => {
      state.functions = action.payload.data.list;
    });

    builder.addCase(getJobTypeApi.fulfilled, (state, action) => {
      state.jobTypes = action.payload.data.list;
    });

    builder.addCase(getWorkplaceTypeApi.fulfilled, (state, action) => {
      state.workplaceTypes = action.payload.data.list;
    });

    builder.addCase(getSalaryRangeMinApi.fulfilled, (state, action) => {
      state.minSalaries = action.payload.data.list;
    });

    builder.addCase(getSalaryMaxRangeApi.fulfilled, (state, action) => {
      state.maxSalaries = action.payload.data.list;
    });

    builder.addCase(getCompanySizeRangeApi.fulfilled, (state, action) => {
      state.companySize = action.payload.data.list;
    });

    builder.addCase(getCompanyTypesApi.fulfilled, (state, action) => {
      state.companyTypes = action.payload.data.list;
    });

    builder.addCase(getExperienceMaxApi.fulfilled, (state, action) => {
      state.maxExperience = action.payload.data.list;
    });

    builder.addCase(getExperienceMinApi.fulfilled, (state, action) => {
      state.minExperience = action.payload.data.list;
    });
  },
});

export default appSlice.reducer;
