import { useAppSelector } from "../../config/hooks";

const useWorkPlaceTypes = () => {
  const workplaceTypesArray = useAppSelector(
    (state) => state.appConfig.workplaceTypes
  );

  const defaultWorkPlaceType = (dafaultId) => {
    if (isNaN(dafaultId)) {
      return { Title: dafaultId, Id: dafaultId };
    }
    const selectedJobType = workplaceTypesArray.find(
      (workplaceType) => workplaceType.Id == dafaultId
    );
    return selectedJobType;
  };
  return { workplaceTypesArray, defaultWorkPlaceType };
};

export default useWorkPlaceTypes;
