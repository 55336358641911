import React, { useState } from "react";
import { Controller, useForm } from "react-hook-form";
import ExInput from "../../../components/basic-components/ExInput";
import ExTextarea from "../../../components/basic-components/ExTextarea";
import CustomDatePicker from "../../../components/basic-components/CustomDatePicker";
import { unwrapResult } from "@reduxjs/toolkit";
import moment from "moment/moment";
import { useAppDispatch, useAppSelector } from "../../../config/hooks";
import {
  addEducationApi,
  updateEducationApi,
} from "../../../slices/candidateProfileSlice";
import { toast } from "sonner";

const EducationForm = ({ onClose = () => {}, educationDetail = null }) => {
  const dispatch = useAppDispatch();
  const userId = useAppSelector((state) => state.login.session.userId);
  const [isLoading, setIsLoading] = useState(false);

  const form = useForm({
    defaultValues: {
      school: educationDetail?.institute ? educationDetail?.institute : "",
      degree: educationDetail?.degree ? educationDetail?.degree : "",
      startDate:
        educationDetail?.startDate &&
        educationDetail?.startDate.month &&
        educationDetail?.startDate.year
          ? moment(
              `${educationDetail?.startDate.month} ${educationDetail?.startDate.year}`
            ).format()
          : "",
      endDate:
        educationDetail?.endDate &&
        educationDetail?.endDate.month &&
        educationDetail?.endDate.year
          ? moment(
              `${educationDetail?.endDate.month} ${educationDetail?.endDate.year}`
            ).format()
          : "",
      fieldStudy: educationDetail?.fieldOfStudy
        ? educationDetail?.fieldOfStudy
        : "",
      grade: educationDetail?.grade ? educationDetail?.grade : "",
      description: educationDetail?.description
        ? educationDetail?.description
        : "",
    },
  });

  const { handleSubmit, control, watch, formState, reset } = form;
  const { errors, isDirty } = formState;

  const onSubmit = async (formData) => {
    setIsLoading(true);
    const startDateMonth = moment(formData.startDate).format("MMMM");
    const startDateYear = moment(formData.startDate).format("YYYY");
    const endDateMonth = moment(formData.endDate).format("MMMM");
    const endDateYear = moment(formData.endDate).format("YYYY");

    if (!educationDetail?.id) {
      try {
        await dispatch(
          addEducationApi({
            userId: userId,
            institute: formData.school,
            organizationId: "",
            degree: formData.degree,
            description: formData.description,
            grade: formData.grade,
            fieldOfStudy: formData.fieldStudy,
            activitiesAndSocieties: [],
            startDate: {
              month: startDateMonth,
              year: startDateYear,
            },
            endDate: {
              month: endDateMonth,
              year: endDateYear,
            },
          })
        ).then(unwrapResult);
        setIsLoading(false);
        toast.success("Education added !");
        onClose();
        reset();
      } catch (error) {
        toast.error("Something went wrong");
        setIsLoading(false);
        onClose();
      }
    } else {
      try {
        await dispatch(
          updateEducationApi({
            userId: userId,
            educationId: educationDetail.id,
            institute: formData.school,
            organizationId: "",
            degree: formData.degree,
            description: formData.description,
            grade: formData.grade,
            fieldOfStudy: formData.fieldStudy,
            activitiesAndSocieties: [],
            startDate: {
              month: startDateMonth,
              year: startDateYear,
            },
            endDate: {
              month: endDateMonth,
              year: endDateYear,
            },
          })
        ).then(unwrapResult);
        setIsLoading(false);
        toast.success("Updated successfully!");
        onClose();
      } catch (error) {
        toast.error("Something went wrong");
        setIsLoading(false);
        onClose();
      }
    }
  };

  return (
    <div className="w-[50%] max-h-[90%] bg-expurple-200 p-10 rounded-xl overflow-y-auto no-scrollbar">
      <h1 className="text-3xl text-expurple-800 font-semibold text-center">
        Education
      </h1>
      <form onSubmit={handleSubmit(onSubmit)} className="space-y-2">
        <div>
          <Controller
            name="school"
            control={control}
            rules={{
              required: {
                value: true,
                message: "School is required",
              },
            }}
            render={({ field: { onChange, value } }) => (
              <ExInput
                placeholder="Ex. Mumbai University"
                title="School *"
                name="school"
                value={value || ""}
                onChange={onChange}
                error={errors?.school?.message}
              />
            )}
          />
        </div>

        <div>
          <Controller
            name="degree"
            control={control}
            rules={{
              required: {
                value: true,
                message: "Degree is required",
              },
            }}
            render={({ field: { onChange, value } }) => (
              <ExInput
                placeholder="Ex. Bachler's"
                title="Degree *"
                name="degree"
                value={value || ""}
                onChange={onChange}
                error={errors?.degree?.message}
              />
            )}
          />
        </div>

        <div className="flex justify-between items-center gap-6">
          <div className="w-full">
            <Controller
              name="startDate"
              control={control}
              rules={{
                required: {
                  value: true,
                  message: "Start date is required",
                },
              }}
              render={({ field: { onChange, value } }) => (
                <CustomDatePicker
                  title="Start date *"
                  name="startDate"
                  onChange={onChange}
                  selected={value ? new Date(value) : null}
                  maxDate={new Date()}
                  placeholderText="Ex. mm/yy"
                  popperPlacement="bottom-end"
                  dateFormat="MMMM yyyy"
                  showMonthYearPicker={true}
                  error={errors?.startDate?.message}
                />
              )}
            />
          </div>
          <div className="w-full">
            <Controller
              name="endDate"
              control={control}
              rules={{
                required: {
                  value: true,
                  message: "End date is required",
                },
              }}
              render={({ field: { onChange, value } }) => (
                <CustomDatePicker
                  title="End date *"
                  name="endDate"
                  onChange={onChange}
                  selected={value ? new Date(value) : null}
                  maxDate={new Date()}
                  minDate={new Date(watch("startDate"))}
                  placeholderText="Ex. mm/yy"
                  popperPlacement="bottom-end"
                  dateFormat="MMMM yyyy"
                  showMonthYearPicker={true}
                  error={errors?.startDate?.message}
                  disabled={!watch("startDate")}
                />
              )}
            />
          </div>
        </div>

        <div>
          <Controller
            name="fieldStudy"
            control={control}
            rules={{
              required: {
                value: true,
                message: "Degree is required",
              },
            }}
            render={({ field: { onChange, value } }) => (
              <ExInput
                placeholder="Ex. Bussiness"
                title="Field of study *"
                name="fieldStudy"
                value={value || ""}
                onChange={onChange}
                error={errors?.fieldStudy?.message}
              />
            )}
          />
        </div>

        <div>
          <Controller
            name="grade"
            control={control}
            rules={{
              required: {
                value: true,
                message: "Grade is required",
              },
            }}
            render={({ field: { onChange, value } }) => (
              <ExInput
                placeholder="In percentage or GPA"
                title="Grade *"
                name="grade"
                type="number"
                onWheel={(e) => e.target.blur()}
                value={value || ""}
                onChange={onChange}
                error={errors?.grade?.message}
              />
            )}
          />
        </div>

        <div>
          <Controller
            name="description"
            control={control}
            rules={{
              required: {
                value: true,
                message: "Description is required",
              },
              maxLength: {
                value: 2600,
                message: "Only 2600 characters are allowed",
              },
            }}
            render={({ field: { onChange, value } }) => (
              <ExTextarea
                rows={8}
                name="description"
                value={value || ""}
                title="Description *"
                maxLength={2600}
                onChange={onChange}
                placeholder="This information will be seen by the candidate in job discription."
                error={errors?.companyDescription?.message}
              />
            )}
          />
        </div>

        <div>
          <div className="w-fit ml-auto mr-0">
            <button
              className={`text-base text-center px-8 py-2 ${
                isLoading || !isDirty
                  ? "bg-exgray-400/50 text-exwhite-100"
                  : "bg-expurple-800 text-golden"
              } rounded-lg`}
              disabled={isLoading || !isDirty}
            >
              Save
            </button>
          </div>
        </div>
      </form>
    </div>
  );
};

export default EducationForm;
