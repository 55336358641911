import React, { useMemo, useState } from "react";

import LocationIcon from "../../../assets/Icons/LocationIcon";
import moment from "moment";
import { useAppSelector } from "../../../config/hooks";
import ClockIcon from "../../../assets/Icons/ClockIcon";
import useWorkPlaceTypes from "../../../utils/dropdown-options/useWorkPlaceTypes";
import useLocations from "../../../utils/dropdown-options/useLocations";

const JobCard = ({ job, jobApplication = null }) => {
  const { defaultLocations } = useLocations();
  const { defaultWorkPlaceType } = useWorkPlaceTypes();

  const loc = useMemo(() => {
    return job.location && job.location.length > 0
      ? defaultLocations(job.location)
      : null;
  }, [job.location]);

  const workplace = useMemo(() => {
    return job?.workplaceType ? defaultWorkPlaceType(job?.workplaceType) : "";
  }, [job?.workplaceType]);

  const jobRelativeTime = job?.createdAt
    ? moment(job?.createdAt).fromNow()
    : "";

  return (
    // <Link className="w-[33.3333333%] p-2" to={`/job/${job.id}`}>
    <div className="p-2">
      <div
        className={`bg-gradient-to-b from-[#FAFBFF] to-expurple-100 h-full rounded-lg p-7 space-y-2 cursor-pointer border border-exgray-100/70`}
      >
        {job?.workplaceType && workplace?.Title && (
          <div className="max-[1400px]:block max-[1400px]:self-start max-[1400px]:ml-auto max-[1400px]:mr-0 hidden w-fit">
            <p className="py-1 px-4 rounded-full bg-[#CEECD5] text-green-900 whitespace-nowrap line-clamp-1">
              {workplace?.Title}
            </p>
          </div>
        )}
        <div className="flex items-center justify-between gap-2">
          <div className="h-16 w-16 bg-deafultCompanyProfile rounded-md bg-cover bg-center bg-no-repeat"></div>
          <div className="self-stretch ml-3 flex-1 space-y-1">
            <h1 className="text-lg text-black font-semibold line-clamp-1">
              {job?.title}
            </h1>
            <div className="flex items-center justify-start gap-2">
              <p className=" flex-1 text-base text-black font-semibold line-clamp-1">
                {job?.hideCompany ? "Confidential" : job?.company}
              </p>
              {jobApplication?.status && jobApplication?.status === "HOLD" && (
                <div className="px-4 border border-red-500 text-base text-red-500 font-semibold text-center w-fit rounded-full line-clamp-1">
                  Hold
                </div>
              )}

              {jobApplication?.status &&
                jobApplication?.status === "REJECTED" && (
                  <div className="px-4 border border-red-500 text-base text-red-500 font-semibold text-center w-fit rounded-full line-clamp-1">
                    Rejected
                  </div>
                )}

              {jobApplication?.status &&
                jobApplication?.status === "SHORTLISTED" && (
                  <div className="px-4 border border-green-800 text-base text-green-800 font-semibold text-center w-fit rounded-full line-clamp-1">
                    Shortlisted
                  </div>
                )}
            </div>
          </div>
          {job?.workplaceType && workplace?.Title && (
            <div className="min-[1400px]:block min-[1400px]:self-start min-[1400px]:ml-auto min-[1400px]:mr-0 hidden">
              <p className="py-1 px-4 rounded-full bg-[#CEECD5] text-green-900 whitespace-nowrap line-clamp-1">
                {workplace?.Title}
              </p>
            </div>
          )}
        </div>

        <div className="flex gap-2 text-base text-exgray-300">
          <LocationIcon width="20" height="20" color="#6C7275" />
          <h1 className="line-clamp-1">
            {loc && loc.length > 0
              ? loc.map(
                  (l, i) => `${l?.Name} ${loc?.length - 1 !== i ? ", " : ""}`
                )
              : "N/A"}
          </h1>
        </div>
        <div className="flex items-center justify-between">
          <h1 className="flex items-center gap-2 text-base text-exgray-300">
            <ClockIcon width="20" height="20" color="#6C7275" />
            {`Posted ${jobRelativeTime}`}
          </h1>

          {job.jobStatus === "CLOSED" && (
            <div className="px-4 border border-red-500 text-base text-red-500 font-semibold text-center w-fit rounded-full">
              Closed
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default JobCard;
