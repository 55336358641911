import useReadMoreToggle from "../../../utils/custome-hooks/useReadMoreToggle";
import { useAppSelector } from "../../../config/hooks";

const UploadedUserExpCard = ({ expDetail }) => {
  const employmentTypes = useAppSelector((state) => state.appConfig.jobTypes);

  const { userExperienceDetails, organization } = expDetail;

  const { location, title, startDate, endDate, description, employmentType } =
    userExperienceDetails[0];

  const jobType = employmentTypes.find((option) => option.Id == employmentType);

  const { contentRef, showMore, isOverflowing, textToggleHandler } =
    useReadMoreToggle(description);

  return (
    <div>
      <div className="p-4 bg-expurple-100 space-y-1">
        <div className="flex items-center justify-between relative">
          <h1 className="text-base text-expurple-800 font-semibold">{title}</h1>
        </div>

        <div className="w-[98%] flex items-center justify-between">
          <h2 className="text-base text-expurple-600 font-normal">
            {organization}
          </h2>
          <div className="flex items-center justify-center gap-12">
            <h2>{jobType?.Title}</h2>
            <h2 className="text-base text-expurple-600 font-normal">
              {`${startDate.month} ${startDate.year} - ${endDate.month} ${endDate.year}`}
            </h2>
          </div>
        </div>

        <h2 className="text-base text-expurple-600 font-normal">{location}</h2>

        <div>
          <p
            className={`text-sm text-expurple-600 font-normal ${
              showMore ? "line-clamp-none" : "line-clamp-3"
            }`}
            ref={contentRef}
          >
            {description}
          </p>
          {description && isOverflowing && (
            <div className="flex justify-end items-center">
              <button
                className="border-none text-black text-base font-normal w-[100px] text-center"
                onClick={textToggleHandler}
              >
                {showMore ? "View Less" : "View More"}
              </button>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default UploadedUserExpCard;
