import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import companyMemberService from "../services/companyMember.service";
import { logout } from "./loginSlice";

const initialState = {
  members: [],
  memberDetails: null,
  jobAuthorMemberDetails: null,
};

export const createMemberApi = createAsyncThunk(
  "companyMember/createMemberApi",
  async (data, { getState, rejectWithValue }) => {
    try {
      const response = await companyMemberService.createMember(data);
      return response;
    } catch (error) {
      return rejectWithValue(error.response?.data);
    }
  }
);

export const getMembersApi = createAsyncThunk(
  "companyMember/getMembersApi",
  async (data, { getState, rejectWithValue }) => {
    try {
      const response = await companyMemberService.getMembers(data);
      return response;
    } catch (error) {
      return rejectWithValue(error.response?.data);
    }
  }
);

export const getMemberByIdApi = createAsyncThunk(
  "companyMember/getMemberByIdApi",
  async (data, { getState, rejectWithValue }) => {
    try {
      const response = await companyMemberService.getMemberById(data);
      return response;
    } catch (error) {
      return rejectWithValue(error.response?.data);
    }
  }
);

export const getJobAuthour_CompanyMemberApi = createAsyncThunk(
  "companyMember/getJobAuthourCompanyMemberApi",
  async (data, { getState, rejectWithValue }) => {
    try {
      const response = await companyMemberService.getMemberById(data);
      return response;
    } catch (error) {
      return rejectWithValue(error.response?.data);
    }
  }
);

export const removeMemberApi = createAsyncThunk(
  "companyMember/removeMemberApi",
  async (data, { getState, rejectWithValue }) => {
    try {
      const response = await companyMemberService.removeMember(data);
      return response;
    } catch (error) {
      return rejectWithValue(error.response?.data);
    }
  }
);

export const companyMemberSlice = createSlice({
  name: "companyMember",
  initialState,
  reducers: {
    logoutMember: (state, action) => {
      state.members = [];
      state.memberDetails = null;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(createMemberApi.fulfilled, (state, action) => {
      const response = action.payload.data.data;
      state.members.push(response);
    });

    builder.addCase(getMembersApi.fulfilled, (state, action) => {
      const response = action.payload.data.data;
      state.members = response;
    });

    builder.addCase(removeMemberApi.fulfilled, (state, action) => {
      const response = action.meta.arg;
      state.members = state.members.filter(
        (member) => member.id !== response.memberId
      );
    });

    builder.addCase(getMemberByIdApi.fulfilled, (state, action) => {
      const response = action.payload.data.data;
      state.memberDetails = response;
    });
    builder.addCase(
      getJobAuthour_CompanyMemberApi.fulfilled,
      (state, action) => {
        const response = action.payload.data.data;
        state.jobAuthorMemberDetails = response;
      }
    );

    builder.addCase(logout, (state) => {
      // Reset this slice's state to initial state
      return initialState;
    });
  },
});

export const { logoutMember } = companyMemberSlice.actions;
export default companyMemberSlice.reducer;
