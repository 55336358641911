import Select from "react-select";

const ExDropdown = ({
  title,
  error,
  optionValue,
  optionLabel,
  searchJob = false,
  dropDownHeight = "3.5rem",
  dropDownBorderWidth = "0px",
  dropDownBorderColor = "rgb(108 114 117 )",
  ...props
}) => {
  const customStyles = {
    control: (provided, state) => ({
      ...provided,
      cursor: "pointer",
      paddingLeft: searchJob && "30px",
      height: dropDownHeight,
      //   maxWidth: "150px",
      fontSize: "16px",
      color: state.isSelected ? "#5071C4" : "#5071C4",
      border: `${dropDownBorderWidth} solid ${
        state.isFocused
          ? error
            ? "#dc2623"
            : dropDownBorderColor
          : error
          ? "#dc2623"
          : dropDownBorderColor
      }`,
      borderRadius: "2px",
      backgroundColor: "#E0DFF4",
      boxShadow: state.isFocused ? "none" : "none",
      ":hover": {
        border: `${dropDownBorderWidth} solid ${
          state.isFocused
            ? error
              ? "#dc2623"
              : dropDownBorderColor
            : error
            ? "#dc2623"
            : dropDownBorderColor
        }`,
      },
    }),
    input: (provided) => ({
      ...provided,
      fontSize: "16px",
      color: "#5071C4",
    }),
    singleValue: (provided) => ({
      ...provided,
      fontSize: "16px",
      color: "#5071C4",
    }),
    option: (provided, state) => ({
      ...provided,
      fontSize: "16px",
      color: state.isSelected ? "#5071C4" : "#5071C4",
      backgroundColor: state.isSelected ? "#E0DFF4" : "#E0DFF4",
      ":hover": {
        backgroundColor: "#223870",
        color: "#FDCF57",
        cursor: "pointer",
      },
    }),
    menu: (provided) => ({
      ...provided,
      backgroundColor: "#E0DFF4",
      zIndex: 99999,
    }),
    menuList: (provided) => ({
      ...provided,
      // maxHeight: "140px",
      "::-webkit-scrollbar": {
        width: 0,
      },
    }),
    indicatorSeparator: (provided) => ({
      ...provided,
      display: "none",
    }),

    dropdownIndicator: (provided) => ({
      ...provided,
      // display: "none",
      color: "#223870",
      ":hover": {
        color: "#223870",
      },
    }),

    dropdownIndicatorContainer: (provided) => ({
      ...provided,
      cursor: "pointer",
      paddingRight: "8px",
    }),
    indicatorContainer: (provided) => ({
      ...provided,
      "& svg": {
        display: "none",
      },
    }),
    clearIndicator: (provided) => ({
      ...provided,
      display: "none",
    }),
    placeholder: (provided) => ({
      ...provided,
      color: "#5071C4", // Adjust the placeholder text color
      fontSize: "16px", // Adjust the font size as needed
    }),
  };

  return (
    <div>
      <label className="text-lg text-expurple-800">{title}</label>
      <Select
        {...props}
        getOptionValue={(position) => position[optionValue]}
        getOptionLabel={(position) => position[optionLabel]}
        isClearable={true}
        backspaceRemovesValue={true}
        styles={customStyles}
        isSearchable={true}
        theme={(theme) => ({
          ...theme,
          borderRadius: 0,
          colors: {
            ...theme.colors,
            text: "#FDCF57",
            primary: "black",
          },
        })}
      />
      {error && <p className="text-red-400">{error}</p>}
    </div>
  );
};

export default ExDropdown;
