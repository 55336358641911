import React from "react";

const ClockIcon = ({ width = "15", height = "14", color = "#7A88A9" }) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 15 14"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M12.9489 2.05136C11.8551 0.957391 10.4299 0.256422 8.89567 0.0579054C7.36148 -0.140612 5.80473 0.174498 4.46851 0.954031C4.39636 0.996209 4.33322 1.05219 4.2827 1.11876C4.23218 1.18534 4.19527 1.26122 4.17407 1.34207C4.15288 1.42291 4.14781 1.50714 4.15917 1.58994C4.17052 1.67274 4.19808 1.7525 4.24025 1.82465C4.28243 1.8968 4.33841 1.95994 4.40499 2.01046C4.47157 2.06098 4.54744 2.0979 4.62829 2.11909C4.70913 2.14029 4.79336 2.14535 4.87616 2.134C4.95896 2.12264 5.03872 2.09509 5.11087 2.05291C6.31315 1.35149 7.7297 1.11185 9.0958 1.37876C10.4619 1.64567 11.6841 2.40088 12.5339 3.50324C13.3838 4.60561 13.8031 5.97971 13.7137 7.36876C13.6242 8.75781 13.032 10.0668 12.0478 11.051C11.0636 12.0352 9.7546 12.6274 8.36555 12.7169C6.9765 12.8064 5.6024 12.387 4.50003 11.5371C3.39766 10.6873 2.64246 9.46511 2.37555 8.09901C2.10864 6.73291 2.34828 5.31637 3.0497 4.11409C3.12196 3.99053 3.15046 3.84621 3.13059 3.70446C3.11072 3.5627 3.04364 3.43178 2.94018 3.33286C2.83673 3.23393 2.70294 3.17277 2.56044 3.15926C2.41794 3.14574 2.27504 3.18067 2.15484 3.25839L0.291002 4.46308C0.220807 4.50844 0.160234 4.56718 0.112742 4.63595C0.0652496 4.70472 0.0317679 4.78218 0.0142081 4.86389C-0.0212554 5.02891 0.0102882 5.20126 0.101899 5.34302C0.147261 5.41322 0.206004 5.47379 0.274774 5.52128C0.343545 5.56877 0.420996 5.60225 0.502706 5.61981C0.667728 5.65528 0.840078 5.62374 0.981842 5.53212L1.1798 5.40424C0.901531 6.59175 0.938277 7.83134 1.2864 9.00027C1.63452 10.1692 2.28195 11.2269 3.16456 12.0687C4.04718 12.9105 5.13437 13.5071 6.31847 13.7995C7.50257 14.0919 8.74252 14.0699 9.91552 13.7358C11.0885 13.4016 12.1539 12.7668 13.0061 11.8943C13.8583 11.0218 14.4679 9.94175 14.7744 8.76122C15.0808 7.58068 15.0736 6.34056 14.7535 5.16367C14.4333 3.98677 13.8112 2.91392 12.9489 2.05136Z"
        fill={color}
      />
      <path
        d="M7.63646 3C7.46766 3.00001 7.30578 3.06707 7.18642 3.18642C7.06707 3.30578 7.00001 3.46766 7 3.63645V7.45518C6.99997 7.61775 7.06219 7.77416 7.17388 7.89228L9.27903 10.1199C9.3364 10.1807 9.40521 10.2296 9.48151 10.2639C9.55781 10.2981 9.6401 10.3169 9.72369 10.3194C9.80728 10.3218 9.89053 10.3077 9.96867 10.2779C10.0468 10.2481 10.1183 10.2032 10.1791 10.1458C10.2399 10.0883 10.2887 10.0195 10.3229 9.94316C10.3571 9.86683 10.3758 9.78452 10.3782 9.70093C10.3805 9.61733 10.3663 9.5341 10.3365 9.45599C10.3066 9.37787 10.2617 9.30641 10.2042 9.24568L8.27291 7.20206V3.63645C8.2729 3.46766 8.20584 3.30578 8.08649 3.18642C7.96713 3.06707 7.80525 3.00001 7.63646 3Z"
        fill={color}
      />
    </svg>
  );
};

export default ClockIcon;
