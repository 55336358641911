import EditIcon from "../../../assets/Icons/EditIcon";
import DeleteIcon from "../../../assets/Icons/DeleteIcon";
import useReadMoreToggle from "../../../utils/custome-hooks/useReadMoreToggle";
import { useAppDispatch, useAppSelector } from "../../../config/hooks";
import { userEducationDelete } from "../../../slices/candidateProfileSlice";
import EducationForm from "../forms/EducationForm";
import Popup from "../../../components/popup/Popup";
import { useState } from "react";
import VerticleThreeDotsIcon from "../../../assets/Icons/VerticleThreeDotsIcon";

const EducationCard = ({ educationDetail }) => {
  const dispatch = useAppDispatch();
  const [togglePopup, setTogglePopup] = useState(false);
  const [toggleModal, setToggleModal] = useState(false);

  const userId = useAppSelector((state) => state.login.userId);

  const { institute, degree, startDate, endDate, description, id } =
    educationDetail;

  const { contentRef, showMore, isOverflowing, textToggleHandler } =
    useReadMoreToggle(description);

  const deletedEducationHandler = () => {
    toggleModalHandler();
    dispatch(
      userEducationDelete({
        infoType: "education",
        userId: userId,
        id: id,
      })
    );
  };

  const toggleModalHandler = () => {
    setToggleModal(!toggleModal);
  };
  const togglePopupHandler = () => {
    toggleModalHandler();
    setTogglePopup(!togglePopup);
  };

  return (
    <div>
      <div className="p-4 bg-expurple-100 space-y-1">
        <div className="flex items-center justify-between relative">
          <h1 className="text-base text-expurple-800 font-semibold">
            {institute}
          </h1>
          <div className="w-fit cursor-pointer" onClick={toggleModalHandler}>
            <VerticleThreeDotsIcon width="30" height="30" />
          </div>
          {toggleModal && (
            <div className="absolute top-6 right-3 bg-exwhite-100 py-2 divide-y-2 divide-exgray-100">
              <button
                className="w-full py-1 font-semibold px-6 text-center text-expurple-600 text-base"
                onClick={togglePopupHandler}
              >
                Edit
              </button>
              <button
                className="w-full py-1 font-semibold px-6 text-center text-expurple-600 text-base"
                onClick={deletedEducationHandler}
              >
                Delete
              </button>
            </div>
          )}
        </div>

        <h2 className="text-base text-expurple-600 font-normal">{degree}</h2>
        <h2 className="text-base text-expurple-600 font-normal">
          {startDate.year} - {endDate.year}
        </h2>

        <div>
          <p
            className={`text-sm text-expurple-600 font-normal ${
              showMore ? "line-clamp-none" : "line-clamp-3"
            }`}
            ref={contentRef}
          >
            {description}
          </p>
          {description && isOverflowing && (
            <div className="flex justify-end items-center">
              <button
                className="border-none text-black text-base font-normal w-[100px] text-center"
                onClick={textToggleHandler}
              >
                {showMore ? "View Less" : "View More"}
              </button>
            </div>
          )}
        </div>
      </div>
      <Popup onClose={() => setTogglePopup(false)} visible={togglePopup}>
        <EducationForm educationDetail={educationDetail} />
      </Popup>
    </div>
  );
};

export default EducationCard;
