import React, { useEffect } from "react";
import JobSearchForm from "./components/JobSearchForm";

import AutomotiveImage from "../../assets/images/industry-verticals/Automotive.png";
import DigitalImage from "../../assets/images/industry-verticals/Digital.png";
import IndustrialImage from "../../assets/images/industry-verticals/Industrial.png";
import ChemicalIndustryImage from "../../assets/images/industry-verticals/ChemicalIndustry.png";
import InfrastructurePowerImage from "../../assets/images/industry-verticals/Infrastructure&Power.png";
import FinancialServicesImage from "../../assets/images/industry-verticals/FinancialServices.png";
import HealthcareImage from "../../assets/images/industry-verticals/Healthcare.png";

import payal from "../../assets/images/Clients/Payal_S.png";
import kamla from "../../assets/images/Clients/Kamla.jpg";
import santosh from "../../assets/images/Clients/Santosh.jpg";
import murtaza from "../../assets/images/Clients/Murtaza.jpg";
import manthan from "../../assets/images/Candidates/Manthan.jpg";
import pankaj from "../../assets/images/Candidates/Pankaj.jpg";
import kumud from "../../assets/images/Candidates/Kumud.jpg";
import chinmaya from "../../assets/images/Candidates/Chinmay.jpg";
import vaibhav from "../../assets/images/Candidates/Vaibhav.jpg";
import hriday from "../../assets/images/Candidates/Hriday.jpg";
import dhana from "../../assets/images/Candidates/Dhanyata.jpg";
import prasad from "../../assets/images/Candidates/Prasad.jpg";
import girish from "../../assets/images/Candidates/Girish.jpg";
import sanket from "../../assets/images/Candidates/Sanket.jpg";
import amit from "../../assets/images/Candidates/Amit_Wadekar.jpg";
import swapnil from "../../assets/images/Candidates/Swapnil.jpg";
import aditiJ from "../../assets/images/Candidates/Aditi_Joshi.jpg";
import prakash from "../../assets/images/Candidates/Prakash_V.jpg";
import umang from "../../assets/images/Candidates/Umang.jpg";
import nitya from "../../assets/images/Candidates/Nitya.jpg";
import aditiA from "../../assets/images/Candidates/Aditi_Agg.jpg";
import shiva from "../../assets/images/Candidates/Shiva.jpg";
import sChandra from "../../assets/images/Candidates/S_Chandra.jpg";
import isha from "../../assets/images/Candidates/IshaPatil.jpg";
import divya from "../../assets/images/Candidates/Divyani.jpg";
import soorya from "../../assets/images/Candidates/Surya_Kri.jpg";
import rakesh from "../../assets/images/Candidates/Rakesh_K.jpg";
import parag from "../../assets/images/Candidates/Parag.jpg";

import { motion } from "framer-motion";
import * as constants from "../../constant";

import { ITEMS, ITMES2, ITEMS3 } from "../../constant";
import EmblaCarousel from "../../components/embla-carousel/EmblaCarousel";

import { v4 as uuidV4 } from "uuid";
import { useLocation, useNavigate } from "react-router-dom";
import { scroller } from "react-scroll";
import { useAppSelector } from "../../config/hooks";

export const clientTestimonial = [
  {
    image: payal,
    // image: "https://exotalent-logo.s3.ap-south-1.amazonaws.com/Payal_S.png",
    content:
      "I completely agree our partnership is highly valued and the journey of the last 5 years has been enriching and rewarding. The external talent market dynamics in the last 2-3 years has been drastically changing, it's a VUCA world, it's the candidates world with multiple offers in hand, war for talent etc , but must say ExoTalent has always taken the challenge and never did we dry up on the inflow of resumes. I highly appreciate the hard work done by Richa , Bhagya and the entire backend team. Thank you Hema for taking the ownership of hiring the right talent for John Deere.",
    name: "Payal S",
    designation: "Head Talent Acquisition India - John Deere",
  },

  {
    image: kamla,
    content:
      "I have been working with ExoTalent for the past 4 years, Your conversion rate from profile to shortlist is pretty good. The quality of service mainly for mid-senior positions and IT space is very good and your response is very quick in every stage of recruitment. Thank you for being our partners in our growth Journey.",
    name: "Kamalapriya Mohanarangan",
    designation: "Sr. Manager HR - Yokogawa",
  },
  {
    image: murtaza,
    content:
      "I have found Exo Talent to be professional and efficient at what they do. They are eager to listen and improve which provides a continuous feedback to adapt with changing times and continue to excel ",
    name: "Murtaza Hita",
    designation: "Engineering Manager",
  },
  {
    image: santosh,
    content:
      "The team is mature and understand the needs quickly. The response time and communication is also great. They have good processes established",
    name: "Santhosh Venkateshaiah",
    designation: "Manufacturing Quality Engineering Supervisor",
  },
];

const candidatesTestimonial = [
  {
    id: "1",
    image: manthan,
    content:
      "ExoTalent Consultancy helped me to get a job in reputed company.Mr. Amrit Mallick who was the coordinator for the whole process Made good coordination with me and the company while interview process.Guided me in professional manner to achieve my dream. Team has very strong in follow-up and very helpful. I will recommend my friends about this consultancy.",
    name: "Manthan Jape ",
    designation: "Senior Engineer I",
  },
  {
    id: "2",
    image: pankaj,
    content:
      "Great experience with this company. Hiring process was very smooth and well managed. Staff is friendly and supportive. Thanks to the Exotalent team.",
    name: "Pankaj Shidaye",
    designation: "Senior Engineer I",
    quotation: ``,
  },
  {
    id: "3",
    image: kumud,
    content:
      "Mansi Nigaltiya helped though out the process with John Deere and her commitment to help was beyond anyone’s expectation. Very glad to get that kind of service from the consultancy. Keep you the good work",
    name: "Kumud Singh",
    designation: "Senior Engineer I",
  },
  {
    id: "4",
    image: chinmaya,
    content:
      "It was a smooth experience with Exotalent CS team. Huge thanks to Daniel Sibiyal for quick clarification on queries & helped me to understand recruitment process better. Thanks again.",
    name: "Chinmay Deo",
    designation: "Senior Engineer I",
  },
  {
    id: "5",
    image: vaibhav,
    content:
      "ExoTalent landed me onto best Product Based Organisation. Eventually the HR team of ExoTalent are really helpfull in solving small doubts too.",
    name: "Vaibhav Shinde",
    designation: "Senior Engineer II",
  },
  {
    id: "6",
    image: hriday,
    content:
      "Had a fluid on-boarding experience thanks to Charul Sharma. Very delighted to be associated with Exotalent.",
    name: "Hriday Tejwani",
    designation: "Technical Information Specialist II",
  },
  {
    id: "7",
    image: dhana,
    content:
      "Interview Process and everything was very smooth . Shivanshu was there at every step to clarify my questions with the hiring manager.",
    name: "Dhanyata Kancharla",
    designation: "Lead Engineer I",
  },
  {
    id: "8",
    image: prasad,
    content:
      "Thank you Exo Talent for the job. Every helped me and thanks Shivanshu for all the process for my onboarding",
    name: "Prasad Bhagwat",
    designation: "Technical Information Specialist",
  },
  {
    id: "9",
    image: girish,
    content:
      "ExoTalent helped me to get a job in reputed company. Made good coordination with me and the company while interview process. Team is very strong in follow-up and very helpful. I will recommend my friends about this consultancy.",
    name: "Girish Chute",
    designation: "Senior Engineer I",
  },
  {
    id: "10",
    image: sanket,
    content:
      "I'm writing this review after getting placed at one of the most well known companies and best places to work by ExoTalent Consultancy Services. The consultants are very helpful and very professional in the placement and recruitment activities. I would like to thank the consultants for my successful placement especially Amita Singh for her helpful approach and smooth transition throughout the process. I'm really very happy with the placement services provided by the firm. I wish all the very best to Amita and ExoTalent for the future. Thank you very much! Keep up the good work.",
    name: "Sanket Tuplondhe",
    designation: "Senior Engineer - Routed Systems",
  },
  {
    id: "11",
    image: amit,
    content:
      "Amrit did his job great helped me throughout the process from starting till the day i joined he used to take care of all the necessary details required. Thank you Amrit for your support and help",
    name: "Amit Wadekar",
    designation: "Senior Engineer II",
  },
  {
    id: "12",
    image: swapnil,
    content:
      "Pretty Good consultant/ recruiter. All the staff provided a great help during all rounds of interviews. Efficient in follow ups with client recruitment and gave a good feedback on understanding the process throughout. I would give a 5 star for Quick response and clear communication along with sending gentle reminders throughout the process. Nakiya and Pooja helped me and were very efficient in delivering any workarounds!",
    name: "Swapnil Bagul",
    designation: "Lead Engineer II",
  },
  {
    id: "13",
    image: aditiJ,
    content:
      "New job search n joining is very stressful process but thanks to Anupama & team, my process was smooth and ontime.",
    name: "Aditi Joshi Potkar",
    designation: "Manager MFG Engineer",
  },
  {
    id: "14",
    image: prakash,
    content:
      "It was really great experience with Exotalent. I would like to thanks Varsha Nayak, Bhagyashree, Shruti and Richa madam for your support and encouragement from the time of scheduling interview till joining date. I really like the way you all approaching to candidate and providing all requir support at any time.Thank you all once again.",
    name: "Prakash Vaniya",
    designation: "Senior Engineer I",
  },
  {
    id: "15",
    image: umang,
    content:
      "Amazing end to end support provided. I was in contact with Puja Baranwal, she has always available for any query i had. Right from the 1st interview till the joining the management is amazing. They will not only guide you but also support you in many ways and make your journey as comfortable as possible!",
    name: "Umang Mahant",
    designation: "Data Wrangler I",
  },
  {
    id: "16",
    image: nitya,
    content: "Smooth processes and queries were handled clearly",
    name: "Nitya  Phulphagar",
    designation: "Sr. Executive Service",
  },

  {
    id: "20",
    image: aditiA,
    content:
      "Dui faucibus in ornare quam viverra orci sagittis eu. Nisl purus in mollis nunc sed id semper risus. Amet com modo nulla facilisi nullam vehicula",
    name: "Aditi Agarwal",
    designation: "Manager F&A",
  },
  {
    id: "18",
    image: shiva,
    content: "excellent support and very well coordinated",
    name: "Shiva Kumar",
    designation: "Specialist",
  },

  {
    id: "21",
    image: sChandra,
    content:
      "I'm sending this thank you note for the fantastic job opportunity you provided me for my career. Thank you for recognizing my skills and abilities and get a right path and following up about my concerns till joining from interview.",
    name: "S. Chandrakumar",
    designation: "Sr. Engineer",
  },
  {
    id: "22",
    image: isha,
    content:
      "ExoTalent referred me to a position in a reputable company. They helped schedule the interviews and answer my questions throughout the process. They consulted me till the very end and I was able to get my dream position. I would like to thank the team, especially Seema ma'am for her help and support!",
    name: "Isha Patil",
    designation: "Quantitative Analyst",
  },
  {
    id: "23",
    image: rakesh,
    content:
      "ExoTalent referred me to a reputable company. They helped schedule the interviews. They are well organised. They will response at any time you ping them regarding interview status. Thanks Exotalent",
    name: "Rakesh Kumar Barik",
    designation: " Sr. Software Test Engineer",
  },
  {
    id: "24",
    image: divya,
    content:
      "First I would like to say thanks to Exo Talent team for referring me the Job vacancy and being with me from the start of interviews till onboarding. I would specially thanks to Saraswati, who supported me right from profile shortlist to Onboarding. She always attended all my queries and got them resolved on time. I Wish her and ExoTalent team all the best wishes for their on boarding efforts. Thanks...",
    name: "Divyani Nalawade",
    designation: " Sr. Engineer",
  },
  {
    id: "25",
    image: soorya,
    content:
      "Exo talent consultancy was very helpful in getting me a position in my current organization. Their way of interacting with me till the on boarding  process was very organized, simple and was very friendly especially",
    name: "Sooryakrishnan.U",
    designation: "Specialist",
  },
  {
    id: "26",
    image: parag,
    content:
      "Dui faucibus in ornare quam viverra orci sagittis eu. Nisl purus in mollis nunc sed id semper risus. Amet com modo nulla facilisi nullam vehicula",
    name: "Parag Gupta",
    designation: "Sales Manager",
  },
];

const OPTIONS = { slidesToScroll: "auto" };

const HomePage = () => {
  const location = useLocation();
  const navigate = useNavigate();

  const userId = useAppSelector((state) => state?.login?.session?.userId);
  const who = useAppSelector((state) => state?.login?.session?.who);

  useEffect(() => {
    if (userId) {
      if (who === constants.COMPANY.toLowerCase()) {
        navigate("/company-admin");
      }
      if (who === constants.COMPANY_MEMBER.toLowerCase()) {
        navigate("/member");
      }
      if (who === constants.USER.toLowerCase()) {
        navigate("/applied-jobs");
      }
      if (who === constants.RECRUITER.toLowerCase()) {
        navigate("/recruiter-dashboard");
      }
    }
  }, [userId, who]);

  useEffect(() => {
    // Check if there is a hash in the URL and scroll to that section
    if (location.hash) {
      if (location.hash.substring(1) === "about") {
        scroller.scrollTo(location.hash.substring(1), {
          smooth: true,
          duration: 500,
          offset: -250,
        });
      } else if (location.hash.substring(1) === "services") {
        scroller.scrollTo(location.hash.substring(1), {
          smooth: true,
          duration: 500,
          offset: -10,
        });
      } else if (location.hash.substring(1) === "industry") {
        scroller.scrollTo(location.hash.substring(1), {
          smooth: true,
          duration: 500,
          offset: 50,
        });
      } else if (location.hash.substring(1) === "client") {
        scroller.scrollTo(location.hash.substring(1), {
          smooth: true,
          duration: 500,
          offset: 50,
        });
      }
    }
  }, [location]);

  return (
    <>
      <div className="min-h-screenheight w-full bg-homeBG bg-cover bg-center bg-no-repeat py-7">
        <div className="w-[95%] mx-auto space-y-10">
          <div className="h-screen pt-20">
            <div className="w-[40%] space-y-2" id="search">
              <h1 className="text-4xl text-expurple-800 font-semibold text-start max-w-xl tracking-wide">
                AI-Enabled Recruitment Solutions to suit all your Business Needs
              </h1>

              <div className="bg-gradient-to-b from-[#FAFBFF] to-expurple-100 h-full rounded-lg py-7 px-4 space-y-2 border border-exgray-100/70 shadow-lg shadow-exgray-200">
                <JobSearchForm />
              </div>
            </div>
          </div>

          <div className="py-10">
            <div
              className="bg-aboutUsBG bg-cover bg-center bg-no-repeat p-7 mx-auto rounded-xl flex items-center justify-center"
              id="about"
            >
              <div className="flex-1"></div>
              <div className="flex-1">
                <h1 className="text-3xl text-start text-golden font-semibold">
                  About Us
                </h1>
                <p className="text-xl text-exwhite-100 text-start">
                  One of the top recruitment and RPO firms in India that
                  provides remarkable and unmatched recruitment process
                  outsourcing and Talent Acquisition services across various
                  business verticals. Backed by decades of experience, our team
                  is comprised of agile experts and consultants with a strong
                  focus on Automotive, Manufacturing, and Technology. We believe
                  in developing a full suite of technology-driven Talent
                  Acquisition solutions, powered by a diversity-rich network of
                  top talents who deliver tailor-made recruitment solutions as
                  per the client's needs.
                </p>
              </div>
            </div>
          </div>

          <div className="py-8" id="services">
            <div className="space-y-5">
              <div className="space-y-2">
                <h1 className="text-4xl text-expurple-800 font-semibold text-center tracking-wide">
                  Services Offered
                </h1>

                <div className="flex items-center justify-center gap-2">
                  <div className="flex-1 bg-servicesBg_1 bg-center bg-no-repeat bg-cover p-4 space-y-2 self-stretch">
                    <h1 className="text-2xl text-expurple-800 font-semibold text-center">
                      Recruitment
                    </h1>
                    <ul className="list-disc ml-4 text-expurple-600 text-lg">
                      <li>End to End Staffing</li>
                      <li>Permanent</li>
                      <li>Contractual</li>
                      <li>Campus hiring</li>
                    </ul>
                  </div>

                  <div className="flex-1 bg-servicesBg_1 bg-center bg-no-repeat bg-cover p-4 space-y-2 self-stretch">
                    <h1 className="text-2xl text-expurple-800 font-semibold text-center">
                      RPO
                    </h1>
                    <ul className="list-disc ml-4 text-expurple-600 text-lg">
                      <li>
                        Deep dive services in high volume/strategic recruitment
                      </li>
                      <li>Cost Effective</li>
                      <li>Time Effective</li>
                      <li>Process Effective Consulting</li>
                    </ul>
                  </div>

                  <div className="flex-1 bg-servicesBg_1 bg-center bg-no-repeat bg-cover p-4 space-y-2 self-stretch">
                    <h1 className="text-2xl text-expurple-800 font-semibold text-center">
                      HRM Consulting
                    </h1>
                    <ul className="list-disc ml-4 text-expurple-600 text-lg">
                      <li>
                        Deep dive services in high volume/strategic recruitment
                      </li>
                      <li>Org restructuring</li>
                      <li>Policy restructuring</li>
                      <li>Compensation benchmarking</li>
                      <li>Competitor analysis</li>
                    </ul>
                  </div>
                </div>
              </div>

              <div className="space-y-2">
                <h1 className="text-4xl text-expurple-800 font-semibold text-center tracking-wide">
                  Key differentiators
                </h1>

                <div className="flex items-center justify-center gap-2">
                  <div className="flex-1 bg-servicesBg_1 bg-center bg-no-repeat bg-cover px-4 py-10 space-y-2 self-stretch">
                    <h1 className="text-2xl text-expurple-800 font-semibold text-center">
                      AI enabled screening platform
                    </h1>
                    <p className="text-expurple-600 text-lg">
                      Our AI enabled platform helps to shortlist the ideal
                      candidates, as well as automate manual tasks in the
                      recruitment process.
                    </p>
                  </div>

                  <div className="flex-1 bg-servicesBg_1 bg-center bg-no-repeat bg-cover px-4 py-10 space-y-2 self-stretch">
                    <h1 className="text-2xl text-expurple-800 font-semibold text-center">
                      Team of industry experts
                    </h1>
                    <p className="text-expurple-600 text-lg">
                      Our team is comprised of industry experts who are
                      passionate about their work, have excelled in their field,
                      and have an extensive background of over 20+ years of
                      industry exposure.
                    </p>
                  </div>

                  <div className="flex-1 bg-servicesBg_1 bg-center bg-no-repeat bg-cover px-4 py-10 space-y-2 self-stretch">
                    <h1 className="text-2xl text-expurple-800 font-semibold text-center">
                      Candidate management
                    </h1>
                    <p className="text-expurple-600 text-lg">
                      Our candidate management teams help in building and
                      maintaining a healthy relationship with candidates, and
                      this helps to reduce the decline ratio.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div
        className="py-20 bg-industryVerticalsBG bg-center bg-cover bg-no-repeat space-y-2"
        id="industry"
      >
        <h1 className="text-3xl text-expurple-800 text-center font-semibold">
          Industry Verticals
        </h1>

        <div className="w-[95%] mx-auto flex flex-wrap justify-center gap-2">
          <div className="p-8">
            <img
              src={AutomotiveImage}
              alt={"AutomotiveImage"}
              className="max-w-[80%]"
            />
            <h1 className="text-2xl text-expurple-800 font-semibold text-center">
              Automotive
            </h1>
          </div>
          <div className="p-8">
            <img
              src={DigitalImage}
              alt={"DigitalImage"}
              className="max-w-[80%]"
            />
            <h1 className="text-2xl text-expurple-800 font-semibold text-center">
              Digital
            </h1>
          </div>
          <div className="p-8">
            <img
              src={IndustrialImage}
              alt={"AutomotiveImage"}
              className="max-w-[80%]"
            />
            <h1 className="text-2xl text-expurple-800 font-semibold text-center">
              Industrial
            </h1>
          </div>
          <div className="p-8">
            <img
              src={ChemicalIndustryImage}
              alt={"AutomotiveImage"}
              className="max-w-[80%]"
            />
            <h1 className="text-2xl text-expurple-800 font-semibold text-center">
              Chemical Industry
            </h1>
          </div>
          <div className="p-8">
            <img
              src={InfrastructurePowerImage}
              alt={"AutomotiveImage"}
              className="max-w-[80%]"
            />
            <h1 className="text-2xl text-expurple-800 font-semibold text-center">
              Infrastructure & Power
            </h1>
          </div>
          <div className="p-8">
            <img
              src={FinancialServicesImage}
              alt={"AutomotiveImage"}
              className="max-w-[80%]"
            />
            <h1 className="text-2xl text-expurple-800 font-semibold text-center">
              Financial Services
            </h1>
          </div>
          <div className="p-8">
            <img
              src={HealthcareImage}
              alt={"AutomotiveImage"}
              className="max-w-[80%]"
            />
            <h1 className="text-2xl text-expurple-800 font-semibold text-center">
              Healthcare
            </h1>
          </div>
        </div>
      </div>

      <div className="bg-statsBG bg-cover bg-center bg-no-repeat px-7 py-20 flex items-center justify-center gap-2">
        <div className="text-center flex-1 self-start">
          <h1 className="text-3xl text-golden font-semibold">48 hrs</h1>
          <p className="text-xl text-exwhite-100 font-semibold">Average TAT</p>
        </div>
        <div className="text-center flex-1 self-start">
          <h1 className="text-3xl text-golden font-semibold">100+</h1>
          <p className="text-xl text-exwhite-100 font-semibold">
            Active Clients
          </p>
        </div>
        <div className="text-center flex-1 self-start">
          <h1 className="text-3xl text-golden font-semibold">Above 80%</h1>
          <p className="text-xl text-exwhite-100 font-semibold">
            Profile Relevancy
          </p>
        </div>
        <div className="text-center flex-1 self-start">
          <h1 className="text-3xl text-golden font-semibold">Above 30%</h1>
          <p className="text-xl text-exwhite-100 font-semibold">
            Diversity Ratio
          </p>
        </div>
        <div className="text-center flex-1 self-start">
          <h1 className="text-3xl text-golden font-semibold">5000+</h1>
          <p className="text-xl text-exwhite-100 font-semibold max-w-52">
            Total Candidate's Places
          </p>
        </div>
        <div className="text-center flex-1 self-start">
          <h1 className="text-3xl text-golden font-semibold">15 Days</h1>
          <p className="text-xl text-exwhite-100 font-semibold max-w-52">
            Average TAT for Selection
          </p>
        </div>
      </div>

      <div className="bg-expurple-800 py-28 space-y-7" id="client">
        <h1 className="text-center text-4xl text-golden font-bold">
          Some of our highly reputed clients who swear by our services
        </h1>

        <div className="w-[95%] mx-auto space-y-10">
          <div className="overflow-hidden whitespace-nowrap relative">
            <motion.div
              className="flex gap-7"
              animate={{ x: ["0%", "-100%"] }}
              transition={{ duration: 20, repeat: Infinity, ease: "linear" }}
              style={{ display: "flex" }}
            >
              {[...ITEMS, ...ITEMS].map((ele, index) => (
                <img
                  key={uuidV4()}
                  src={ele.image}
                  alt="asd"
                  className="max-w-28 object-cover mx-4"
                />
              ))}
            </motion.div>
          </div>

          <div className="overflow-hidden whitespace-nowrap relative">
            <motion.div
              className="flex gap-4"
              animate={{ x: ["-100%", "0%"] }}
              transition={{ duration: 20, repeat: Infinity, ease: "linear" }}
              style={{ display: "flex" }}
            >
              {[...ITMES2, ...ITMES2].map((ele, index) => (
                <img
                  key={uuidV4()}
                  src={ele.image}
                  alt="asd"
                  className="max-w-20 object-cover mx-4"
                />
              ))}
            </motion.div>
          </div>

          <div className="overflow-hidden whitespace-nowrap relative">
            <motion.div
              className="flex gap-2"
              animate={{ x: ["0%", "-100%"] }}
              transition={{ duration: 20, repeat: Infinity, ease: "linear" }}
              style={{ display: "flex" }}
            >
              {[...ITEMS3, ...ITEMS3].map((ele, index) => (
                <img
                  key={uuidV4()}
                  src={ele.image}
                  alt="asd"
                  className="max-w-28 object-cover mx-4"
                />
              ))}
            </motion.div>
          </div>
        </div>
      </div>

      <div className="bg-clientTestimonialsBG bg-cover bg-center bg-no-repeat py-20 space-y-4">
        <div className="w-[95%] mx-auto">
          <h1 className="text-center text-4xl text-expurple-800 font-bold">
            What our clients have to say
          </h1>
          <EmblaCarousel slides={clientTestimonial} options={OPTIONS} />
        </div>
      </div>

      <div className="bg-candidateTestimonialsBG bg-cover bg-center bg-no-repeat py-20 space-y-4">
        <div className="w-[95%] mx-auto">
          <h1 className="text-center text-4xl text-expurple-800 font-bold">
            What our candidates have to say
          </h1>
          <EmblaCarousel slides={candidatesTestimonial} options={OPTIONS} />
        </div>
      </div>

      <div className="bg-expurple-800 py-8 sapce-y-4">
        <h1 className="text-4xl text-exwhite-100 font-bold text-center">
          Contact Us
        </h1>

        <div className="w-[95%] mx-auto ">
          <div className="flex items-center justify-center gap-6 border-b-2 border-b-exwhite-100 pb-20">
            {/* <div className="flex-1 text-start space-y-1 self-start">
              <h1 className="text-2xl text-golden font-semibold">Mumbai</h1>
              <p className="text-lg text-exwhite-100 font-semibold">
                211, 2nd Floor, B Wing, Western Edge II, Behind Metro Super
                Market, Near Magathane, Borivali, Mumbai, Maharashtra - 400066.
              </p>
              <p className="text-lg text-exwhite-100 font-semibold">
                Phone: +91 9930408319
              </p>
            </div> */}

            <div className="flex-1 text-start space-y-1 self-start">
              <h1 className="text-2xl text-golden font-semibold">
                Navi Mumbai
              </h1>
              <p className="text-lg text-exwhite-100 font-semibold">
                AWFIS , 2nd Floor, Raghuleela, Arcade Vishwaroop, Infotech Pvt.
                Ltd., Plot No-34, 35, 38, Navi Mumbai, Maharashtra - 400703
              </p>
              <p className="text-lg text-exwhite-100 font-semibold">
                Phone: +91 9137779517
              </p>
            </div>

            <div className="flex-1 text-start space-y-1 self-start">
              <h1 className="text-2xl text-golden font-semibold">Pune</h1>
              <p className="text-lg text-exwhite-100 font-semibold">
                Solitaire Business Hub, Unit no. 6070B, H Wing, opp. NECO Garden
                Society, Clover Park, Viman Nagar, Pune, Maharashtra - 411014
              </p>
              <p className="text-lg text-exwhite-100 font-semibold">
                Phone: +91 9930408319
              </p>
            </div>

            <div className="flex-1 text-start space-y-1 self-start">
              <h1 className="text-2xl text-golden font-semibold">Bengaluru</h1>
              <p className="text-lg text-exwhite-100 font-semibold">
                133/1, 4th Floor, Yashkesh Tower, 10th Main, 14th Cross, 6th
                Sector, HSR Layout, Bengaluru Karnataka - 560102.
              </p>
              <p className="text-lg text-exwhite-100 font-semibold">
                Phone: +91 9513007253
              </p>
            </div>

            <div className="flex-1 text-start space-y-1 self-start">
              <h1 className="text-2xl text-golden font-semibold">Delhi/NCR</h1>
              <p className="text-lg text-exwhite-100 font-semibold">
                Awfis Majestic Signia, 1st Floor, Majestic Signia, Plot No.
                A-27, Block A, Industrial Area, Sector 62, Noida, Uttar Pradesh
                - 201309
              </p>
              <p className="text-lg text-exwhite-100 font-semibold">
                Phone: +91 9871311206
              </p>
            </div>

            <div className="flex-1 text-start space-y-1 self-start">
              <h1 className="text-2xl text-golden font-semibold">Email</h1>
              <p className="text-lg text-exwhite-100 font-semibold">
                info@exotalent.in
              </p>
              <p className="text-lg text-exwhite-100 font-semibold">
                career@exotalent.in
              </p>
            </div>
          </div>

          <div className="text-lg text-exwhite-100 py-4 space-x-2 divide-x-2 divide-exwhite-100">
            <button className="text-center px-2">© 2022 exotalent.in </button>
            <button className="text-center px-2">All Rights Reserved </button>
            <button className="text-center px-2 underline leading-7">
              Privacy Policy
            </button>
            <button className="text-center px-2 underline leading-7">
              Terms Of Use
            </button>
          </div>
        </div>
      </div>
    </>
  );
};

export default HomePage;
