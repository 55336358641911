import React, { useEffect, useMemo, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import ExInput from "../../../components/basic-components/ExInput";
import ExTextarea from "../../../components/basic-components/ExTextarea";
import CustomDatePicker from "../../../components/basic-components/CustomDatePicker";
import moment from "moment/moment";
import { useAppDispatch, useAppSelector } from "../../../config/hooks";
import {
  addExperienceApi,
  updateExperienceApi,
} from "../../../slices/candidateProfileSlice";
import ExCheckBox from "../../../components/basic-components/ExCheckBox";
import ExDropdown from "../../../components/basic-components/ExDropdown";
import { unwrapResult } from "@reduxjs/toolkit";
import useJobTypes from "../../../utils/dropdown-options/useJobTypes";
import { toast } from "sonner";

const ExperienceForm = ({ onClose = () => {}, editExp = null }) => {
  const dispatch = useAppDispatch();
  const userId = useAppSelector((state) => state.login.session.userId);

  const { jobTypesArray, defaultJobType } = useJobTypes();

  const [isLoading, setIsLoading] = useState(false);

  let selectedJobType;

  if (editExp?.userExperienceDetails[0]?.employmentType)
    selectedJobType = editExp?.userExperienceDetails[0]?.employmentType;

  const defautlJobTypeOption = useMemo(() => {
    if (editExp?.userExperienceDetails[0]?.employmentType)
      return defaultJobType(editExp?.userExperienceDetails[0]?.employmentType);
    else return null;
  }, [selectedJobType]);

  const form = useForm({
    defaultValues: {
      desgnation: editExp?.userExperienceDetails[0]?.title
        ? editExp?.userExperienceDetails[0]?.title
        : "",
      companyName: editExp ? editExp.organization : "",
      startDate:
        editExp?.userExperienceDetails[0]?.startDate &&
        editExp?.userExperienceDetails[0]?.startDate.month &&
        editExp?.userExperienceDetails[0]?.startDate.year
          ? moment(
              `${editExp?.userExperienceDetails[0]?.startDate.month} ${editExp?.userExperienceDetails[0]?.startDate.year}`
            ).format()
          : "",
      endDate:
        editExp?.userExperienceDetails[0]?.endDate &&
        editExp?.userExperienceDetails[0]?.endDate.month &&
        editExp?.userExperienceDetails[0]?.endDate.year
          ? moment(
              `${editExp?.userExperienceDetails[0]?.endDate.month} ${editExp?.userExperienceDetails[0]?.endDate.year}`
            ).format()
          : "",
      location: editExp?.userExperienceDetails[0]?.location
        ? editExp?.userExperienceDetails[0]?.location
        : "",
      skill: "",
      summery: editExp?.userExperienceDetails[0]?.description
        ? editExp?.userExperienceDetails[0]?.description
        : "",
      employmentType: defautlJobTypeOption,
      isCurrentlyworking: editExp?.userExperienceDetails[0]?.isCurrentRole
        ? editExp?.userExperienceDetails[0]?.isCurrentRole
        : false,
    },
  });

  const { handleSubmit, control, watch, formState, setValue } = form;
  const { errors } = formState;

  const currentWorkingStatus = watch("isCurrentlyworking");

  useEffect(() => {
    if (currentWorkingStatus) {
      setValue("endDate", "");
    }
  }, [currentWorkingStatus]);

  const onSubmit = async (formData) => {
    setIsLoading(true);
    const startDateMonth = moment(formData.startDate).format("MMMM");
    const startDateYear = moment(formData.startDate).format("YYYY");
    const endDateMonth = formData.isCurrentlyworking
      ? moment().format("MMMM")
      : moment(formData.endDate).format("MMMM");
    const endDateYear = formData.isCurrentlyworking
      ? moment().format("YYYY")
      : moment(formData.endDate).format("YYYY");
    const employmentType = formData?.employmentType?.Id;

    if (!editExp?.id) {
      try {
        await dispatch(
          addExperienceApi({
            userId: userId,
            organization: formData.companyName,
            organizationId: "",
            userExperienceDetails: [
              {
                location: formData.location,
                locationId: "",
                locationType: "",
                employmentType: employmentType.toString(),
                title: formData.desgnation,
                description: formData.summery,
                startDate: {
                  month: startDateMonth,
                  year: startDateYear,
                },
                endDate: {
                  month: endDateMonth,
                  year: endDateYear,
                },
                isCurrentRole: formData.isCurrentlyworking,
                skills: ["Java", "Software"],
              },
            ],
          })
        ).then(unwrapResult);
        setIsLoading(false);
        toast.success("Experience added !");
        onClose();
      } catch (error) {
        toast.error("Something went wrong");
        setIsLoading(false);
        onClose();
      }
    } else {
      try {
        await dispatch(
          updateExperienceApi({
            userId: userId,
            experienceId: editExp.id,
            organization: formData.companyName,
            organizationId: "",
            userExperienceDetails: [
              {
                location: formData.location,
                locationId: "",
                locationType: "",
                employmentType: employmentType.toString(),
                title: formData.desgnation,
                description: formData.summery,
                startDate: {
                  month: startDateMonth,
                  year: startDateYear,
                },
                endDate: {
                  month: endDateMonth,
                  year: endDateYear,
                },
                isCurrentRole: formData.isCurrentlyworking,
                skills: ["Java", "Software"],
              },
            ],
          })
        ).then(unwrapResult);
        setIsLoading(false);
        toast.success("Updated successfully!");
        onClose();
      } catch (error) {
        toast.error("Something went wrong");
        setIsLoading(false);
        onClose();
      }
    }
  };

  return (
    <div className="w-[50%] max-h-[90%] bg-expurple-200 p-10 rounded-xl overflow-y-auto no-scrollbar space-y-2">
      <div>
        <h1 className="text-3xl text-expurple-800 font-semibold text-start">
          Experience
        </h1>
      </div>
      <form onSubmit={handleSubmit(onSubmit)} className="space-y-2">
        <div>
          <Controller
            name="desgnation"
            control={control}
            rules={{
              required: {
                value: true,
                message: "Designantion is required",
              },
            }}
            render={({ field: { onChange, value } }) => (
              <ExInput
                placeholder="Ex. React js developer"
                title="Designation *"
                name="desgnation"
                value={value || ""}
                onChange={onChange}
                error={errors?.desgnation?.message}
              />
            )}
          />
        </div>

        <div>
          <Controller
            name="employmentType"
            control={control}
            rules={{
              required: {
                value: true,
                message: "Employment Type is required",
              },
            }}
            render={({ field: { onChange, value } }) => (
              <ExDropdown
                title="Employment Type *"
                onChange={onChange}
                placeholder="Select"
                value={value || null}
                optionValue="Id"
                optionLabel="Title"
                options={jobTypesArray}
                dropDownHeight="40px"
                dropDownBorderWidth="0px"
                dropDownBorderColor="#696969"
                error={errors?.employmentType?.message}
              />
            )}
          />
        </div>

        <div>
          <Controller
            name="companyName"
            control={control}
            rules={{
              required: {
                value: true,
                message: "Company name is required",
              },
            }}
            render={({ field: { onChange, value } }) => (
              <ExInput
                placeholder="Ex. Microsoft"
                title="Company Name *"
                name="companyName"
                value={value || ""}
                onChange={onChange}
                error={errors?.companyName?.message}
              />
            )}
          />
        </div>

        <div>
          <Controller
            name="location"
            control={control}
            rules={{
              required: {
                value: true,
                message: "Location is required",
              },
            }}
            render={({ field: { onChange, value } }) => (
              <ExInput
                placeholder="Ex. Chicago"
                title="Location *"
                name="location"
                value={value || ""}
                onChange={onChange}
                error={errors?.location?.message}
              />
            )}
          />
        </div>

        <div>
          <Controller
            name="isCurrentlyworking"
            control={control}
            render={({ field: { onChange, value } }) => (
              <ExCheckBox
                title="I am currently working in this role"
                name="isCurrentlyworking"
                onChange={onChange}
                checked={value}
                value={value}
              />
            )}
          />
        </div>

        <div className="flex justify-between items-center gap-6">
          <div className="w-full self-start">
            <Controller
              name="startDate"
              control={control}
              rules={{
                required: {
                  value: true,
                  message: "Start date is required",
                },
              }}
              render={({ field: { onChange, value } }) => (
                <CustomDatePicker
                  title="Start date *"
                  name="startDate"
                  onChange={onChange}
                  selected={value ? new Date(value) : null}
                  maxDate={new Date()}
                  placeholderText="Ex. mm/yy"
                  popperPlacement="bottom-end"
                  dateFormat="MMMM yyyy"
                  showMonthYearPicker={true}
                  error={errors?.startDate?.message}
                />
              )}
            />
          </div>
          <div className="w-full self-start">
            <Controller
              name="endDate"
              control={control}
              rules={{
                required: {
                  value: !currentWorkingStatus,
                  message: "End date is required",
                },
              }}
              render={({ field: { onChange, value } }) => (
                <CustomDatePicker
                  title="End date *"
                  name="endDate"
                  onChange={onChange}
                  selected={value ? new Date(value) : null}
                  maxDate={new Date()}
                  minDate={new Date(watch("startDate"))}
                  placeholderText="Ex. mm/yy"
                  popperPlacement="bottom-end"
                  dateFormat="MMMM yyyy"
                  disabled={currentWorkingStatus || !watch("startDate")}
                  showMonthYearPicker={true}
                  error={!currentWorkingStatus && errors?.endDate?.message}
                />
              )}
            />
          </div>
        </div>

        {/* <div>
          <Controller
            name="skill"
            control={control}
            rules={{
              required: {
                value: true,
                message: "Skill is required",
              },
            }}
            render={({ field: { onChange, value } }) => (
              <ExInput
                placeholder="Ex Java"
                title="Skills *"
                name="skill"
                value={value || ""}
                onChange={onChange}
                error={errors?.skill?.message}
              />
            )}
          />
        </div> */}

        <div>
          <Controller
            name="summery"
            control={control}
            rules={{
              required: {
                value: true,
                message: "Summary is required",
              },
              maxLength: {
                value: 2600,
                message: "Only 2600 characters are allowed",
              },
            }}
            render={({ field: { onChange, value } }) => (
              <ExTextarea
                rows={8}
                name="summery"
                value={value || ""}
                title="Summary *"
                maxLength={2600}
                onChange={onChange}
                placeholder="This information will be seen by the candidate in job discription."
                error={errors?.summery?.message}
              />
            )}
          />
        </div>

        <div>
          <div className="w-fit ml-auto mr-0">
            <button
              className={`text-base  text-center px-8 py-2 ${
                isLoading
                  ? "bg-exgray-400/50 text-exwhite-100"
                  : "bg-expurple-800 text-golden"
              } rounded-lg`}
              disabled={isLoading}
            >
              Save
            </button>
          </div>
        </div>
      </form>
    </div>
  );
};

export default ExperienceForm;
