import React, { useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import SearchIcon from "../../assets/Icons/SearchIcon";
import { useAppDispatch, useAppSelector } from "../../config/hooks";
import {
  getCandidatesApi,
  getCandidatesByUrlApi,
} from "../../slices/referSlice";
import { unwrapResult } from "@reduxjs/toolkit";

import LoadingIcon from "../../assets/Icons/LoadingIcon";
import { v4 as uuidV4 } from "uuid";
import * as constants from "../../constant";
import InfiniteScroll from "react-infinite-scroll-component";
import NoDataFoundIcon from "../../assets/Icons/NoDataFoundIcon";
import BundleUploadUserCard from "./components/BundleUploadUserCard";
import { toast } from "sonner";

const JobSeekers = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const searchRef = useRef(null);

  // const [searchTerm, setSearchTerm] = useState("");

  const loginSelector = useAppSelector((state) => state?.login?.session);
  const memberSelector = useAppSelector(
    (state) => state?.member?.memberDetails
  );

  const candidateListSelector = useAppSelector(
    (state) => state?.refer?.candidateList?.candidates
  );

  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    const fetchData = async () => {
      setIsLoading(true);
      try {
        await dispatch(
          getCandidatesApi({
            authorId:
              loginSelector.who === constants.COMPANY.toLowerCase()
                ? loginSelector.userId
                : loginSelector.who === constants.COMPANY_MEMBER.toLowerCase()
                ? memberSelector.companyId
                : loginSelector.userId,
          })
        ).then(unwrapResult);
        setIsLoading(false);
      } catch (error) {
        setIsLoading(false);
      }
    };

    fetchData();
  }, []);

  const navigationHandler = (path) => {
    navigate(path);
  };

  const onChangeSearchHandler = (e) => {
    if (!e.target.value) {
      const fetchData = async () => {
        setIsLoading(true);
        try {
          await dispatch(
            getCandidatesApi({
              authorId:
                loginSelector.who === constants.COMPANY.toLowerCase()
                  ? loginSelector.userId
                  : loginSelector.who === constants.COMPANY_MEMBER.toLowerCase()
                  ? memberSelector.companyId
                  : loginSelector.userId,
            })
          ).then(unwrapResult);
          setIsLoading(false);
        } catch (error) {
          setIsLoading(false);
        }
      };

      fetchData();
    }
  };

  const searchCandidateHandler = async () => {
    if (searchRef.current) {
      setIsLoading(true);
      try {
        await dispatch(
          getCandidatesApi({
            authorId:
              loginSelector.who === constants.COMPANY.toLowerCase()
                ? loginSelector.userId
                : loginSelector.who === constants.COMPANY_MEMBER.toLowerCase()
                ? memberSelector.companyId
                : loginSelector.userId,
            s: searchRef.current.value,
          })
        ).then(unwrapResult);
        setIsLoading(false);
      } catch (error) {
        setIsLoading(false);
      }
    }
  };

  const getMoreDataHandler = () => {
    try {
      dispatch(
        getCandidatesByUrlApi({
          authorId:
            loginSelector.who === constants.COMPANY.toLowerCase()
              ? loginSelector.userId
              : loginSelector.who === constants.COMPANY_MEMBER.toLowerCase()
              ? memberSelector.companyId
              : loginSelector.userId,
        })
      );
    } catch (error) {
      toast.error("Unable to fetch data");
    }
  };

  return (
    <div className="w-[95%] mx-auto py-7">
      <div className="py-4 flex items-center justify-between">
        <p className="text-expurple-800 text-lg font-semibold">{`${
          candidateListSelector?.metadata?.totalCount
            ? candidateListSelector?.metadata?.totalCount
            : 0
        } Job Seekers`}</p>
        <div>
          <button
            className="bg-transparent px-10 py-2 text-expurple-800 text-base font-semibold"
            onClick={() =>
              navigationHandler(
                loginSelector.who === constants.COMPANY.toLowerCase()
                  ? "/company-admin"
                  : loginSelector.who === constants.COMPANY_MEMBER.toLowerCase()
                  ? "/member"
                  : loginSelector.who === constants.RECRUITER.toLowerCase()
                  ? "/recruiter-dashboard"
                  : ""
              )
            }
          >
            Back
          </button>
          <button
            className="px-6 py-2 text-base text-exwhite-100  rounded-md font-semibold w-fit  bg-expurple-800"
            onClick={() => navigationHandler("/bulk-upload")}
          >
            Create
          </button>
        </div>
      </div>

      <div>
        <div className="w-[40%] relative">
          <input
            type="text"
            ref={searchRef}
            autoComplete="off"
            className="w-full h-12 pl-10 pr-4 bg-expurple-100 rounded-sm outline-none text-expurple-700 placeholder:text-expurple-700 text-base"
            placeholder="Search"
            name="search"
            onChange={onChangeSearchHandler}
          />
          <div className="h-full absolute top-0 flex items-center justify-center left-2">
            <SearchIcon />
          </div>
          <div className="h-full absolute top-0 flex items-center justify-center right-2">
            <button
              className="px-6 py-1 text-exwhite-100 text-base font-semibold text-center bg-expurple-800 rounded-md active:scale-90"
              type="button"
              onClick={searchCandidateHandler}
            >
              Search
            </button>
          </div>
        </div>
      </div>

      <div className="py-2">
        {!isLoading &&
        candidateListSelector?.user &&
        candidateListSelector?.user?.length > 0 ? (
          <InfiniteScroll
            className="no-scrollbar"
            dataLength={
              candidateListSelector?.user?.length
                ? candidateListSelector?.user?.length
                : 0
            }
            next={() => getMoreDataHandler()}
            hasMore={candidateListSelector?.metadata?.nextResultURL}
            // height={"calc(100vh - 155px)"}
            loader={""}
          >
            {candidateListSelector?.user.map((candidateDetails) => (
              <BundleUploadUserCard
                candidateDetails={candidateDetails}
                key={uuidV4()}
              />
            ))}
          </InfiniteScroll>
        ) : candidateListSelector?.user &&
          candidateListSelector?.user?.length === 0 ? (
          <div>
            <div className="flex items-center justify-center pt-20">
              <NoDataFoundIcon />
            </div>
            <div>
              <h1 className="text-3xl text-expurple-800/50 font-semibold text-center">
                No Data Found
              </h1>
              <p className="text-base text-exgray-200 font-medium text-center max-w-sm mx-auto">
                Find new opportunities and manage your candidate search progress
                here.
              </p>
            </div>
          </div>
        ) : (
          <div className="flex items-center justify-center py-20">
            <LoadingIcon />
          </div>
        )}
      </div>
    </div>
  );
};

export default JobSeekers;
