import { combineReducers, configureStore } from "@reduxjs/toolkit";
import { persistStore, persistReducer } from "redux-persist";
import storage from "redux-persist-indexeddb-storage";

import loginReducer from "../slices/loginSlice";
import candidateProfileDetailsReducer from "../slices/candidateProfileSlice";
import appReducer from "../slices/appSlice";
import jobReducer from "../slices/jobSlice";
import companyReducer from "../slices/companySlice";
import companyMemberReducer from "../slices/companyMemberSlice";
import referReducer from "../slices/referSlice";
import recruiterReducer from "../slices/recruiterSlice";

const STORAGE_NAME = "exotalent.app.db";

const persistConfig = {
  key: "root",
  storage: storage(STORAGE_NAME),
  blacklist: ["login", "appConfig", "jobs", "company", "refer"],
};

const loginPersistConfig = {
  storage: storage(STORAGE_NAME),
  key: "login",
  blacklist: ["ui"],
};

const companyPersistConfig = {
  storage: storage(STORAGE_NAME),
  key: "company",
  blacklist: ["createdJobs"],
};

const referPersistConfig = {
  storage: storage(STORAGE_NAME),
  key: "refer",
  blacklist: ["candidateList", "jobReferalList"],
};

const persistedLoginReducer = persistReducer(loginPersistConfig, loginReducer);
const persistedCompanyReducer = persistReducer(
  companyPersistConfig,
  companyReducer
);
const persistedReferReducer = persistReducer(referPersistConfig, referReducer);

const rootReducer = combineReducers({
  login: persistedLoginReducer,
  candidateProfile: candidateProfileDetailsReducer,
  appConfig: appReducer,
  jobs: jobReducer,
  company: persistedCompanyReducer,
  member: companyMemberReducer,
  refer: persistedReferReducer,
  recruiter: recruiterReducer,
});

const persistedReducer = persistReducer(persistConfig, rootReducer);

export const store = configureStore({
  reducer: persistedReducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: false,
    }),
  devTools: process.env.NODE_ENV !== "production",
});

export const clearDatabase = () => {
  storage(STORAGE_NAME).removeItem("persist:app");
};

export const persistor = persistStore(store);

// export type RootState = ReturnType<typeof store.getState>;
// export type AppDispatch = typeof store.dispatch;
