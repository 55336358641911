import apiClient from "../config/axiosConfig";

class ContentService {
  uploadFile(uploadFileData) {
    return apiClient.post(`/v1/exo/contents`, uploadFileData.data, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    });
  }
}

const contentService = new ContentService();
export default contentService;
