import React, { useMemo, useState } from "react";
import Popup from "../../../components/popup/Popup";
import PreferenceForm from "../forms/PreferenceForm";
import { useAppSelector } from "../../../config/hooks";
import useIndustries from "../../../utils/dropdown-options/useIndustries";
import useLanguages from "../../../utils/dropdown-options/useLanguages";
import EditIcon from "../../../assets/Icons/EditIcon";

const PreferenceSection = () => {
  const languageSelector = useAppSelector(
    (state) => state.candidateProfile.userLanguages
  );
  const candidateDetailsSelector = useAppSelector(
    (state) => state.candidateProfile?.userDetails
  );

  const { defaultIndustries } = useIndustries();
  const { defaultLanguages } = useLanguages();

  const [togglePopup, setTogglePopup] = useState(false);

  const industries = useMemo(() => {
    if (
      candidateDetailsSelector?.industry &&
      candidateDetailsSelector?.industry.length > 0
    ) {
      return defaultIndustries(candidateDetailsSelector.industry);
    } else return null;
  }, [candidateDetailsSelector?.industry]);

  const languages = useMemo(() => {
    if (languageSelector && languageSelector?.length > 0) {
      return defaultLanguages(languageSelector);
    } else return [];
  }, [languageSelector]);

  const togglePopupHandler = () => {
    setTogglePopup(!togglePopup);
  };

  const closePopupHandler = () => {
    setTogglePopup(false);
  };

  return (
    <div className="">
      <div className="space-y-2">
        <div className="flex items-center justify-between">
          <h1 className="text-lg text-black text-semibold text-start">
            Preference
          </h1>
          <button
            type="button"
            className="text-sm text-expurple-800 font-medium"
            onClick={togglePopupHandler}
          >
            {languageSelector.length > 0 ||
            (candidateDetailsSelector.industry &&
              candidateDetailsSelector.industry.length > 0) ||
            (candidateDetailsSelector.interest &&
              candidateDetailsSelector.interest.length > 0) ? (
              <p className="flex items-center justify-start gap-2">
                <EditIcon color="#223870" />
                Edit Preference
              </p>
            ) : (
              <p>
                <span className="font-bold text-lg">+</span> Add Preference
              </p>
            )}
          </button>
        </div>

        <div className="space-y-1">
          {candidateDetailsSelector.interest &&
            candidateDetailsSelector.interest.length > 0 && (
              <div className="flex items-center justify-start gap-2">
                <p className="text-base text-expurple-800 font-semibold">
                  Interest :
                </p>

                <p className="text-base text-exgray-400 font-semibold">
                  {candidateDetailsSelector.interest.map(
                    (interest, index) =>
                      `${interest}${
                        index === candidateDetailsSelector?.interest?.length - 1
                          ? ""
                          : ", "
                      }`
                  )}
                </p>
              </div>
            )}

          {candidateDetailsSelector.industry &&
            candidateDetailsSelector.industry.length > 0 &&
            industries?.length > 0 && (
              <div className="flex items-center justify-start gap-2">
                <p className="text-base text-expurple-800 font-semibold">
                  Industry :
                </p>

                <p className="text-base text-exgray-400 font-semibold">
                  {industries.map(
                    (ind, index) =>
                      `${ind.Name}${
                        index === industries?.length - 1 ? "" : ", "
                      }`
                  )}
                </p>
              </div>
            )}

          {languageSelector.length > 0 && languages.length > 0 && (
            <div className="flex items-center justify-start gap-2">
              <p className="text-base text-expurple-800 font-semibold">
                Languages :
              </p>

              <p className="text-base text-exgray-400 font-semibold">
                {languages.map(
                  (lan, index) =>
                    `${lan.Title}${index === languages?.length - 1 ? "" : ", "}`
                )}
              </p>
            </div>
          )}
        </div>
      </div>

      <Popup onClose={closePopupHandler} visible={togglePopup}>
        <PreferenceForm onClose={closePopupHandler} />
      </Popup>
    </div>
  );
};

export default PreferenceSection;
