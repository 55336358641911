import React, { useEffect, useRef, useState } from "react";

import { useAppDispatch, useAppSelector } from "../../config/hooks";

import {
  addEducationApi,
  addExperienceApi,
  getCandidateDetailApi,
  updateUserProfileApi,
} from "../../slices/candidateProfileSlice";

import UploadIcon from "../../assets/Icons/UploadIcon";
import PersonalDetails from "./components/PersonalDetails";
import ExperienceSection from "./components/ExperienceSection";
import EducationSection from "./components/EducationSection";
import SkillSection from "./components/SkillSection";

import "../../App.css";
import AdditionalInformationSection from "./components/AdditionalInformationSection";
import uploadContent from "../../utils/upload-content/uploadContent";
import { unwrapResult } from "@reduxjs/toolkit";
import * as constants from "../../constant";
import StandardEditIcon from "../../assets/Icons/StandardEditIcon";
import PreferenceSection from "./components/PreferenceSection";
import { useNavigate } from "react-router-dom";
import { toast } from "sonner";
import LoadingIcon from "../../assets/Icons/LoadingIcon";
import { Controller, useForm } from "react-hook-form";
import PlusCircleIcon from "../../assets/Icons/PlusCircleIcon";
import { getCvDetailsApi } from "../../slices/aiSlice";
import moment from "moment";

const CandidateProfile = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const imageInputRef = useRef(null);
  const submitBtnRef = useRef(null);
  const inputRef = useRef(null);

  const userId = useAppSelector((state) => state.login?.session?.userId);
  const token = useAppSelector((state) => state.login?.session?.token);
  const userDetails = useAppSelector(
    (state) => state.candidateProfile.userDetails
  );
  const apiAsyncData = useAppSelector((state) => state.candidateProfile);

  const [isFileLoading, setIsFileLoading] = useState(false);

  const form = useForm({
    defaultValues: {
      file: {
        name: "",
        url: "",
        contentType: "",
        size: 0,
      },
    },
  });

  const { handleSubmit, control, formState, watch, setValue } = form;
  const { errors } = formState;

  const uploadedFile = watch("file");

  const { isLoading, error } = apiAsyncData;

  useEffect(() => {
    dispatch(getCandidateDetailApi({ userId }));
  }, []);

  const handleClick = () => {
    if (imageInputRef.current) imageInputRef.current.click();
  };

  const savePersonalDetailsHandler = () => {
    if (submitBtnRef.current) submitBtnRef.current.click();
  };

  const navigateHandler = () => {
    navigate("/applied-jobs");
  };

  const triggerInputfileHanlder = () => {
    if (inputRef.current) inputRef.current.click();
  };

  const resumeDetailsHandler = async (uploadContent) => {
    const formData = new FormData();
    formData.append(
      "resumes",
      `${constants.baseUrl}/${uploadContent[0].url}?t=${token}`
    );

    try {
      const response = await dispatch(getCvDetailsApi(formData)).then(
        unwrapResult
      );
      setIsFileLoading(false);
      if (response.data.SkillsInfo && response.data.SkillsInfo.length > 0) {
        try {
          dispatch(
            updateUserProfileApi({
              userId: userId,
              skills: response.data.SkillsInfo,
            })
          );
        } catch (error) {
          toast.error("Unable to create skills");
        }
      }

      if (
        response?.data?.educationHistory &&
        response?.data?.educationHistory?.length > 0
      ) {
        response?.data?.educationHistory?.forEach((edu) => {
          const startDateMonth = edu.startDate
            ? moment(edu.startDate).format("MMMM")
            : "";
          const startDateYear = edu.startDate
            ? moment(edu.startDate).format("YYYY")
            : "";
          const endDateMonth = edu.endDate
            ? moment(edu.endDate).format("MMMM")
            : "";
          const endDateYear = edu.endDate
            ? moment(edu.endDate).format("YYYY")
            : "";

          dispatch(
            addEducationApi({
              userId: userId,
              institute: edu.institute ? edu.institute : "",
              organizationId: "",
              degree: edu.degree ? edu.degree : "",
              description: edu.description ? edu.description : "",
              grade: edu.grade ? edu.grade : "",
              fieldOfStudy: edu.fieldOfStudy ? edu.fieldOfStudy : "",
              activitiesAndSocieties: [],
              startDate: {
                month: startDateMonth,
                year: startDateYear,
              },
              endDate: {
                month: endDateMonth,
                year: endDateYear,
              },
            })
          );
        });
      }

      if (
        response.data.professionalHistory &&
        response.data.professionalHistory.length > 0
      ) {
        response.data.professionalHistory.forEach((exp) => {
          const startDateMonth = exp.startDate
            ? moment(exp.startDate).format("MMMM")
            : "";
          const startDateYear = exp.startDate
            ? moment(exp.startDate).format("YYYY")
            : "";
          const endDateMonth = exp.endDate
            ? exp.endDate === "Present" || exp.endDate === "till date"
              ? moment().format("MMMM")
              : moment(exp.endDate).format("MMMM")
            : "";
          const endDateYear = exp.endDate
            ? exp.endDate === "Present" || exp.endDate === "till date"
              ? moment().format("YYYY")
              : moment(exp.endDate).format("YYYY")
            : "";

          dispatch(
            addExperienceApi({
              userId: userId,
              organization: exp?.organization ? exp?.organization : "",
              organizationId: "",
              userExperienceDetails: [
                {
                  location: exp.location ? exp.location : "",
                  locationId: "",
                  locationType: "",
                  // employmentType: employmentType.toString(),
                  title: exp.designation ? exp.designation : "",
                  description: exp.description ? exp.description : "",
                  startDate: {
                    month: startDateMonth,
                    year: startDateYear,
                  },
                  endDate: {
                    month: endDateMonth,
                    year: endDateYear,
                  },
                  isCurrentRole:
                    exp.endDate === "Present" || exp.endDate === "till date"
                      ? true
                      : false,
                  skills: ["Java", "Software"],
                },
              ],
            })
          );
        });
      }

      if (response?.data?.socialInfo?.linkedin?.profileUrl) {
        dispatch(
          updateUserProfileApi({
            userId: userId,
            socialInfo: {
              Twitter: {
                type: "twitter",
                profileURL: response?.data?.socialInfo?.linkedin?.profileUrl,
              },
            },
          })
        );
      }
    } catch (error) {
      toast.error("Something went wrog");
      setIsFileLoading(false);
    }
  };

  const onChangeHandler = (e) => {
    if (e.target.files && e.target.files.length > 0) {
      const toastId = toast.loading("Uploading ...");
      uploadContent.uploadProfileContent(
        userId,
        e.target.files,
        async (response) => {
          const url = response[0].url;
          toast.dismiss(toastId);
          if (url) {
            try {
              await dispatch(
                updateUserProfileApi({
                  userId: userId,
                  profilePicture: url,
                })
              ).then(unwrapResult);
              toast.success("Profile uploaded successfully");
            } catch (error) {
              toast.error("Somthing went wrong, Please try again.");
            }
          } else {
            toast.error("Somthing went wrong, Please try again.");
            toast.dismiss(toastId);
          }
        }
      );
    }
  };

  if (error) {
    return <h1 className="text-red-950 text-3xl">Somthing went wrong</h1>;
  } else {
    return (
      !isLoading && (
        <div className="">
          <div className="w-[95%] mx-auto">
            <div className="flex items-center justify-between py-4">
              <h1 className="text-3xl text-exgray-300/80 tracking-wide font-semibold">
                Edit Profile
              </h1>

              <div className="flex items-center justify-center gap-2">
                <button
                  className="px-10 py-2 text-center text-base font-semibold rounded-lg bg-exgray-100 text-expurple-800"
                  onClick={navigateHandler}
                >
                  Back
                </button>
                <button
                  className="px-10 py-2 text-center text-base font-semibold rounded-lg text-exwhite-100 bg-expurple-800"
                  type="button"
                  onClick={savePersonalDetailsHandler}
                >
                  Save
                </button>
              </div>
            </div>
            <div className="flex items-center justify-center">
              <div className="w-[300px] space-y-2 self-start">
                <h6 className="text-black text-base tracking-wide font-semibold leading-none">
                  Profile Picture
                </h6>

                {userDetails.profilePicture ? (
                  <div className="w-full h-80 rounded-lg flex items-center justify-center overflow-hidden relative">
                    <img
                      src={`${constants.baseUrl}/${userDetails.profilePicture}?t=${token}`}
                      alt="profile"
                      className="w-full h-full object-cover"
                    />
                    <div
                      className="h-10 w-10 bg-expurple-100 absolute bottom-2 right-2 flex items-center justify-center rounded-lg cursor-pointer"
                      onClick={handleClick}
                    >
                      <StandardEditIcon color="#223870" />
                      <input
                        ref={imageInputRef}
                        type="file"
                        name="candidateProfileImage"
                        multiple
                        accept="image/*"
                        onChange={onChangeHandler}
                        style={{ display: "none" }}
                      />
                    </div>
                  </div>
                ) : (
                  <div className="w-full h-80 border-2 border-dashed border-exgray-200 rounded-lg flex items-center justify-center">
                    <div className="text-center w-[90%] mx-auto">
                      <div
                        className="cursor-pointer w-fit mx-auto"
                        onClick={handleClick}
                      >
                        <div>
                          <UploadIcon />
                        </div>
                        <input
                          ref={imageInputRef}
                          type="file"
                          name="image"
                          multiple
                          accept="image/*"
                          onChange={onChangeHandler}
                          style={{ display: "none" }}
                        />
                      </div>
                      <h2 className="text-lg text-black font-semibold">
                        Browse Photo
                      </h2>
                      <p className="text-base text-exgray-500">
                        A photo larger than 400 pixels work best. Max photo size
                        5 MB.
                      </p>
                    </div>
                  </div>
                )}
              </div>

              <div className="flex-1 pl-4 space-y-2 pb-4">
                <div className="pb-4">
                  <form className="space-y-2">
                    <div className="w-[50%]">
                      <Controller
                        name="file"
                        control={control}
                        rules={{
                          required: {
                            value: false,
                            message: "Required",
                          },
                        }}
                        render={({ field: { onChange, value } }) => (
                          <div>
                            <label
                              htmlFor="file"
                              className={`text-lg font-normal text-expurple-800`}
                            >
                              Upload CV
                            </label>
                            <div className="hidden">
                              <input
                                // title="Add Resume / CV"
                                placeholder="Browse file"
                                name="file"
                                id="file"
                                type="file"
                                ref={inputRef}
                                accept=".pdf, .doc, .docx"
                                onChange={(e) => {
                                  setIsFileLoading(true);

                                  uploadContent.uploadProfileContent(
                                    userId,
                                    e.target.files,
                                    async (response) => {
                                      const url = response[0].url;
                                      if (url) {
                                        onChange({
                                          name: response[0].orginalFileName,
                                          url: response[0].url,
                                          contentType: response[0].contentType,
                                          size: response[0].size,
                                        });
                                        resumeDetailsHandler(response);
                                      } else {
                                        toast.error(
                                          "Somthing went wrong, Please try again."
                                        );
                                      }
                                    }
                                  );
                                }}
                                // value={value}
                              />
                            </div>
                            <div className="flex items-center justify-between gap-2">
                              <div className="flex-1 h-10 bg-expurple-100 px-3 flex items-center justify-start gap-1">
                                <div
                                  className="cursor-pointer"
                                  onClick={triggerInputfileHanlder}
                                >
                                  <PlusCircleIcon />
                                </div>
                                <p className="text-expurple-700 text-base line-clamp-1">
                                  {uploadedFile?.name
                                    ? uploadedFile?.name
                                    : "Browse file. Only pdf"}
                                </p>
                              </div>
                              {isFileLoading && (
                                <div>
                                  <LoadingIcon />
                                </div>
                              )}
                            </div>
                            {errors?.file?.message && (
                              <p className="text-red-400">
                                {errors?.file?.message}
                              </p>
                            )}
                          </div>
                        )}
                      />
                    </div>
                  </form>
                </div>

                <p className="text-center text-xl text-exgray-200 font-semibold">
                  OR
                </p>

                <div className="border-b-2 border-b-exgray-200 pb-4">
                  <PersonalDetails submitBtnRef={submitBtnRef} />
                </div>

                <div className="border-b-2 border-b-exgray-200 pb-2">
                  <ExperienceSection />
                </div>
                <div className="border-b-2 border-b-exgray-200 pb-2">
                  <EducationSection />
                </div>
                <div className="border-b-2 border-b-exgray-200 pb-2">
                  <SkillSection />
                </div>
                <div className="border-b-2 border-b-exgray-200 pb-2">
                  <PreferenceSection />
                </div>
                <div className="pb-2">
                  <AdditionalInformationSection />
                </div>
              </div>
            </div>
          </div>
        </div>
      )
    );
  }
};

export default CandidateProfile;
