import React, { useMemo } from "react";
import { Controller, useForm } from "react-hook-form";
import ExInput from "../../../components/basic-components/ExInput";
import ExDropdown from "../../../components/basic-components/ExDropdown";
import { useAppDispatch, useAppSelector } from "../../../config/hooks";
import * as constants from "../../../constant";
import { createMemberApi } from "../../../slices/companyMemberSlice";
import { unwrapResult } from "@reduxjs/toolkit";
import { toast } from "sonner";

const roles = [
  { Id: 1, Title: constants.ADMIN },
  { Id: 2, Title: constants.JOB_MANEGMENT },
  { Id: 3, Title: constants.CANDIDATE_MANEGMENT },
];

const CreateMember = ({ onClose }) => {
  const dispatch = useAppDispatch();
  const userId = useAppSelector((state) => state?.login?.session?.userId);
  const loginSelector = useAppSelector((state) => state?.login?.session);
  const companySelector = useAppSelector(
    (state) => state.company.companyDetails
  );
  const memberSelector = useAppSelector(
    (state) => state?.member?.memberDetails
  );

  const domain = useMemo(() => {
    if (
      loginSelector.who === constants.COMPANY_MEMBER.toLowerCase() &&
      memberSelector.emailId
    ) {
      const email = memberSelector.emailId.match(
        /@[A-Za-z0-9.-]+\.[A-Za-z]{2,}/
      );
      if (email.length > 0) {
        return email[0] ? email[0] : "";
      }
      return "";
    } else if (
      loginSelector.who === constants.COMPANY.toLowerCase() &&
      companySelector?.domain
    ) {
      return `@${companySelector.domain}`;
    }
    return "";
  }, [companySelector?.domain, loginSelector?.who, memberSelector?.emailId]);

  const form = useForm({
    defaultValues: {
      emailId: "",
      role: null,
    },
  });
  const { handleSubmit, control, formState } = form;
  const { errors } = formState;

  const onSubmit = async (formData) => {
    try {
      await dispatch(
        createMemberApi({
          emailId: formData.emailId,
          designation: formData.role.Title,
          companyId:
            loginSelector.who === constants.COMPANY.toLowerCase()
              ? userId
              : memberSelector.companyId,
          authorId: userId,
          authortype: loginSelector.who.toUpperCase(),
        })
      ).then(unwrapResult);
      toast.success("Meamber created successfully");
    } catch (error) {
      toast.error("Something went wrong");
    }
    onClose();
  };
  return (
    <div className="space-y-2">
      <h1 className="text-xl text-exgray-800 font-semibold border-b-2 border-b-exgray-100 pb-2">
        Create Member
      </h1>
      <form onSubmit={handleSubmit(onSubmit)} className="space-y-2">
        <div>
          <Controller
            name="emailId"
            control={control}
            rules={{
              required: {
                value: true,
                message: "Email ID is required",
              },
              pattern: {
                value:
                  /^([a-zA-Z0-9_.+-])+@(([a-zA-Z0-9-])+\.)+([a-zA-Z0-9]{2,4})+$/,
                message: "Please enter a valid email ID",
              },
              validate: (value) => {
                // Ensure email ends with @aws.com
                return (
                  value.endsWith(domain) || `Email must end with ${domain}`
                );
              },
            }}
            render={({ field: { onChange, value } }) => (
              <ExInput
                title="Official Email ID"
                placeholder="eg. abc@abc.com"
                name="emailId"
                onChange={onChange}
                value={value}
                error={errors?.emailId?.message}
              />
            )}
          />
        </div>

        <div>
          <Controller
            name="role"
            control={control}
            rules={{
              required: {
                value: true,
                message: "Role is required",
              },
            }}
            render={({ field: { onChange, value } }) => (
              <ExDropdown
                title="Member's Role"
                onChange={onChange}
                placeholder="Select"
                value={value || null}
                optionValue="Id"
                optionLabel="Title"
                options={roles}
                dropDownHeight="40px"
                dropDownBorderWidth="0px"
                dropDownBorderColor="#696969"
                error={errors?.role?.message}
              />
            )}
          />
        </div>

        <div className="">
          <div className="w-fit ml-auto mr-0 pt-2">
            <button
              type="submit"
              className="px-8 py-2 text-exwhite-100 text-base font-semibold text-center bg-expurple-800 rounded-md"
            >
              Create
            </button>
          </div>
        </div>
      </form>
    </div>
  );
};

export default CreateMember;
