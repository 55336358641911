import { useState } from "react";
import CompanyDashboardProfile from "./components/CompanyDashboardProfile";
import { v4 as uuid4 } from "uuid";

import CompanyDashboardDetails from "./components/CompanyDashboardDetails";
import CompanyJobsDashboard from "./components/CompanyJobsDashboard";
import CompanyMembersDashboard from "./components/CompanyMembersDashboard";

const navigationButtons = [
  { title: "Profile" },
  { title: "Jobs" },
  { title: "Members" },
];

const CompanyDashboard = () => {
  const [toggle, setToggle] = useState(0);

  const toggleHandler = (index) => {
    setToggle(index);
  };
  return (
    <div className="w-[95%] min-h-screenheight mx-auto py-8 space-y-4">
      <CompanyDashboardProfile />

      <div className="w-[96%] mx-auto pb-2 border-b-2 border-b-exgray-100">
        {navigationButtons.map((navogationBtn, index) => (
          <button
            key={uuid4()}
            className={`py-2 px-8 text-base ${
              toggle === index
                ? "bg-expurple-800 text-exwhite-100 rounded-md"
                : "text-expurple-800"
            }`}
            onClick={() => toggleHandler(index)}
          >
            {navogationBtn.title}
          </button>
        ))}
      </div>

      {toggle === 0 && (
        <div className="w-[96%] mx-auto py-2 px-5">
          <CompanyDashboardDetails />
        </div>
      )}

      {toggle === 1 && (
        <div className="w-[96%] mx-auto py-2 px-5">
          <CompanyJobsDashboard />
        </div>
      )}

      {toggle === 2 && (
        <div className="w-[96%] mx-auto py-2 px-5">
          <CompanyMembersDashboard />
        </div>
      )}
    </div>
  );
};

export default CompanyDashboard;
