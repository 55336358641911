import apiClient from "../config/axiosConfig";
import apiUserClient from "../config/local-axios-config/userApiConfig";

class CandidateProfileService {
  getCandidateDetailApi(userDetail) {
    return apiClient.get(`v1/exo/users/${userDetail.userId}`);
  }

  addExperience(experience) {
    return apiClient.post(
      `v1/exo/users/${experience.userId}/experience`,
      experience
    );
  }

  addEducation(education) {
    return apiClient.post(
      `v1/exo/users/${education.userId}/education`,
      education
    );
  }

  deleteUserInfo(info) {
    return apiClient.delete(
      `v1/exo/users/${info.userId}/${info.infoType}/${info.id}`
    );
  }

  updateUserDetail(userDetail) {
    return apiClient.patch(`v1/exo/users/${userDetail.userId}`, userDetail);
  }

  createAdditionalDetails(userDetail) {
    return apiClient.post(
      `v1/exo/users/${userDetail.userId}/additional-info`,
      userDetail
    );
  }

  updateAdditionalDetails(userDetail) {
    return apiClient.put(
      `v1/exo/users/${userDetail.userId}/additional-info/${userDetail.id}`,
      userDetail
    );
  }

  updateExperience(experience) {
    return apiClient.put(
      `v1/exo/users/${experience.userId}/experience/${experience.experienceId}`,
      experience
    );
  }

  updateEducation(education) {
    return apiClient.put(
      `v1/exo/users/${education.userId}/education/${education.educationId}`,
      education
    );
  }

  postLanguages(language) {
    return apiClient.post(
      `v1/exo/users/${language.userId}/languages`,
      language.languages
    );
  }

  addLanguages(language) {
    return apiClient.patch(
      `v1/exo/users/${language.userId}/languages`,
      language.languages
    );
  }

  //   addPublication(publication) {
  //     return apiClient.post(
  //       `v1/users/${publication.userId}/publications`,
  //       publication.publicationData
  //     );
  //   }

  //   addAward(
  //     award) {
  //     return apiClient.post(`v1/users/${award.userId}/awards`, award);
  //   }

  //   postLanguages(
  //     language) {
  //     return apiClient.post(
  //       `v1/users/${language.userId}/languages`,
  //       language.languages
  //     );
  //   }

  //   updateEducation(
  //     education){
  //     return apiClient.put(
  //       `v1/users/${education.userId}/education/${education.educationId}`,
  //       education
  //     );
  //   }

  //   updateAward(award) {
  //     return apiClient.put(
  //       `v1/users/${award.userId}/awards/${award.awardId}`,
  //       award
  //     );
  //   }

  //   updatePublication(publication) {
  //     return apiClient.put(
  //       `v1/users/${publication.userId}/publications/${publication.publicationId}`,
  //       publication
  //     );
  //   }
}
const candidateProfileService = new CandidateProfileService();
export default candidateProfileService;
