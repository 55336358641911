import apiClient from "../config/axiosConfig";
import apiCompanyClient from "../config/local-axios-config/companyApiConfig";
import apiCompanyAuthClient from "../config/local-axios-config/companyAuthApiConfig";

class CompanyService {
  updateCompany(updateCompanyReq) {
    return apiClient.put(
      `/v1/exo/companies/${updateCompanyReq.id}`,
      updateCompanyReq
    );
  }

  getCompanyDetail(getDetail) {
    return apiClient.get(`/v1/exo/companies/${getDetail.id}`);
  }
}

const companyService = new CompanyService();
export default companyService;
