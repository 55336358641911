import React, { useEffect } from "react";
import { Controller, useForm } from "react-hook-form";
import ExInput from "../../components/basic-components/ExInput";
import ExCheckBox from "../../components/basic-components/ExCheckBox";
import ExDropdown from "../../components/basic-components/ExDropdown";
import { Link, useNavigate } from "react-router-dom";
import Popup from "../../components/popup/Popup";
import OtpField from "../../components/otp/OtpField";
import {
  getOtpApi,
  registerApi,
  toggleOtpHandler,
} from "../../slices/loginSlice";
import { unwrapResult } from "@reduxjs/toolkit";
import { useAppDispatch, useAppSelector } from "../../config/hooks";
import useCountryCodes from "../../utils/dropdown-options/useCountryCodes";
import useLocations from "../../utils/dropdown-options/useLocations";
import * as constants from "../../constant";
import { getCandidateDetailApi } from "../../slices/candidateProfileSlice";
import { jobOpenedbeforeLoginHandler } from "../../slices/jobSlice";

const CandidateCreate = () => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const togglePopup = useAppSelector((state) => state.login.ui.toggleOtp);
  const uiSelector = useAppSelector((state) => state.login.ui);
  const requestID = useAppSelector((state) => state.login.ui.requestId);
  const jobSelector = useAppSelector(
    (state) => state.jobs.openedJobBeforelogin
  );

  const { modifiedCountryCodes, defaultCountryCode } = useCountryCodes();
  const { locationsArray } = useLocations();

  const {
    control,
    handleSubmit,
    watch,
    setValue,
    formState: { errors },
  } = useForm({
    defaultValues: {
      firstName: "",
      lastName: "",
      emailId: "",
      location: "",
      phoneNo: "",
      isFresher: false,
      companyName: "",
      designantion: "",
      experienceYears: null,
      experienceMonths: null,
      ctcInLacs: null,
      ctcInThousand: null,
      countryCode: constants.IND_COUNTRYCODE,
      uploadCV: "",
      tAndc: false,
    },
  });

  const isFresherValue = watch("isFresher");
  const tAndcAccepted = watch("tAndc");
  const countryCodeValue = watch("countryCode");
  const phoneNoValue = watch("phoneNo");

  useEffect(() => {
    if (uiSelector.isVerified && uiSelector.loginType === "user") {
      if (uiSelector.isEmail) {
        setValue("emailId", uiSelector.email);
      } else {
        setValue("countryCode", defaultCountryCode(uiSelector.countryCode));
        setValue("phoneNo", uiSelector.phone);
      }
    }
  }, []);

  const getOtp = async (req) => {
    try {
      const response = await dispatch(getOtpApi(req)).then(unwrapResult);
      const requestId = response?.data?.data?.requestId;
      if (requestId) {
        dispatch(toggleOtpHandler());
      }
    } catch (error) {}
  };

  const toglePopupHandler = () => {
    // setTogglePopup(!togglePopup);
    getOtp({ countryCode: 91, phoneNo: parseInt(phoneNoValue) });
  };

  const onSubmit = async (formData) => {
    const newSignupData = {
      requestId: requestID,
      firstName: formData.firstName,
      lastName: formData.lastName,
      emailId: formData.emailId,
      countryCode: parseInt(formData.countryCode.PhoneCode),
      phoneNo: parseInt(formData.phoneNo),
      location: formData.location.Id.toString(),
      yearsOfExperience: formData.experienceYears,
      designation: formData.designantion,
      organization: formData.companyName,
      socialInfo: {
        Twitter: {
          type: "Twitter",
          profileURL: "twitter.com",
        },
      },
    };

    try {
      const response = await dispatch(registerApi(newSignupData)).then(
        unwrapResult
      );

      if (jobSelector.jobId) {
        try {
          await dispatch(
            getCandidateDetailApi({
              userId: response.data.data.session.userId,
            })
          ).then(unwrapResult);
          navigate("/job-search");
        } catch (error) {
          dispatch(jobOpenedbeforeLoginHandler({ jobId: "" }));
          navigate("/job-search");
        }
      } else navigate("/candidate-profile");
    } catch (error) {}
  };

  return (
    <>
      <div className="h-full flex">
        <div className="w-full h-full"></div>
        <div className="h-full w-full">
          <h1 className="text-4xl text-expurple-800 font-semibold text-center py-4">
            Candidate Signup
          </h1>
          <form
            onSubmit={handleSubmit(onSubmit)}
            className="w-[80%] h-[80%] mx-auto space-y-3 overflow-y-scroll no-scrollbar"
          >
            <div className="flex items-center justify-between gap-2">
              <div className="w-full self-start">
                <Controller
                  name="firstName"
                  control={control}
                  rules={{
                    required: {
                      value: true,
                      message: "First Name is required",
                    },
                  }}
                  render={({ field: { onChange, value } }) => (
                    <ExInput
                      title="First Name"
                      placeholder="First Name"
                      name="firstName"
                      onChange={onChange}
                      value={value}
                      error={errors?.firstName?.message}
                    />
                  )}
                />
              </div>
              <div className="w-full self-start">
                <Controller
                  name="lastName"
                  control={control}
                  rules={{
                    required: {
                      value: true,
                      message: "Last Name is required",
                    },
                  }}
                  render={({ field: { onChange, value } }) => (
                    <ExInput
                      title="Last Name"
                      placeholder="Last Name"
                      name="lastName"
                      onChange={onChange}
                      value={value}
                      error={errors?.lastName?.message}
                    />
                  )}
                />
              </div>
            </div>

            <div>
              {/* <Controller
                name="emailId"
                control={control}
                rules={{
                  required: {
                    value: true,
                    message: "Email ID is required",
                  },
                  pattern: {
                    value:
                      /^([a-zA-Z0-9_.+-])+@(([a-zA-Z0-9-])+\.)+([a-zA-Z0-9]{2,4})+$/,
                    message: "Please enter a valid email ID",
                  },
                }}
                render={({ field: { onChange, value } }) => (
                  <ExInput
                    title="Email ID"
                    placeholder="eg. abc@abc.com"
                    name="emailId"
                    onChange={onChange}
                    value={value}
                    error={errors?.emailId?.message}
                  />
                )}
              /> */}

              <Controller
                name="emailId"
                control={control}
                rules={{
                  required: {
                    value: true,
                    message: "Email ID is required",
                  },
                  pattern: {
                    value:
                      /^([a-zA-Z0-9_.+-])+@(([a-zA-Z0-9-])+\.)+([a-zA-Z0-9]{2,4})+$/,
                    message: "Please enter a valid email ID",
                  },
                }}
                render={({ field: { onChange, value } }) => (
                  <div>
                    <label
                      htmlFor={"emailId"}
                      className={`text-lg font-normal text-expurple-800`}
                    >
                      Email ID *
                    </label>
                    <div className={`relative`}>
                      <input
                        type="text"
                        readOnly={
                          uiSelector.isVerified &&
                          uiSelector.email &&
                          uiSelector.loginType === "user"
                        }
                        className="w-full h-10 px-3 bg-expurple-100 rounded-sm outline-none text-expurple-700 placeholder:text-expurple-700"
                        id="emailId"
                        placeholder="eg. abc@abc.com"
                        autoComplete="off"
                        name="emailId"
                        onChange={onChange}
                        value={value}
                      />
                      {uiSelector.isVerified &&
                        uiSelector.email &&
                        uiSelector.loginType === "user" && (
                          <div className="absolute h-full top-0 flex items-center justify-center right-2">
                            <button
                              type="button"
                              disabled={true}
                              className="py-1 w-fit px-4 bg-expurple-800 text-golden text-xs rounded-sm"
                            >
                              Verified
                            </button>
                          </div>
                        )}
                    </div>
                    {errors?.emailId?.message && (
                      <p className="text-red-400">{errors?.emailId?.message}</p>
                    )}
                  </div>
                )}
              />
            </div>

            <div className="w-full">
              <div className="flex items-center justify-between gap-2">
                <div className="min-w-[120px] self-start">
                  <Controller
                    name="countryCode"
                    control={control}
                    rules={{
                      required: {
                        value: true,
                        message: "required",
                      },
                    }}
                    render={({ field: { onChange, value } }) => (
                      <ExDropdown
                        title="Country *"
                        onChange={onChange}
                        placeholder="Select"
                        value={value || null}
                        optionValue="Id"
                        isDisabled={
                          uiSelector.isVerified &&
                          uiSelector.countryCode &&
                          uiSelector.loginType === "user"
                        }
                        optionLabel="PhoneCodeLabel"
                        options={modifiedCountryCodes}
                        dropDownHeight="40px"
                        dropDownBorderWidth="0px"
                        dropDownBorderColor="#696969"
                        error={errors?.countryCode?.message}
                      />
                    )}
                  />
                </div>
                <div className="flex-1 self-start">
                  <Controller
                    name="phoneNo"
                    control={control}
                    rules={{
                      required: {
                        value: true,
                        message: "Mobile no is required",
                      },
                      pattern: {
                        value: /^[0-9]+$/,
                        message: "Please enter a valid mobile number",
                      },
                    }}
                    render={({ field: { onChange, value } }) => (
                      <div>
                        <label
                          htmlFor={"phoneNo"}
                          className={`text-lg font-normal text-expurple-800`}
                        >
                          Mobile No. *
                        </label>
                        <div className={`relative`}>
                          <input
                            type="number"
                            onWheel={(e) => e.target.blur()}
                            className="w-full h-10 px-3 bg-expurple-100 rounded-sm outline-none text-expurple-700 placeholder:text-expurple-700"
                            id="phoneNo"
                            readOnly={
                              uiSelector.isVerified &&
                              uiSelector.phone &&
                              uiSelector.loginType === "user"
                            }
                            placeholder="Enter here"
                            autoComplete="off"
                            name="phoneNo"
                            onChange={onChange}
                            value={value}
                          />
                          {!(uiSelector.isVerified && uiSelector.email) && (
                            <div className="absolute h-full top-0 flex items-center justify-center right-2 spin-button-none">
                              {uiSelector.isVerified &&
                              uiSelector.phone &&
                              uiSelector.loginType === "user" ? (
                                <button
                                  type="button"
                                  disabled={true}
                                  className="py-1 w-fit px-4 bg-expurple-800 text-golden text-xs rounded-sm"
                                >
                                  Verified
                                </button>
                              ) : (
                                <button
                                  type="button"
                                  disabled={!(phoneNoValue && countryCodeValue)}
                                  onClick={toglePopupHandler}
                                  className="py-1 w-fit px-4 bg-expurple-800 text-golden text-xs rounded-sm"
                                >
                                  Verify
                                </button>
                              )}
                            </div>
                          )}
                        </div>
                        {errors?.phoneNo?.message && (
                          <p className="text-red-400">
                            {errors?.phoneNo?.message}
                          </p>
                        )}
                      </div>
                    )}
                  />
                </div>
              </div>
            </div>

            <div>
              <Controller
                name="location"
                control={control}
                rules={{
                  required: {
                    value: true,
                    message: "Location is required",
                  },
                }}
                render={({ field: { onChange, value } }) => (
                  <ExDropdown
                    title="Location"
                    onChange={onChange}
                    placeholder="Select"
                    value={value || null}
                    optionValue="Id"
                    optionLabel="Name"
                    options={locationsArray}
                    dropDownHeight="40px"
                    dropDownBorderWidth="0px"
                    dropDownBorderColor="#696969"
                    error={errors?.location?.message}
                  />
                )}
              />
            </div>

            <div>
              <Controller
                name="isFresher"
                control={control}
                render={({ field: { onChange, value } }) => (
                  <ExCheckBox
                    title="I'm a Fresher"
                    name="isFresher"
                    onChange={onChange}
                    value={value}
                  />
                )}
              />
            </div>

            {!isFresherValue && (
              <div className="space-y-3">
                <div>
                  <Controller
                    name="companyName"
                    control={control}
                    rules={{
                      required: {
                        value: true,
                        message: "Company name is required",
                      },
                    }}
                    render={({ field: { onChange, value } }) => (
                      <ExInput
                        title="Company Name"
                        placeholder="Enter Here"
                        name="companyName"
                        onChange={onChange}
                        value={value}
                        error={errors?.companyName?.message}
                      />
                    )}
                  />
                </div>

                <div>
                  <Controller
                    name="designantion"
                    control={control}
                    rules={{
                      required: {
                        value: true,
                        message: "Designantion is required",
                      },
                    }}
                    render={({ field: { onChange, value } }) => (
                      <ExInput
                        title="Designation"
                        placeholder="Enter Here"
                        name="designantion"
                        onChange={onChange}
                        value={value}
                        error={errors?.designantion?.message}
                      />
                    )}
                  />
                </div>

                <div>
                  <Controller
                    name="experienceYears"
                    control={control}
                    rules={{
                      required: {
                        value: true,
                        message: "Experience is required",
                      },
                    }}
                    render={({ field: { onChange, value } }) => (
                      <ExInput
                        title="Years of Experience"
                        placeholder="Ex. 5"
                        type="number"
                        onWheel={(e) => e.target.blur()}
                        name="experienceYears"
                        onChange={onChange}
                        value={value || ""}
                        error={errors?.experienceYears?.message}
                      />
                    )}
                  />
                </div>

                {/* <div className="flex items-center justify-center gap-2">
                  <div className="w-full self-start">
                    <Controller
                      name="experienceYears"
                      control={control}
                      rules={{
                        required: {
                          value: true,
                          message: "Experience is required",
                        },
                      }}
                      render={({ field: { onChange, value } }) => (
                        <ExDropdown
                          title="Experience *"
                          onChange={onChange}
                          placeholder="Select"
                          value={value || null}
                          optionValue="Id"
                          optionLabel="Title"
                          options={experienceInYears}
                          dropDownHeight="40px"
                          dropDownBorderWidth="0px"
                          dropDownBorderColor="#696969"
                          error={errors?.experienceYears?.message}
                        />
                      )}
                    />
                  </div>
                  <div className="w-full self-start">
                    <p className="opacity-0 text-lg">title</p>
                    <Controller
                      name="experienceMonths"
                      control={control}
                      rules={{
                        required: {
                          value: true,
                          message: "required",
                        },
                      }}
                      render={({ field: { onChange, value } }) => (
                        <ExDropdown
                          title=" "
                          onChange={onChange}
                          placeholder="Select"
                          value={value || null}
                          optionValue="Id"
                          optionLabel="Title"
                          options={experienceInMonths}
                          dropDownHeight="40px"
                          dropDownBorderWidth="0px"
                          dropDownBorderColor="#696969"
                          error={errors?.experienceMonths?.message}
                        />
                      )}
                    />
                  </div>
                </div> */}

                {/* <div className="flex items-center justify-center gap-2">
                  <div className="w-full self-start">
                    <Controller
                      name="ctcInLacs"
                      control={control}
                      rules={{
                        required: {
                          value: true,
                          message: "CTC is required",
                        },
                      }}
                      render={({ field: { onChange, value } }) => (
                        <ExDropdown
                          title="Current CTC *"
                          onChange={onChange}
                          placeholder="Select"
                          value={value || null}
                          optionValue="Id"
                          optionLabel="Title"
                          options={ctcInLacs}
                          dropDownHeight="40px"
                          dropDownBorderWidth="0px"
                          dropDownBorderColor="#696969"
                          error={errors?.ctcInLacs?.message}
                        />
                      )}
                    />
                  </div>
                  <div className="w-full self-start">
                    <p className="opacity-0 text-lg">title</p>
                    <Controller
                      name="ctcInThousand"
                      control={control}
                      rules={{
                        required: {
                          value: true,
                          message: "required",
                        },
                      }}
                      render={({ field: { onChange, value } }) => (
                        <ExDropdown
                          title=" "
                          onChange={onChange}
                          placeholder="Select"
                          value={value || null}
                          optionValue="Id"
                          optionLabel="Title"
                          options={ctcInThounsands}
                          dropDownHeight="40px"
                          dropDownBorderWidth="0px"
                          dropDownBorderColor="#696969"
                          error={errors?.ctcInThousand?.message}
                        />
                      )}
                    />
                  </div>
                </div> */}
              </div>
            )}

            {/* <div>
              <Controller
                name="uploadCV"
                control={control}
                rules={{
                  required: {
                    value: true,
                    message: "Please upload CV",
                  },
                }}
                render={({ field: { onChange, value } }) => (
                  <ExUplaodInput
                    title={"Uplad CV *"}
                    name={"uploadCV"}
                    onChange={onChange}
                    value={value}
                    error={errors?.uploadCV?.message}
                  />
                )}
              />
            </div> */}

            <div>
              <Controller
                name="tAndc"
                control={control}
                render={({ field: { onChange, value } }) => (
                  <ExCheckBox
                    title="I agree to the T&C and Privacy Policy of the ExoTalent *"
                    name="tAndc"
                    onChange={onChange}
                    value={value}
                  />
                )}
              />
            </div>

            <div className="">
              <div className="w-fit mx-auto">
                <button
                  type="submit"
                  disabled={!tAndcAccepted}
                  className={`py-2 px-10 bg-expurple-800 text-lg text-golden text-center font-semibold rounded-xl tracking-wider ${
                    !tAndcAccepted && "opacity-75"
                  }`}
                >
                  SignUp
                </button>
              </div>

              <div>
                <div className="w-fit ml-auto mr-0">
                  <Link
                    to={"/candidate"}
                    className="text-center text-lg text-expurple-800 underline tracking-wider"
                  >
                    Login
                  </Link>
                </div>
              </div>
            </div>
          </form>
        </div>
      </div>

      <Popup onClose={() => dispatch(toggleOtpHandler())} visible={togglePopup}>
        <div className="bg-expurple-100 p-10 rounded-xl">
          <OtpField
            userType={constants.USER.toLowerCase()}
            onClose={() => dispatch(toggleOtpHandler())}
          />
        </div>
      </Popup>
    </>
  );
};

export default CandidateCreate;
