import React, { useState } from "react";

import Profile from "./components/Profile";
import JobFilter from "./components/JobFilter";
import AppliedJobList from "./components/AppliedJobList";
import SavedJobsList from "./components/SavedJobsList";
import RefferedJobsList from "./components/RefferedJobsList";

const CandidateAppliedJobs = () => {
  const [selectedButton, setSelectedButton] = useState(0);

  const selectButtonHandler = (index) => {
    setSelectedButton(index);
  };
  return (
    <div className="min-h-screenheight bg-expurple-200">
      <div className="w-[95%] mx-auto">
        <div className="my-5">
          <Profile />
        </div>
        <JobFilter
          selectedButton={selectedButton}
          setSelectedButton={setSelectedButton}
          selectButtonHandler={selectButtonHandler}
        />
        <div className="my-2">
          {selectedButton === 0 && <AppliedJobList />}
          {selectedButton === 1 && <SavedJobsList />}
          {selectedButton === 2 && <RefferedJobsList />}
        </div>
      </div>
    </div>
  );
};

export default CandidateAppliedJobs;
