import React from "react";

const SuccessIcon = ({ width = "126", height = "126" }) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 126 126"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect width="126" height="126" rx="63" fill="#CEECD5" />
      <path
        d="M66.75 54.75L50.25 71.25L42 63.0004"
        stroke="#098023"
        strokeWidth="3"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M83.9996 54.75L67.4996 71.25L63.1172 66.8678"
        stroke="#098023"
        strokeWidth="3"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default SuccessIcon;
