import React from "react";

const ExTextarea = ({ title, name, error, ...props }) => {
  return (
    <>
      <label htmlFor={name} className={`text-lg font-normal text-expurple-800`}>
        {title}
      </label>
      <textarea
        className={`w-full h-auto p-3 bg-expurple-100 rounded-sm outline-none text-expurple-700 placeholder:text-expurple-700 no-scrollbar`}
        style={{ resize: "none" }}
        id={name}
        {...props}
      />
      {error && <p className="text-red-400">{error}</p>}
    </>
  );
};

export default ExTextarea;
