import React from "react";
import { Navigate } from "react-router-dom";
import { useAppSelector } from "../../config/hooks";

const ProtectedRoute = ({ element }) => {
  const userId = useAppSelector((state) => state?.login?.session?.userId);

  return userId ? element : <Navigate to={"/"} replace />;
};

export default ProtectedRoute;
