import axios from "axios";
import { tokenRepo } from "../../cache/db";
import * as constants from "../../constant";

// Next we make an 'instance' of it

const nocoApiClient = axios.create({
  // .. where we make our configurations
  baseURL: process.env.REACT_APP_NOCO_BASE_URL,
  responseType: "json",

  // headers: {
  //   common: {
  //     Authorization: `Bearer ${"IsbGXzUPZQPqdTm6SRMepWpezPqvx2ghsMm0x8i7nJ70TsSBlXZ4p0izBsWHwWfIClMUPTqEBCiPeKxb7r93XI7Nd3GdoG8tZZjjTbN1g2QLhCUaZ16gd9yb8OA8aZAK"
  //       }`,
  //   },
  // },
});

// Where you would set stuff like your 'Authorization' header, etc ...
//instance.defaults.headers.common['Authorization'] = 'AUTH TOKEN FROM INSTANCE';

// Also add/ configure interceptors && all the other cool stuff

// Where you would set stuff like your 'Authorization' header, etc ...
//instance.defaults.headers.common['Authorization'] = 'AUTH TOKEN FROM INSTANCE';
nocoApiClient.interceptors.request.use(
  async function (config) {
    const appToken = await tokenRepo.appToken.get(1);
    let header = {
      "content-type": config.headers.get("content-type")
        ? config.headers.get("content-type")
        : "application/json",
    };

    const token = appToken;

    if (token) {
      header = {
        ...header,
        // Authorization: `Bearer ${token.token}`,
        Authorization: `Bearer ${constants.noco_token}`,
      };
    } else {
      header = {
        ...header,
        // Authorization: `Bearer ${token.token}`,
        Authorization: `Bearer ${constants.noco_token}`,
      };
    }

    if (config.skipHeader) {
      config.headers = {};
    } else {
      config.headers = header;
    }

    return config;
  },
  function (error) {
    console.error(error);
    return Promise.reject(error);
  }
);
export default nocoApiClient;
