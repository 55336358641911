import axios from "axios";
import { tokenRepo } from "../../cache/db";
import { exouserAuthLocalBaseURL } from "../../constant";

const apiUserAuthClient = axios.create({
  baseURL: exouserAuthLocalBaseURL,
  responseType: "json",
});

apiUserAuthClient.interceptors.request.use(
  async function (config) {
    const appToken = await tokenRepo.appToken.get(1);
    let header = {
      "content-type": config.headers.get("content-type")
        ? config.headers.get("content-type")
        : "application/json",
    };

    const token = appToken;

    if (token) {
      header = {
        ...header,
        Authorization: `Bearer ${token.token}`,
      };
    }
    if (config.skipHeader) {
      config.headers = {};
    } else {
      config.headers = header;
    }

    return config;
  },
  function (error) {
    console.error(error);
    return Promise.reject(error);
  }
);
export default apiUserAuthClient;
