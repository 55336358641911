import React, { useEffect } from "react";
import { Outlet, useNavigate } from "react-router-dom";
import { useAppSelector } from "../../config/hooks";

const Recruiter = () => {
  const navigate = useNavigate();
  const logginData = useAppSelector((state) => state.login);

  const { userId, userType } = logginData;

  useEffect(() => {
    if (userId && userType === "recruiter") navigate("/recruiter-profile");
  }, []);

  return (
    <div className="min-h-screenheight bg-loginBackground bg-cover bg-center bg-no-repeat">
      <Outlet />
    </div>
  );
};

export default Recruiter;
