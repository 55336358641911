import React from "react";

const ExInput = ({ title, name, error, ...props }) => {
  return (
    <div>
      <label htmlFor={name} className={`text-lg font-normal text-expurple-800`}>
        {title}
      </label>
      <div>
        <input
          className="w-full h-10 px-3 bg-expurple-100 rounded-sm outline-none text-expurple-700 placeholder:text-expurple-700 text-base"
          id={name}
          autoComplete="off"
          {...props}
        />
      </div>
      {error && <p className="text-red-400">{error}</p>}
    </div>
  );
};

export default ExInput;
