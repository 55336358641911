import apiClient from "../config/axiosConfig";
import nocoApiClient from "../config/local-axios-config/nocoApiConfig";

class NocoService {
  getAllDesignations() {
    return nocoApiClient.get(
      `/v1/configuration/api/v1/db/data/v1/exoTalent/Designations-List?limit=500&fields=Id,Title`
    );
  }

  getAllIndustries() {
    return nocoApiClient.get(
      `/v1/configuration/api/v1/db/data/v1/exoTalent/Industries-List?limit=500&fields=Id,Name`
    );
  }

  getAllCategories() {
    return nocoApiClient.get(
      `/v1/configuration/api/v1/db/data/v1/exoTalent/Categories-List?limit=500&fields=Id,Categories`
    );
  }

  getAllFunctions() {
    return nocoApiClient.get(
      `/v1/configuration/api/v1/db/data/v1/exoTalent/Functions-List?limit=500&fields=Id,Name`
    );
  }

  getAllCountries() {
    return nocoApiClient.get(
      `/v1/configuration/api/v1/db/data/v1/exoTalent/Countries-List?limit=500&fields=Id,CountryName,PhoneCode,Iso3,Iso2`
    );
  }
  getAllCurrencyType() {
    return nocoApiClient.get(
      `/v1/configuration/api/v1/db/data/v1/exoTalent/Currency-Type?limit=500&fields=Id,Title`
    );
  }
  getSalaryRange() {
    return nocoApiClient.get(
      `/v1/configuration/api/v1/db/data/v1/exoTalent/Salary-Range-Min?limit=500&fields=Id,Title`
    );
  }

  getAllJobType() {
    return nocoApiClient.get(
      `/v1/configuration/api/v1/db/data/v1/exoTalent/Job-Types?limit=500&fields=Id,Title`
    );
  }
  getAllWorkplaceType() {
    return nocoApiClient.get(
      `/v1/configuration/api/v1/db/data/v1/exoTalent/Workplace-Type?limit=500&fields=Id,Title`
    );
  }
  getExperienceMinRange() {
    return nocoApiClient.get(
      `/v1/configuration/api/v1/db/data/v1/exoTalent/Experience-Range-Min?limit=500&fields=Id,Title`
    );
  }
  getExperienceMaxRange() {
    return nocoApiClient.get(
      `/v1/configuration/api/v1/db/data/v1/exoTalent/Experience-Range-Max?limit=500&fields=Id,Title`
    );
  }
  getAllCities() {
    return nocoApiClient.get(
      `/v1/configuration/api/v1/db/data/v1/exoTalent/Cities-List?limit=1500&fields=Id,Name,State`
    );
  }
  getLanguageList() {
    return nocoApiClient.get(
      `/v1/configuration/api/v1/db/data/v1/exoTalent/Language-List?limit=500&fields=Id,Title`
    );
  }

  getMinSalaryRange() {
    return nocoApiClient.get(
      `/v1/configuration/api/v1/db/data/v1/exoTalent/Salary-Range-Min?limit=500&fields=Id,Title`
    );
  }

  getMaxSalaryRange() {
    return nocoApiClient.get(
      `/v1/configuration/api/v1/db/data/v1/exoTalent/Salary-Range-Max?limit=500&fields=Id,Title`
    );
  }

  getCompanySizeRange() {
    return nocoApiClient.get(
      `/v1/configuration/api/v1/db/data/v1/exoTalent/Employee-Size?limit=500&fields=Id,Title`
    );
  }

  getCompanyTypes() {
    return nocoApiClient.get(
      `/v1/configuration/api/v1/db/data/v1/exoTalent/Company-Types?limit=500&fields=Id,Title`
    );
  }
}

const nocoService = new NocoService();
export default nocoService;
