import React, { useMemo, useState } from "react";

import useIndustries from "../../../utils/dropdown-options/useIndustries";
import useLanguages from "../../../utils/dropdown-options/useLanguages";

const UploadedUserPreferenceSection = ({ userDetails }) => {
  const { defaultIndustries } = useIndustries();
  const { defaultLanguages } = useLanguages();

  const [togglePopup, setTogglePopup] = useState(false);

  const industries = useMemo(() => {
    if (
      userDetails?.userDetails?.industry &&
      userDetails?.userDetails?.industry.length > 0
    ) {
      return defaultIndustries(userDetails?.userDetails?.industry);
    } else return null;
  }, [userDetails?.userDetails?.industry]);

  const languages = useMemo(() => {
    if (
      userDetails?.userDetails?.userLanguage &&
      userDetails?.userDetails?.userLanguage?.length > 0
    ) {
      return defaultLanguages(userDetails?.userDetails?.userLanguage);
    } else return [];
  }, [userDetails?.userDetails?.userLanguage]);

  return (
    <div className="">
      <div className="space-y-2">
        <div className="flex items-center justify-between">
          <h1 className="text-lg text-black text-semibold text-start">
            Preference
          </h1>
        </div>

        <div className="space-y-1">
          {userDetails?.userDetails?.interest &&
            userDetails?.userDetails?.interest.length > 0 && (
              <div className="flex items-center justify-start gap-2">
                <p className="text-base text-expurple-800 font-semibold">
                  Interest :
                </p>

                <p className="text-base text-exgray-400 font-semibold">
                  {userDetails?.userDetails?.interest.map(
                    (interest, index) =>
                      `${interest}${
                        index === userDetails?.userDetails?.interest?.length - 1
                          ? ""
                          : ", "
                      }`
                  )}
                </p>
              </div>
            )}

          {userDetails?.userDetails?.industry &&
            userDetails?.userDetails?.industry.length > 0 &&
            industries?.length > 0 && (
              <div className="flex items-center justify-start gap-2">
                <p className="text-base text-expurple-800 font-semibold">
                  Industry :
                </p>

                <p className="text-base text-exgray-400 font-semibold">
                  {industries.map(
                    (ind, index) =>
                      `${ind.Name}${
                        index === userDetails?.userDetails?.industry?.length - 1
                          ? ""
                          : ", "
                      }`
                  )}
                </p>
              </div>
            )}

          {userDetails?.userDetails?.userLanguage &&
            userDetails?.userDetails?.userLanguage.length > 0 && (
              <div className="flex items-center justify-start gap-2">
                <p className="text-base text-expurple-800 font-semibold">
                  Languages :
                </p>

                <p className="text-base text-exgray-400 font-semibold">
                  {userDetails?.userDetails?.userLanguage.map(
                    (lan, index) =>
                      `${lan.Title}${
                        index ===
                        userDetails?.userDetails?.userLanguage?.length - 1
                          ? ""
                          : ", "
                      }`
                  )}
                </p>
              </div>
            )}
        </div>
      </div>
    </div>
  );
};

export default UploadedUserPreferenceSection;
