import React from "react";
import { useNavigate } from "react-router-dom";
import useLocations from "../../../utils/dropdown-options/useLocations";
import { Controller, useForm } from "react-hook-form";
import ExInput from "../../../components/basic-components/ExInput";
import ExDropdown from "../../../components/basic-components/ExDropdown";
import { useAppDispatch } from "../../../config/hooks";
import { jobSearchFieldsReducer } from "../../../slices/jobSlice";

const JobSearchForm = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const { locationsArray } = useLocations();

  const {
    control,
    handleSubmit,
    formState: { errors, isDirty },
  } = useForm({
    defaultValues: {
      designantion: "",
      location: null,
    },
  });

  const onSubmit = async (formData) => {
    dispatch(
      jobSearchFieldsReducer({
        keyword: formData.designantion,
        location: formData?.location?.Id?.toString(),
      })
    );
    navigate("/job-search");
  };
  return (
    <form onSubmit={handleSubmit(onSubmit)} className="space-y-2">
      <div>
        <Controller
          name="designantion"
          control={control}
          rules={{
            required: {
              value: false,
              message: "Designantion is required",
            },
          }}
          render={({ field: { onChange, value } }) => (
            <ExInput
              placeholder="Job Title"
              name="designantion"
              onChange={onChange}
              value={value}
              error={errors?.designantion?.message}
            />
          )}
        />
      </div>

      <div>
        <Controller
          name="location"
          control={control}
          rules={{
            required: {
              value: false,
              message: "Location is required",
            },
          }}
          render={({ field: { onChange, value } }) => (
            <ExDropdown
              // title="Location"
              onChange={onChange}
              placeholder="Select"
              value={value || null}
              optionValue="Id"
              optionLabel="Name"
              options={locationsArray}
              dropDownHeight="40px"
              dropDownBorderWidth="0px"
              dropDownBorderColor="#696969"
              error={errors?.location?.message}
            />
          )}
        />
      </div>
      <div>
        <div className="w-fit ml-auto mr-0 pt-2">
          <button
            className={`px-8 py-2 text-center text-base  font-semibold ${
              !isDirty
                ? "bg-exgray-200 text-exwhite-100"
                : "bg-golden text-expurple-800"
            } rounded-md`}
            disabled={!isDirty}
          >
            Search
          </button>
        </div>
      </div>
    </form>
  );
};

export default JobSearchForm;
